import React from "react";

const InputBlog = () => (
  <>
    <div className="col-md-4 col-sm-4">
      <div className="form-group">
        <input
          name="dzName"
          type="text"
          required
          className="form-control"
          placeholder="Enter your name"
        />
      </div>
    </div>
    <div className="col-md-4 col-sm-4">
      <div className="form-group">
        <input
          name="dzEmail"
          type="email"
          className="form-control"
          required
          placeholder="Your Email Address"
        />
      </div>
    </div>
    <div className="col-md-4 col-sm-4">
      <div className="form-group">
        <input
          name="dzOther[Phone]"
          type="text"
          required
          className="form-control"
          placeholder="Phone"
        />
      </div>
    </div>
    <div className="col-md-12 col-sm-12">
      <div className="form-group">
        <textarea
          name="dzMessage"
          rows="4"
          className="form-control"
          required
          placeholder="Your message here"
        ></textarea>
      </div>
    </div>
  </>
);

export default InputBlog;
