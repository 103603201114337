import React, { useState } from "react";
import classnames from "classnames";

import FileUpload from "../../Element/FileUpload";
import styles from "./styles.module.scss";

const initFields = {
  groupLabel: "",
  images: [],
};

const AddGalleryForm = ({ addImages }) => {
  const [fields, setFields] = useState(initFields);

  const onChange = (evt) => {
    setFields({ ...fields, [evt.target.name]: evt.target.value });
  };

  const updateUploadedFiles = (files) =>
    setFields({
      ...fields,
      images: files,
    });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    addImages(fields);
  };

  return (
    <div className="contact-box">
      <div className={styles.formContainer}>
        <div className={styles.fixedHeader}>
          <h2 className="text-secondary">AddGalleryForm</h2>
        </div>
        <form className={classnames(styles.form, "dzForm container")}>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  onChange={onChange}
                  type="text"
                  name="groupLabel"
                  required
                  className="form-control input"
                  placeholder="Group Label"
                  value={fields.groupLabel}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <FileUpload
                  accept=".jpg,.png,.jpeg,.gif"
                  label="Gallery Image(s)"
                  updateFilesCb={updateUploadedFiles}
                  multiple
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 text-center">
              <button
                type="submit"
                value="Submit"
                className="btn radius-xl btn-lg"
                onClick={handleSubmit}
              >
                Add Image{fields.images.length > 1 ? "s" : ""}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddGalleryForm;
