export const menuConfig = (authorized, accessLevel) =>
  [
    {
      active: true,
      name: "Home",
      link: "/",
      // submenu: [
      //   { link: "/", name: "Home 1" },
      //   { link: "/index-2", name: "Home 2" },
      // ],
    },
    {
      name: "About",
      link: "/about",
      // submenu: [
      //   { link: "/about", name: "About Us 1" },
      //   { link: "/about-2", name: "About Us 2" },
      // ],
    },
    {
      name: "Classes",
      link: "/classes",
      // submenu: [
      //   { link: "/classes", name: "Classes" },
      //   { link: "/classes-details", name: "Classes Details" },
      // ],
    },
    {
      name: "Teachers",
      link: "/teachers",
      // submenu: [
      //   { link: "/teachers", name: "Teachers" },
      //   { link: "/teachers-details", name: "Teachers Details" },
      // ],
    },
    {
      name: "Fun Info",
      submenu: [
        { link: "/faq", name: "FAQ" },
        { link: "/coming-soon", name: "Coming Soon" },
        //   { link: "/event", name: "Event" },
        //   { link: "/event-details", name: "Event Details" },
        //   { link: "/error-404", name: "Error 404" },
      ],
    },
    {
      name: "Gallery",
      link: "/gallery-filter",
      // submenu: [
      //   { link: "/gallery", name: "Gallery Grid" },
      //   { link: "/gallery-masonry", name: "Gallery masonry" },
      //   { link: "/gallery-filter", name: "Gallery Tiles Filter" },
      // ],
    },
    {
      link: "/contact-us",
      name: "Contact",
    },
    { name: "Blogs", link: "/blog-classic-grid" },
    {
      name: "Account",
      submenu: [
        {
          name: "Logout",
          action: "logout",
          authorized: true,
        },
        {
          name: "Login",
          link: "/login",
          authorized: false,
        },
        {
          name: "Sign Up",
          link: "/sign-up",
          authorized: false,
        },
      ].filter((v) => v && !v.authorized === !authorized),
    },
    authorized &&
      accessLevel < 3 && {
        name: "Admin",
        submenu: [{ name: "Create User", link: "/admin" }],
      },
  ].filter((v) => v);
