import React, { useEffect, useState } from "react";
import { useUrls } from "store/urls";

import styles from "./styles.module.scss";

const AddUrlComponent = () => {
  const [url, setUrl] = useState("");

  const {
    actions: { create, get },
    state: { allData },
  } = useUrls();

  useEffect(() => {
    get();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!url) {
      alert("please enter something");
      return;
    }
    create({ url });
    setUrl("");
  };

  return (
    <div>
      <main>
        <section className="w-100 d-flex flex-column justify-content-center align-items-center">
          <h1 className="mb-2 fs-1">URL Shortener</h1>
          <form className="w-50" onSubmit={onSubmit}>
            <input
              className="w-100 border border-primary p-2 mb-2 fs-3 h-25"
              type="text"
              placeholder="http://samplesite.com"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            {!!allData.length && (
              <>
                <div className={styles.listHeader}>Url List</div>
                <table>
                  <thead>
                    <tr>
                      <th>Original</th>
                      <th>Short</th>
                      <th>Clicks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allData.map((data, i) => (
                      <tr key={i}>
                        <td>{data.origUrl}</td>
                        <td>
                          <a href={data.shortUrl}>{data.shortUrl}</a>
                        </td>
                        <td>{data.clicks ?? 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
            <div className="d-grid gap-2 col-6 mx-auto">
              <button type="submit" className="btn btn-danger m-5">
                Shorten!
              </button>
            </div>
          </form>
        </section>
      </main>
    </div>
  );
};

export default AddUrlComponent;
