import React, { useMemo, useState } from "react";
import {
  Link,
  Redirect /* useLocation */,
  useLocation,
} from "react-router-dom";

import VideoPopup from "markup/Element/VideoPopup";
import ButtonPair from "markup/Element/ButtonPair";
import Skills from "./Skills";

import { useGlobal } from "store/global";
import { useTeam } from "store/team";

//Images
import bnr1 from "images/line2.png";
import bnr2 from "images/background/bg4.jpg";
import genericImage from "images/icons/generic.jpg";

import styles from "./styles.module.scss";

const TeachersDetail = () => {
  const { state, updateImage = () => {} } = useLocation();
  const {
    state: { teachers },
    actions: { update },
  } = useTeam();

  const {
    state: { isAdmin },
  } = useGlobal();

  const teacher = teachers.find((v) => v.id === state?.id);

  const [editStatus, setEditStatus] = useState(null);
  const [currEdit, setCurrEdit] = useState({});

  const handleClick = (field) => () => {
    setEditStatus(field);
    setCurrEdit({ [field]: teacher[field] });
  };

  const handleOnChange = (field) => (evt) => {
    setCurrEdit({ [field]: evt.target.value });
  };

  const handleOnCancel = (field) => () => {
    setEditStatus(null);
    setCurrEdit(field ? { [field]: teacher[field] } : {});
  };

  const handleOnSave = (field) => () => {
    update({ [field]: currEdit[field], id: teacher.id });
    setEditStatus(null);
    setCurrEdit({});
  };

  const showImage =
    isAdmin &&
    (!teacher?.imageUrl ||
      teacher?.imageUrl === "undefined" ||
      editStatus === "image");
  const showDesc = isAdmin && (!teacher?.desc || editStatus === "desc");
  const showSubject =
    isAdmin && (!teacher?.subject || editStatus === "subject");
  const showName = isAdmin && (!teacher?.name || editStatus === "name");

  const showRoute = teacher?.id && ((updateImage && isAdmin) || !isAdmin);

  return useMemo(
    () =>
      showRoute ? (
        <div className="page-content">
          <div className="content-block">
            <div
              className="section-full bg-white content-inner-2 teacher-info"
              style={{
                backgroundImage: "url(" + bnr1 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 m-b15">
                    <div className={styles.teacherMedia} onClick={updateImage}>
                      <img
                        src={showImage ? genericImage : teacher?.imageUrl}
                        alt=""
                      />
                      {isAdmin && (
                        <div className="overlay-bx">
                          <div className="overlay-icon">
                            <i className="fa fa-edit icon-bx-xs"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 teacher-content align-self-center">
                    {showName && (
                      <ButtonPair
                        fieldName="desc"
                        onSave={handleOnSave("desc")}
                        onCancel={handleOnCancel("desc")}
                      />
                    )}
                    <div>
                      {showName ? (
                        <input
                          value={currEdit.name ?? teacher.name}
                          onChange={handleOnChange("name")}
                          placeholder="Teacher Name"
                        />
                      ) : (
                        <h2
                          className="teacher-title"
                          onClick={handleClick("name")}
                        >
                          {teacher.name}
                        </h2>
                      )}
                    </div>

                    {showSubject && (
                      <ButtonPair
                        fieldName="subject"
                        onSave={handleOnSave("subject")}
                        onCancel={handleOnCancel("subject")}
                      />
                    )}
                    <div>
                      {showSubject ? (
                        <input
                          value={currEdit.subject ?? teacher.subject}
                          onChange={handleOnChange("subject")}
                          placeholder="Subject"
                        />
                      ) : (
                        <span
                          className="teacher-coures"
                          onClick={handleClick("subject")}
                        >
                          {teacher.subject}
                        </span>
                      )}
                    </div>

                    {showDesc && (
                      <ButtonPair
                        fieldName="desc"
                        onSave={handleOnSave("desc")}
                        onCancel={handleOnCancel("desc")}
                      />
                    )}
                    <div className={styles.desc}>
                      {showDesc ? (
                        <textarea
                          rows={100}
                          value={currEdit.desc ?? teacher.desc}
                          onChange={handleOnChange("desc")}
                          placeholder="Enter teacher info"
                        />
                      ) : (
                        <p className="m-b15" onClick={handleClick("desc")}>
                          {teacher.desc}
                        </p>
                      )}
                    </div>

                    <ul className="list-inline">
                      {teacher.facebook && (
                        <li>
                          <Link to={teacher.facebook}>
                            <i className="fa fa-facebook"></i>
                          </Link>
                        </li>
                      )}
                      {teacher.googlePlus && (
                        <li>
                          <Link to={teacher.googlePlus}>
                            <i className="fa fa-google-plus"></i>
                          </Link>
                        </li>
                      )}
                      {teacher.linkedin && (
                        <li>
                          <Link to={teacher.linkedin}>
                            <i className="fa fa-linkedin"></i>
                          </Link>
                        </li>
                      )}
                      {teacher.instagram && (
                        <li>
                          <Link to={teacher.instagram}>
                            <i className="fa fa-instagram"></i>
                          </Link>
                        </li>
                      )}
                      {teacher.twitter && (
                        <li>
                          <Link to={teacher.twitter}>
                            <i className="fa fa-twitter"></i>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section-full bg-white content-inner-2 about-content bg-img-fix"
              style={{ backgroundImage: "url(" + bnr2 + ")" }}
            >
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-12 "
                    style={{
                      visibility: "visible",
                      animationDuration: "2s",
                      animationDelay: "0.2s",
                      animationName: "fadeIn",
                    }}
                  >
                    <div className="section-head text-center gallery-mpg">
                      <div className="video-play">
                        <VideoPopup url={teacher.videoUrl} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-full content-inner-2">
              <Skills passions={teacher.passions} skills={teacher.skills} />
            </div>
          </div>
        </div>
      ) : (
        <Redirect to="/teachers" />
      ),
    [
      currEdit,
      teacher?.imageUrl,
      teacher?.name,
      teacher?.subject,
      teacher?.desc,
      showRoute,
    ]
  );
};

export default TeachersDetail;
