import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import classnames from "classnames";

import { useResize } from "hooks";
import { preloadImage } from "utils/images";
import { useGlobal } from "store/global";

import styles from "./styles.module.scss";

const bnr1 =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fmain%3Dslider%2Fcustom%2Fslide1.jpg?alt=media&token=143033cd-6ca4-4d25-811c-01dce903954f";
const bnr2 =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fmain%3Dslider%2Fcustom%2Fslide2.jpg?alt=media&token=90163354-534a-4ea6-90aa-f9b9931ab2c0";
const bnr3 =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fmain%3Dslider%2Fcustom%2Fslide3.jpg?alt=media&token=ec1ef655-ced1-4163-aa14-f7cd6a4d909d";

const preloadSrcList = [bnr1, bnr2, bnr3];

const BannerSlider = () => {
  const mountedRef = useRef(false);
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const device = useResize();
  const [rootStyles, setRootStyles] = useState([styles.root]);

  const {
    state: { authenticated },
  } = useGlobal();

  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  useEffect(() => {
    if (mountedRef.current) {
      const effect = async () => {
        const imagesPromiseList = preloadSrcList.map((v) => preloadImage(v));
        await Promise.all(imagesPromiseList);
        if (!mountedRef.current) return;
        setAssetsLoaded(true);
      };
      effect();
    }
  }, []);

  useEffect(() => {
    switch (device) {
      case "PHONE": {
        setRootStyles((state) => [
          ...state.filter((v) => v !== styles.medium),
          styles.small,
        ]);
        break;
      }
      case "TABLET":
      case "TABLETPRO": {
        setRootStyles((state) => [
          ...state.filter((v) => v !== styles.small),
          styles.medium,
        ]);
        break;
      }
      default: {
        setRootStyles((state) =>
          state.filter((v) => v !== styles.small && v !== styles.meddium)
        );
      }
    }
  }, [device]);

  const carouselBlog = useMemo(
    () => [{ image: bnr1 }, { image: bnr2 }, { image: bnr3 }],
    [assetsLoaded]
  );

  return (
    <div
      className={classnames(...rootStyles)}
      style={{
        visibility: assetsLoaded ? "visible" : "hidden",
        animationDuration: "1s",
        // animationDelay: "0.2s",
        animationName: "fadeIn",
        animationFillMode: "forwards",
      }}
    >
      <Carousel
        variant="dark"
        indicators={true}
        controls={false}
        className="owl-slider owl-carousel owl-theme owl-btn-center-lr dots-none"
        autoPlay={true}
        fade={true}
      >
        {carouselBlog.map((item, index) => (
          <Carousel.Item key={index}>
            <div className="slide-item">
              <div className="slide-item-img">
                <img src={item.image} alt="" />
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className={styles.overlay}>
        <h2>
          Boldly Blazing New Trails In Education <strong>K-8</strong>
        </h2>
        <div className={styles.actions}>
          {!authenticated && (
            <Link to={"./sign-up"} className="btn btn-md kids-btn radius-xl">
              Join us
            </Link>
          )}

          <a
            href="https://store9821081.ecwid.com/#!/~/search/keyword=freedom%20learning%20academy"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-secondary">Get Merchandise</button>
          </a>
          <Link to={"./coming-soon"} className="btn btn-md kids-btn radius-xl">
            Events
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
