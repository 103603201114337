import React, { createContext, useContext, useReducer, useMemo } from "react";
import axios from "axios";
import { useGlobal } from "store/global";

const UsersContext = createContext();
UsersContext.displayName = "UsersContext";

const initState = {
  users: [],
};

const createActions = (dispatch, globalActions) => ({
  create: async (data) => {
    globalActions.onFetchStart();
    try {
      const res = await axios.post("/api/users", data);
      const user = res.data?.user;
      globalActions.onFetchSuccess("Successfully created User");
      dispatch({ type: "CREATE", payload: { data: user } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("registerUser Error", serverError);
    }
  },
  update: async (data) => {
    globalActions.onFetchStart();
    try {
      const res = await axios.put("/api/users", data);
      const user = res.data;
      globalActions.onFetchSuccess("Successfully updated User");
      dispatch({ type: "UPDATE", payload: { data: user } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("loginUser Error", serverError);
    }
  },
  delete: async (data) => {
    globalActions.onFetchStart();
    try {
      await axios.delete("/api/users", data);
      globalActions.onFetchSuccess("Successfully deleted User");
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("deleteUser Error", serverError);
    }
  },
  addUsers: (data) => {
    dispatch({ type: "ADDUSERS", payload: { data } });
  },
});

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "CREATE": {
      const newState = { ...state, users: [...state.users, payload.data] };
      return newState;
    }
    case "DELETE": {
      const newState = state.filter((item) => item.id !== payload.data.id);
      return newState;
    }
    case "UPDATE": {
      const newState = state.map((item) =>
        item.id === payload?.data?.id ? { ...item, ...payload?.data } : item
      );
      return newState;
    }
    case "ADDUSERS": {
      const newState = { ...state, users: [...state.users, ...payload?.data] };
      return newState;
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const useUsers = () => {
  const data = useContext(UsersContext);
  if (data === undefined) {
    throw new Error("useUsers must be used within a UsersProvider");
  }
  return data;
};

export const UsersProvider = ({ children }) => {
  const { actions: globalActions } = useGlobal();
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = createActions(dispatch, globalActions);

  return useMemo(
    () => (
      <UsersContext.Provider value={{ state, actions }}>
        {children}
      </UsersContext.Provider>
    ),
    [state, actions, children]
  );
};
