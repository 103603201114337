import React, { useState, useEffect } from "react";

import { useAuth } from "store/auth";
import { useGlobal } from "store/global";

import styles from "./styles.module.scss";

const initFields = {
  email: "",
  password: "",
};

const Login = () => {
  const {
    actions: { login, forgotPassword },
  } = useAuth();
  const {
    state: { authenticated },
    actions: { clearMessage, addMessage },
  } = useGlobal();
  const [fields, setFields] = useState(initFields);

  useEffect(() => clearMessage, []);

  const onChange = (evt) => {
    setFields({ ...fields, [evt.target.name]: evt.target.value });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (!fields?.email) return addMessage("Please enter your email");
    if (!fields?.password) return addMessage("Please enter your password");
    login(fields);
  };

  const handleForgot = () => {
    if (!fields?.email) return addMessage("Please enter your email");
    forgotPassword({ email: fields.email });
  };

  return (
    <>
      <div className="page-content">
        <div className="content-block">
          <div className="section-full bg-white">
            <div className="container">
              <div className="row contact-box content-inner-5">
                <div className="section-head text-center col-md-12">
                  <h2 className="text-secondary">Login</h2>
                </div>
                <div className="dzFormMsg"></div>
                <form className="dzForm col-md-12" autoComplete="off">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <input
                          onChange={onChange}
                          type="email"
                          name="email"
                          className="form-control"
                          required
                          placeholder="Your Email Address"
                          value={fields.email}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          onChange={onChange}
                          type="password"
                          name="password"
                          className="form-control"
                          required
                          placeholder="Password"
                          value={fields.password}
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="row justify-content-center">
                        <div className="col-md-12 col-sm-12 col-12 text-center">
                          <button
                            className="btn radius-xl btn-lg"
                            onClick={handleSubmit}
                          >
                            Log In
                          </button>
                        </div>
                        <div className="col-md-12 col-sm-12 col-12 text-center ">
                          <div className={styles.forgot} onClick={handleForgot}>
                            Forgot Password
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.message} ${
          authenticated ? styles.success : styles.error
        }`}
      ></div>
    </>
  );
};
export default Login;

// loading: false,
// error: false,
// data: {},
// authenticated: false,
// user: null,
// message: "",
