import axios from "axios";

export const createActions = (dispatch, globalActions) => ({
  get: async () => {
    globalActions.onFetchStart({ isLoadingLocal: true });

    try {
      const res = await axios.get("/api/all");
      const { data } = res.data;
      if (!data?.length) {
        globalActions.onFetchSuccess();
        globalActions.addMessage("Sorry, no urls exist.");
        return;
      }

      globalActions.onFetchSuccess("Successfully fetched Urls", false);
      dispatch({ type: "GET", payload: { data } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("fetch Urls Error", serverError);
    }
  },
  create: async ({ url }) => {
    globalActions.onFetchStart();

    try {
      const res = await axios.post("/api/url", { url });
      const data = res.data;
      if (!data) throw new Error("Short Url response is undefined");
      globalActions.onFetchSuccess("Successfully retrieved short Url");
      globalActions.setModalContent(null);
      dispatch({ type: "CREATE", payload: data });
      dispatch({ type: "ADD", payload: data });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("create short Url Error", error?.response?.statusText);
    }
  },
});
