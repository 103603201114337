import React from "react";
import { BrowserRouter } from "react-router-dom";
// import { PortalProvider } from "@gorhom/portal";

import Markup from "markup/Markup";
import Header from "markup/Layout/Header";
import PageTitle from "markup/Layout/PageTitle";

import Footer from "markup/Layout/Footer";

import "./css/plugins.css";
import "./css/style.scss";
import "./css/template.css";
import "./css/skin/skin-1.css";
import "./css/scroll.scss";

import "./plugins/bootstrap-select/bootstrap-select.min.css";
import "./plugins/slick/slick.min.css";
import "react-modal-video/css/modal-video.min.css";

import { AuthProvider } from "store/auth";
import { GlobalProvider } from "store/global";
import { BlogProvider } from "store/blogs";
import { GalleryProvider } from "store/picGallery";
import { UrlProvider } from "store/urls";
import { SocialEventProvider } from "store/socialEvents";

const App = () => (
  <GlobalProvider>
    <div className="App">
      <BrowserRouter basename="/">
        <div className="page-wrapper">
          <UrlProvider>
            <GalleryProvider>
              <BlogProvider>
                <AuthProvider>
                  <Header />
                  <PageTitle />
                </AuthProvider>
                <SocialEventProvider>
                  {/* <PortalProvider> */}
                  <Markup />
                  {/* </PortalProvider> */}
                </SocialEventProvider>
                <AuthProvider>
                  <Footer />
                </AuthProvider>
              </BlogProvider>
            </GalleryProvider>
          </UrlProvider>
        </div>
      </BrowserRouter>
    </div>
  </GlobalProvider>
);

export default App;
