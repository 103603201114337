import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-component";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import Loader from "markup/Element/Loader";
import GalleryImageIcon from "markup/Element/GalleryImageIcon";
import { TagLi } from "./TagLi";
import { classInfo } from "Constants";

const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };

const FilterTab = ({ gallery, onDelete, loadingId, authenticated }) => {
  const [tag, setTag] = useState("Show All");
  const [filteredImages, setFilteredImages] = useState([]);
  const groupLabels = gallery.reduce(
    (acc, item) => {
      if (acc.indexOf(item.groupLabel) === -1) {
        acc.push(item.groupLabel);
      }
      return acc;
    },
    ["Show All"]
  );

  useEffect(() => {
    tag === "Show All"
      ? setFilteredImages(gallery)
      : setFilteredImages(gallery.filter((image) => image.groupLabel === tag));
  }, [tag, gallery]);

  return (
    <>
      <div className="section-full content-inner">
        <div className="container-fluid">
          <div className="section-head text-center">
            <h2 className="head-title text-secondary">
              Gallery of our classes
            </h2>
            <p>{classInfo}</p>
          </div>
          <div className="clearfix" id="lightgallery">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="site-filters filter-style1 clearfix m-b20">
                  <ul className="filters" data-toggle="buttons">
                    {groupLabels.map((label, i) => (
                      <TagLi
                        key={i}
                        name={label}
                        handlesettag={setTag}
                        tagActive={tag === label}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <SimpleReactLightbox>
            <SRLWrapper>
              <ul
                className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery"
                data-wow-duration="2s"
                data-wow-delay="0.2s"
              >
                <Masonry
                  className={"my-gallery-class"} // default ''
                  options={masonryOptions} // default {}
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                  imagesLoadedOptions={imagesLoadedOptions} // default {}
                >
                  {filteredImages.map((item, index) => (
                    <li
                      className="drawing card-container col-lg-3 col-md-6 col-sm-6 wow zoomIn"
                      key={item.id}
                    >
                      <div className="dlab-box frame-box m-b30">
                        <div className="dlab-thum dlab-img-overlay1 ">
                          <img src={item.imageUrl} alt="" />
                          <div className="overlay-bx">
                            <div className="overlay-icon">
                              <GalleryImageIcon imageToOpen={index} />
                            </div>
                            {authenticated && (
                              <div className="row">
                                <div className="col-sm-12 col-md-12">
                                  <button
                                    className="btn btn-sm radius-xl"
                                    onClick={onDelete(item)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {loadingId === item.id && <Loader />}
                    </li>
                  ))}
                </Masonry>
              </ul>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </div>
    </>
  );
};
export default FilterTab;
