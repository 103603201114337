import React from "react";
import { Link } from "react-router-dom";
import { useLightbox } from "simple-react-lightbox";

//Light Gallery Icon
const GalleryImageIcon = ({ imageToOpen }) => {
  const { openLightbox } = useLightbox();
  const onClick = () => openLightbox(imageToOpen)

  return (
    <Link to={"#"} onClick={onClick}>
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};

export default GalleryImageIcon;
