import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

const ButtonGroup = ({ onSave, onCancel }) => {
  return (
    <div className={styles.root}>
      <button className={styles.button} onClick={onSave}>
        Save
      </button>
      <button
        className={classnames(styles.button, styles.cancel)}
        onClick={onCancel}
      >
        Cancel
      </button>
    </div>
  );
};

export default ButtonGroup;
