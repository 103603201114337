import React, { useState, useCallback, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import classnames from "classnames";

import CloseButton from "markup/Element/CloseButton";
import genericImage from "images/icons/generic.jpg";
import { useGlobal } from "store/global";

import styles from "./styles.module.scss";

const Paragraph = ({ text, onClick }) => <div onClick={onClick}>{text}</div>;

const ClassDetailContent = ({
  data,
  commentCount = 0,
  onUpdate,
  onImageUpdate,
}) => {
  const textRef = useRef();
  const [currText, setCurrText] = useState("");
  const [currTitle, setCurrTitle] = useState("");
  const [textDirty, setTextDirty] = useState(false);
  const [titleDirty, setTitleDirty] = useState(false);
  const [showTextEdit, setShowTextEdit] = useState(false);
  const [showTitleEdit, setShowTitleEdit] = useState(false);
  const [rows, setRows] = useState(2);

  const {
    state: { isAdmin },
  } = useGlobal();

  useEffect(() => {
    setCurrText(data.text);
  }, [data.text]);

  useEffect(() => {
    setCurrTitle(data.title);
  }, [data.title]);

  useEffect(() => {
    updateRows();
  }, [currText, showTextEdit]);

  if (!data) {
    return <Redirect to="/blog-classic-grid" />;
  }
  const {
    date: { day, month, year },
  } = data;
  const blockquote = currText.split("<quote>")?.[1]?.trim();
  const paragraphs = currText.replace(/<quote>/g, "").split(/\r?\n/);

  const handleTextChange = (e) => {
    setCurrText(e.currentTarget.value);
    setTextDirty(true);
    setRows(1); // minimum so that it could shrink from prev value
  };

  const handleTitleChange = (e) => {
    setCurrTitle(e.currentTarget.value);
    setTitleDirty(true);
  };

  const updateRows = useCallback(() => {
    if (!textRef?.current) return;
    const height = textRef.current.scrollHeight;
    // Based upon font-size: 1.26rem and line-height: 1.5
    const rowHeight = 1.26 * 16 * 1.5;
    const trows = Math.ceil(height / rowHeight) - 1;
    setRows(trows);
  }, []);

  const handleTextClick = () => {
    if (!isAdmin) return;
    setShowTextEdit(true);
  };

  const handleTitleClick = () => {
    if (!isAdmin) return;
    setShowTitleEdit(true);
  };

  const handleTextSubmit = () => {
    setShowTextEdit(false);
    onUpdate({ text: currText });
  };

  const handleTitleSubmit = (e) => {
    e.stopPropagation();
    setShowTitleEdit(false);
    onUpdate({ title: currTitle });
  };

  const handleTextClose = (e) => {
    e.stopPropagation();
    setShowTextEdit(false);
  };

  const handleTitleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowTitleEdit(false);
  };

  return (
    <div className={styles.classDetail}>
      <div
        className={classnames("class-media", styles.blogMedia)}
        onClick={onImageUpdate}
      >
        <img src={data.imageUrl ?? genericImage} alt="" />
        {isAdmin && (
          <div className="overlay-bx">
            <div className="overlay-icon">
              <i className="fa fa-edit icon-bx-xs"></i>
            </div>
          </div>
        )}
      </div>
      <div className="class-info">
        <div className={classnames("dlab-post-title", styles.blogTitle)}>
          {showTitleEdit ? (
            <div>
              <button
                className={styles.titleSave}
                disabled={!titleDirty}
                onClick={handleTitleSubmit}
              >
                Save
              </button>
              <div className={styles.titleInputWrapper}>
                <input
                  type="text"
                  value={currTitle}
                  placeholder="Title"
                  onChange={handleTitleChange}
                />
                <CloseButton onClick={handleTitleClose} size="SMALL" />
              </div>
            </div>
          ) : (
            <h2
              onClick={handleTitleClick}
              className={classnames("post-title m-t0", styles.title)}
            >
              {data.title}
            </h2>
          )}
        </div>
        <div className="dlab-post-meta m-b20">
          <ul className="d-flex align-items-center">
            <li className="post-date">
              {" "}
              <i className="la la-calendar-o"></i>
              <strong>{`${day} ${month} ${year}`}</strong>
            </li>
            <li className="post-author">
              <i className="la la-user"></i>By{" "}
              <Link to={"/blog-details"}>{data.author}</Link>{" "}
            </li>
            <li className="post-comment">
              <i className="la la-comment"></i>{" "}
              <Link to={"/blog-details"}>{`${commentCount} comments`}</Link>{" "}
            </li>
          </ul>
        </div>
        <div className={classnames("dlab-post-text", styles.blogText)}>
          {showTextEdit ? (
            <div className={styles.textWrap}>
              <button
                className={styles.save}
                disabled={!textDirty}
                onClick={handleTextSubmit}
              >
                Save
              </button>
              <textarea
                placeholder="Enter Text Here"
                ref={textRef}
                rows={rows}
                className={styles.textarea}
                onChange={handleTextChange}
                value={currText}
              />
              <CloseButton onClick={handleTextClose} size="SMALL" />
            </div>
          ) : (
            paragraphs.map((p, i) => (
              <div key={i} className={styles.paragraph}>
                <Paragraph text={p} onClick={handleTextClick} />
                {i === 0 && blockquote && <blockquote>{blockquote}</blockquote>}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
export default ClassDetailContent;
