import React from "react";

import styles from "./styles.module.scss";

const CloseButton = ({ onClick, windowType, size = "LARGE" }) => (
  <div className={`${size === "SMALL" ? styles.small : ""}`}>
    <div
      data-testid="root"
      className={`${styles.root}${
        windowType === "MODAL" ? " " + styles.modalType : ""
      }`}
      onClick={onClick}
    >
      <div data-testid="button" className={styles.closeButton}>
        <div data-testid="in" className={styles.in}>
          <div data-testid="block" className={styles.closeButtonBlock}></div>
          <div data-testid="block" className={styles.closeButtonBlock}></div>
        </div>
        <div data-testid="out" className={styles.out}>
          <div data-testid="block" className={styles.closeButtonBlock}></div>
          <div data-testid="block" className={styles.closeButtonBlock}></div>
        </div>
      </div>
    </div>
  </div>
);

export default CloseButton;
