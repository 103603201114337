const seedToDate = (seed) => {
  return typeof seed?.seconds === "number"
    ? new Date(seed.seconds)
    : typeof seed === "number"
    ? new Date(seed)
    : seed instanceof Date
    ? seed
    : new Date(seed);
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const forceDoubleDigits = (num) => ("0" + num).slice(-2);

export const getFormattedDate = (ms) => {
  const date = new Date(ms);
  const hrsVal = date.getHours();
  const mins = forceDoubleDigits(date.getMinutes());
  const hrs = forceDoubleDigits(hrsVal % 12);
  const meridian = hrsVal >= 12 ? "PM" : "AM";
  const day = date.getDate();
  const month = getMonthName(date, 3);
  const year = date.getFullYear();
  const specialTime =
    hrs !== "00" || mins !== "00"
      ? null
      : meridian === "AM"
      ? "Midnight"
      : "Noon";
  return `${month} ${day} ${year} at ${
    specialTime ?? `${hrs}:${mins} ${meridian}`
  }`;
};

export const getDate = (seed) => seedToDate(seed).getDate();

export const getMonthName = (seed, length) => {
  const date = seedToDate(seed);
  return months[date?.getMonth()]?.substring(0, length);
};
export const getFullYear = (seed) => seedToDate(seed).getFullYear();

export const prepend0 = (n) => ("0" + n).slice(-2);

export const timestampToString = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp.seconds * 1000);
  const str = `${date.getFullYear()}-${prepend0(
    date.getMonth() + 1
  )}-${prepend0(date.getDate())}T${prepend0(date.getHours())}:${prepend0(
    date.getMinutes()
  )}:${prepend0(date.getSeconds())}`;
  return str;
};
export const dateToTimestamp = (data) => {
  const date = typeof data === "string" ? new Date(data) : data;
  return { seconds: date.getTime() / 1000, nanoSeceonds: 0 };
};
