import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  // useCallback,
} from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { createPortal } from "react-dom";

import { useTeam } from "store/team";
import { useGlobal } from "store/global";
import genericImage from "images/icons/generic.jpg";
import Confirmation from "markup/Element/Confirmation";
import AddTeacherForm from "markup/Pages/Teachers/AddTeacherForm";

import styles from "./styles.module.scss";
import "./stylesCSS.scss";

const settings = ({ slidesToShow, infinite }) => ({
  arrows: false,
  slidesToShow,
  infinite,
  autoPlay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3, //3
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
});

const TeacherSlider = ({ add, setAdd }) => {
  const [currentItem, setCurrentItem] = useState();
  const [submitItem, setSubmitItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  const {
    state: { isAdmin, modalMounted },
    actions: { setModalContent },
  } = useGlobal();

  const {
    state: { teachers },
    actions: { get, update, create, delete: remove },
  } = useTeam();
  const sliderRef = useRef(null);

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (add) {
      handleSubmit({})();
      setAdd(false);
    }
  }, [add]);

  useEffect(() => {
    if (!modalMounted) {
      setSubmitItem(false);
      setDeleteItem(false);
    }
  }, [modalMounted]);

  const handleDelete = (item) => () => {
    setModalContent(true);
    setCurrentItem(item);
    setDeleteItem(true);
  };

  const handleSubmit = (item) => () => {
    setModalContent(true);
    setCurrentItem(item);
    setSubmitItem(true);
  };

  const next = () => sliderRef.current.slickNext();
  const previous = () => sliderRef.current.slickPrev();

  const refFunc = (c) => (sliderRef.current = c);
  const updatedSettings = useMemo(
    () => ({
      ...settings({ slidesToShow: 2, infinite: teachers?.length >= 2 }),
    }),
    [teachers?.length]
  );

  const sortedTeachers = teachers.sort((a, b) => {
    if (a.role === "OWNER" && b.role !== "OWNER") return -1;
    if (a.role !== "OWNER" && b.role === "OWNER") return 1;
    return 0;
  });

  return useMemo(
    () => (
      <div className={classnames("sprite-nav", styles.root)}>
        <Slider
          className="team-carousel owl-carousel owl-theme dots-none"
          ref={refFunc}
          {...updatedSettings}
        >
          {sortedTeachers.map((item) => {
            const overlayLink = {
              pathname: `/teachers-details`,
              state: {
                id: item.id,
              },
              updateImage: !isAdmin ? null : handleSubmit(item),
            };

            return (
              <div key={item.id} className={classnames("item", styles.item)}>
                <div
                  className={classnames(
                    "dlab-box box-frame team-box"
                    // `team-frame${(index % 4) + 1}` // Makes cloud shapes different
                  )}
                >
                  <div className="dlab-thum">
                    <Link to="/teachers-details">
                      <img src={item.imageUrl ?? genericImage} alt="" />
                    </Link>
                    <div className="overlay-bx">
                      <Link to={overlayLink}>
                        <div className={styles.overlayClickable} />
                      </Link>
                      <h5 className="team-title">
                        <Link to="/teachers-details">{item.name}</Link>
                      </h5>
                      {item.role === "OWNER" && (
                        <div
                          role="role"
                          className={classnames("team-info", styles.role)}
                        >
                          {item.role}
                        </div>
                      )}
                      <div className="team-info">{item.subject}</div>
                      {isAdmin && (
                        <div className={classnames("row", styles.deleteButton)}>
                          <div className="col-sm-12 col-md-12">
                            <button
                              className="btn btn-sm radius-xl"
                              onClick={handleDelete(item)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                      <ul className="list-inline">
                        {item.facebook && (
                          <li>
                            <Link to="#">
                              <i className="fa fa-facebook"></i>
                            </Link>
                          </li>
                        )}
                        {item.twitter && (
                          <li>
                            <Link to="#">
                              <i className="fa fa-twitter"></i>
                            </Link>
                          </li>
                        )}
                        {item.googlePlus && (
                          <li>
                            <Link to={item.googlePlus}>
                              <i className="fa fa-google-plus"></i>
                            </Link>
                          </li>
                        )}
                        {item.linkedin && (
                          <li>
                            <Link to={item.linkedin}>
                              <i className="fa fa-linkedin"></i>
                            </Link>
                          </li>
                        )}
                        {item.instagram && (
                          <li>
                            <Link to={item.instagram}>
                              <i className="fa fa-instagram"></i>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                    {item.role === "OWNER" && (
                      <div role="outline" className={styles.outline} />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className={classnames("owl-nav", styles.navBtns)}>
          <div className=" owl-prev " onClick={previous} />
          <div className="owl-next " onClick={next} />
        </div>
        {modalMounted &&
          submitItem &&
          createPortal(
            <AddTeacherForm
              submit={
                currentItem?.id
                  ? (fields) => update({ ...currentItem, ...fields })
                  : create
              }
              imageOnly={true}
            />,
            document.getElementById("modalContent")
          )}
        {modalMounted &&
          deleteItem &&
          createPortal(
            <Confirmation
              message="Are you sure that you want to delete this teacher?"
              onSubmit={(allow) => {
                const { id, path } = currentItem;
                if (allow) remove({ id, path });
                setModalContent(false);
              }}
            />,
            document.getElementById("modalContent")
          )}
      </div>
    ),
    [teachers, modalMounted]
  );
};
export default TeacherSlider;
