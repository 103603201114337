import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import styles from "./styles.module.scss";

const MAX = 4;

const Pagination = ({
  page,
  gotoPage,
  nextPage,
  prevPage,
  pageSize,
  totalSize = 40,
}) => {
  const handleGoto = (i) => (e) => {
    e.preventDefault();
    page !== i && gotoPage(i);
  };
  const pages = () => {
    const pageCount = Math.ceil(totalSize / pageSize);
    return [...Array(pageCount)].reduce((acc, _, v) => {
      if (pageCount <= MAX) acc = [...acc, v + 1];
      else if (v < 2 || (v > page - 3 && v < page)) acc = [...acc, v + 1];
      else if (v < page && typeof acc[acc.length - 1] !== "undefined")
        acc = [...acc, undefined];
      return acc;
    }, []);
  };

  return pages().length > 1 ? (
    <div className={classnames(styles.root, "row")}>
      <div className="col-lg-12 col-md-12 col-sm-12 ">
        <div className="pagination-bx rounded pagination-lg clearfix primary">
          <ul
            className={classnames(
              styles.pagination,
              "pagination justify-content-center"
            )}
          >
            <li className="previous">
              <Link to={"#"} onClick={prevPage} disabled={page < 2}>
                <i className="ti-arrow-left"></i> Prev
              </Link>
            </li>
            {pages().map((pageNo) => {
              return pageNo ? (
                <li key={pageNo} className={page === pageNo ? "active" : null}>
                  <Link
                    to={"#"}
                    className={page === pageNo ? "active" : null}
                    onClick={handleGoto(pageNo)}
                  >
                    {pageNo}
                  </Link>
                </li>
              ) : (
                <div key={0} className={styles.pageEllipsis}>
                  <div>...</div>
                </div>
              );
            })}
            <li className="next">
              <Link to={"#"} onClick={nextPage}>
                Next <i className="ti-arrow-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  ) : null;
};

export default Pagination;
