import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { useGlobal } from "store/global";
import { createPageActions, createActions } from './blogActions';

const initState = { blogs: [], currId: null, page: 1 };

const BlogContext = createContext();
const reducer = (state, { type, payload }) => {
  switch (type) {
    case "SETCURRENTID": {
      return { ...state, currId: payload.data };
    }
    case "GET": {
      return { ...state, blogs: payload.data };
    }
    case "ADD_GET": {
      return { ...state, blogs: [...state.blogs, ...payload.data] };
    }
    case "CREATE": {
      return { ...state, blogs: [...state.blogs, payload.data] };
    }
    case "DELETE": {
      return {
        ...state,
        blogs: state.blogs.filter((item) => item.id !== payload.data.id),
      };
    }
    case "UPDATE": {
      return {
        ...state,
        blogs: state.blogs.map((v) => {
          if (v.id === payload.data.id) return payload.data;
          return v;
        }),
      };
    }
    case "PAGE": {
      return { ...state, page: Math.max(payload.data, 1) };
    }
    default:
      throw new Error(`Unhandled blog action type: ${type}`);
  }
};

export const useBlogs = () => {
  const data = useContext(BlogContext);
  if (data === undefined) {
    throw new Error("useBlogs must be used within a BlogProvider");
  }
  return data;
};

export const BlogProvider = ({ children }) => {
  const { actions: globalActions } = useGlobal();
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = useCallback(createActions(state, dispatch, globalActions), [state, dispatch, globalActions]);
  const pageActions = useCallback(createPageActions(state, dispatch, actions), [state, dispatch, actions]);

  return (
    <BlogContext.Provider value={{ state, actions, pageActions }}>
      {children}
    </BlogContext.Provider >
  );
};
