import React from "react";
import styles from "./styles.module.scss";

const Image = ({ src, onLoad, loaded }) => (
  <>
    <img
      src={src}
      onLoad={onLoad}
      className={loaded ? styles.root : styles.notLoaded}
    />
    {!loaded && <div className={styles.spinner}>Loading . . .</div>}
  </>
);

export default Image;
