/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import { useComments } from "store/comments";
import { useGlobal } from "store/global";

import Confirmation from "markup/Element/Confirmation";
import Comment from "./Comment";
import { sortByDate } from "utils";

const CommentList = ({ comments }) => {
  const [showCommentForm, setShowCommentForm] = useState(null);
  const [currParentId, setCurrParentId] = useState(null);
  const {
    state: { authUser },
    actions: { setModalContent, addMessage },
  } = useGlobal();
  const {
    actions: { create, update },
  } = useComments();

  const onSubmit = (parentDocPath) => (e, fields, id) => {
    e.preventDefault();
    if (!authUser) {
      addMessage("Please log in to post.");
    }
    create({
      ...fields,
      author: authUser?.displayName,
      image: authUser?.imageUrl,
      email: authUser?.email,
      userId: authUser?.uid,
      parentDocPath,
    });
    setShowCommentForm(null);
    setCurrParentId(id);
  };

  const onDelete = (docPath) => (e, id) => {
    e.preventDefault();
    setModalContent(
      <Confirmation
        message="Are you sure that you want to delete this Comment?"
        onSubmit={(confirmed) => {
          if (confirmed)
            update({
              rejected: true,
              approved: false,
              docPath,
              id,
              customMsg: "Comment Deleted",
            });
          setModalContent(null);
        }}
      />
    );
  };

  const onApprove = (docPath) => (e, id) =>
    update({
      approved: true,
      docPath,
      id,
    });

  const visibleComments = sortByDate(
    comments.filter(
      (v) => !v.rejected && (v.approved || authUser?.accessLevel < 3)
    )
  );

  return (
    <div className="clear" id="comment-list">
      <div className="comments-area" id="comments">
        <h2 className="comments-title">{comments?.length} Comments</h2>
        <div className="clearfix m-b20">
          <ol className="comment-list">
            {visibleComments?.map((comment) => (
              <li key={comment?.id} className="comment">
                <Comment
                  data={comment}
                  onSubmit={onSubmit}
                  showForm={showCommentForm}
                  setShowForm={setShowCommentForm}
                  currParentId={currParentId}
                  setCurrParentId={setCurrParentId}
                  onApprove={onApprove}
                  onDelete={onDelete}
                />
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default CommentList;
