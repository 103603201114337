import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

const Confirmation = ({
  message,
  onSubmit,
  headingText = "Confirmation",
  affirmText = "Yes",
  rejectText = "Cancel",
}) => {
  const handleSubmit = (allow) => () => {
    onSubmit(allow);
  };

  return (
    <div className=" contact-box">
      <div className={styles.formContainer}>
        <div className={classnames(styles.fixedHeader)}>
          <h2 className="text-secondary">{headingText}</h2>
        </div>
        <form className={classnames(styles.form, "dzForm container")}>
          <div className="row">
            <h4 className="col-sm-12 text-center">{message}</h4>
            <div
              className={classnames(
                styles.buttonContainer,
                "col-sm-12 col-md-6"
              )}
            >
              <button
                className="btn radius-xl btn-lg"
                onClick={handleSubmit(true)}
              >
                {affirmText}
              </button>
            </div>
            <div
              className={classnames(
                styles.buttonContainer,
                "col-sm-12 col-md-6"
              )}
            >
              <button
                className={classnames(styles.cancel, "btn radius-xl btn-lg")}
                onClick={handleSubmit(false)}
              >
                {rejectText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Confirmation;
