import React from "react";
import { Button } from "react-bootstrap";
import { getFormattedDate } from "utils/dateUtils";
import styles from "./styles.module.scss";

const FlyerList = ({ events, edit, updateCurrEvent, remove }) => {
  const handleEdit = (event) => () => {
    updateCurrEvent(event);
    edit();
  };
  const handleDelete = (event) => () => remove(event);

  const list = () =>
    events?.map((event) => (
      <div className={styles.listItem} key={event.id}>
        <div>
          <img src={event.imageUrl} onClick={handleEdit(event)} />
          <span>{getFormattedDate(event.eventDate.seconds * 1000)}</span>
        </div>
        <div>
          <Button size="sm" onClick={handleDelete(event)}>
            Delete
          </Button>
        </div>
      </div>
    ));

  return (
    <>
      <h2>Flyer List</h2>
      {list()}
    </>
  );
};

export default FlyerList;
