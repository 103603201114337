import React from "react";

import bnr1 from "images/line2.png";

import styles from "./styles.module.scss";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fclasses%2Fgened.jpg?alt=media&token=6be0f213-057f-487e-bfeb-4c689a13d636",
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fclasses%2Fmusic.jpg?alt=media&token=02cbeb65-fee6-48c2-9625-4422fa44c40b",
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fclasses%2Fart.jpg?alt=media&token=b710e261-eff4-408f-9c63-a6bfac1d9166",
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fclasses%2Fclassroom.jpg?alt=media&token=f11c2e63-d2a3-4cc8-9b05-85025a588641",
];

const classesBlog = [
  {
    image: images[0],
    title: "General Education",
    schedule: { timeStart: "8:30am", timeEnd: "3:30pm" },
    sizeRange: "12-18",
    ageRange: "5-14",
    price: 584,
  },
  {
    image: images[1],
    title: "Music",
    schedule: { dow: "Monday", timeStart: "1:45pm", timeEnd: "3:00pm" },
    sizeRange: "6-12",
    ageRange: "5-14",
    price: 20,
  },
  {
    image: images[2],
    title: "Art",
    schedule: { dow: "Wednesday", timeStart: "10:00am", timeEnd: "11:30am" },
    sizeRange: "12-18",
    ageRange: "5-14",
    price: 20,
  },
  {
    image: images[3],
    title: "Spanish",
    schedule: { timeStart: "8:30am", timeEnd: "3:30am" },
    sizeRange: "12-18",
    ageRange: "5-14",
    price: 20,
  },
];

const Classes = () => (
  <div className="page-content">
    <div className="content-block">
      <div
        className="section-full bg-white content-inner"
        style={{
          backgroundImage: "url(" + bnr1 + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row sp40">
            {classesBlog.map((data, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                <div className="class-item">
                  <div className="class-media">
                    <img src={data.image} alt="" />
                    <p>
                      <strong>Class Time:&nbsp;</strong>
                      {`${data.schedule.dow ?? "Everyday"}: ${
                        data.schedule.timeStart
                      } - ${data.schedule.timeEnd}`}
                    </p>
                  </div>
                  <div className="class-info">
                    <h4>
                      {/* <Link to={"/classes-details"}> */}
                      {data.title}
                      {/* </Link> */}
                    </h4>
                    <p>{data.desc}</p>
                    <ul className="schedule">
                      <li className="bg-blue class-size">
                        <span>Class Size</span> <span>{data.sizeRange}</span>
                      </li>
                      <li className="bg-green years-old">
                        <span>Years Old</span> <span>{data.ageRange}</span>
                      </li>
                      <li className="bg-orange tution">
                        <span>Tution Fee*</span>{" "}
                        <span>{`${
                          data.price ? `$${data.price}` : "Inc'd"
                        }`}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.footnote}>
            *Tuition fees are paid monthly. However other options are available.
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Classes;
