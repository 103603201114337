import React, { useState, useEffect, useCallback, useMemo } from "react";
import classnames from "classnames";
import { Redirect, useLocation } from "react-router-dom";
import { createPortal } from "react-dom";

import Lottie from "react-lottie";

import { useGlobal } from "store/global";
import { useSocialEvents } from "store/socialEvents";

import thumbtackImage from "images/notifications/thumbtack.png";
import clickMe from "images/lotties/clickme2";

import styles from "./styles.module.scss";
import Image from "../Image";

const ANIM_TIME = 1500;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: clickMe,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Notification = () => {
  const [zoom, setZoom] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [redirect, setRedirect] = useState();
  const [drop, setDrop] = useState(false);
  const [dropped, setDropped] = useState(false);
  const [initFlyer, setInitFlyer] = useState(true);
  const [enterFlyer, setEnterFlyer] = useState(false);
  const [swingFlyer, setSwingFlyer] = useState(false);
  const [hoverInPin, setHoverInPin] = useState(false);
  const [hoverOutPin, setHoverOutPin] = useState(false);
  const [clickMe, setClickMe] = useState(false);

  const { pathname } = useLocation();

  const {
    state: { currEvent, eventExpired, adLaunched },
  } = useSocialEvents();

  const {
    actions: { setNotificationPosition, setModalContent },
    state: { showNotification, notificationExpired, modalMounted },
  } = useGlobal();

  const normal = useMemo(() => pathname === "/coming-soon", [pathname]);

  const show = useMemo(
    () =>
      !eventExpired &&
      adLaunched &&
      !!currEvent?.imageUrl &&
      (normal || (!notificationExpired && (pathname === "/" || normal))) &&
      !zoom,
    [
      eventExpired,
      adLaunched,
      currEvent?.imageUrl,
      normal,
      notificationExpired,
      pathname,
      zoom,
    ]
  );

  useEffect(() => {
    const pos = normal ? "RELATIVE" : "FIXED";
    setNotificationPosition(pos);
  }, [pathname]);

  useEffect(() => {
    if (!modalMounted) {
      setZoom(false);
    }
  }, [modalMounted]);

  useEffect(() => {
    let timer;
    if (drop) {
      setSwingFlyer(false);
      setClickMe(false);
      setTimeout(() => {
        setDropped(true);
      }, ANIM_TIME);
    } else if (showNotification && imageLoaded) {
      setTimeout(() => {
        setDrop(false);
        setEnterFlyer(false);
        setSwingFlyer(true);
        setClickMe(true);
      }, ANIM_TIME);
      setEnterFlyer(true);
      setInitFlyer(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [drop, showNotification, notificationExpired, imageLoaded]);

  const handlePinDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrop(true);
  };

  const handlePinHover = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHoverInPin(true);
    setHoverOutPin(false);
  };

  const handlePinHoverOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHoverInPin(false);
    setHoverOutPin(true);
  };

  const onLoad = () => setImageLoaded(true);

  const handleZoom = () => {
    setModalContent(true);
    setZoom(true);
  };

  const navToNote = useCallback(() => {
    if (normal) {
      handleZoom();
      return;
    }
    setRedirect("/coming-soon");
  }, [handleZoom, normal]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : show ? (
    <div
      className={classnames(
        normal && styles.relative,
        styles.container,
        dropped && styles.hide
      )}
      onClick={navToNote}
    >
      <div
        className={classnames(
          styles.root,
          normal && styles.normal,
          drop && styles.drop,
          enterFlyer && styles.enterFlyer,
          initFlyer && styles.initFlyer,
          swingFlyer && styles.swingFlyer
        )}
      >
        {!normal && <div className={styles.curl}></div>}
        <Image src={currEvent?.imageUrl} onLoad={onLoad} loaded={imageLoaded} />
      </div>
      {!normal && (
        <>
          <div
            className={classnames(
              styles.thumbtackWrapper,
              drop && styles.drop,
              showNotification && !drop && imageLoaded
                ? styles.enterPin
                : !drop
                ? styles.initPin
                : null,
              hoverInPin && styles.hoverInPin,
              hoverOutPin && styles.hoverOutPin
            )}
          >
            <img
              src={thumbtackImage}
              onClick={handlePinDrop}
              onMouseEnter={handlePinHover}
              onMouseLeave={handlePinHoverOut}
              className={classnames(
                styles.thumbtack,
                drop && !normal && styles.drop,
                showNotification && !drop && imageLoaded
                  ? styles.enterPin
                  : !drop
                  ? styles.initPin
                  : null
              )}
            />
          </div>
          <div
            className={classnames(
              styles.clickMe,
              clickMe && styles.showClickMe,
              drop && styles.drop
            )}
          >
            <Lottie options={defaultOptions} height={70} width={70} />
          </div>
        </>
      )}
    </div>
  ) : zoom && modalMounted ? (
    createPortal(
      <div className={styles.imgWrapper}>
        <Image src={currEvent?.imageUrl} onLoad={onLoad} loaded={imageLoaded} />
      </div>,
      document.getElementById("modalContent")
    )
  ) : null;
};

export default Notification;
