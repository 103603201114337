import React from 'react';
import { Link } from "react-router-dom";
import classnames from "classnames";

import styles from "./styles.module.scss";

const Section = ({ smallText, largeText, action, bgUrl, bgStatic = true, noBorder = false, children }) => (
  <div
    className={classnames(
      "section-full bg-white content-inner-2",
      bgStatic ? "bg-img-fix" : styles.bgStatic,
      bgUrl && !noBorder && "about-content"
    )}
    style={bgUrl && {
      backgroundImage: "url(" + bgUrl + ")",
      backgroundPositionY: bgStatic ? -300 : 200,
    }}
  >
    {(smallText || largeText) &&
      (<div className="container">
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-12 about-text"
            style={{
              visibility: "visible",
              animationDuration: "2s",
              animationDelay: "0.2s",
              animationName: "fadeIn",
            }}
          >
            <div className="section-head text-center">
              {smallText && <h4 className={classnames("text-white", styles.overlayText)}>
                {smallText}
              </h4>}
              {largeText && <h2 className={styles.overlayText}>
                {largeText}
              </h2>}
              {action && <Link to={action.link} className="btn btn-md radius-xl">
                {action.text}
              </Link>}
            </div>
          </div>
        </div>
      </div>)
    }
    <div className="container">
      {children}
    </div>
  </div>
);

export default Section;