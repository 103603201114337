import React, { useState, useEffect } from "react";
import classnames from "classnames";

import { useAuth } from "store/auth";
import { useGlobal } from "store/global";
import fieldsConfig from "./fieldsConfig";
import { capitalize } from "utils";

import styles from "./styles.module.scss";

const SignUp = () => {
  const { actions: { register } } = useAuth();
  const {
    state: { authenticated },
    actions: { clearMessage, addMessage },
  } = useGlobal();
  const initFields = fieldsConfig.reduce((acc, v) => {
    acc[v.name] = v.initValue;
    return acc;
  }, {});
  const [fields, setFields] = useState(initFields);

  useEffect(() => clearMessage, []);

  const onChange = (e) =>
    setFields({ ...fields, [e.target.name]: e.target.value });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (!fields?.type && fieldsConfig?.find(v => v.name === "type")?.required) return addMessage("Please choose a user type");
    if (!fields?.email && fieldsConfig?.find(v => v.name === "email")?.required) return addMessage("Please enter your email");
    if (!fields?.password && fieldsConfig?.find(v => v.name === "password")?.required) return addMessage("Please enter your password");
    if (!fields?.fname && fieldsConfig?.find(v => v.name === "fname")?.required) return addMessage("Please enter your First Name");
    if (!fields?.lname && fieldsConfig?.find(v => v.name === "lname")?.required) return addMessage("Please enter your Last Name");
    if (!fields?.childFirstName && fieldsConfig?.find(v => v.name === "childFirstName")?.required) return addMessage("Please enter your child's First Name");
    if (!fields?.childLastName && fieldsConfig?.find(v => v.name === "childLastName")?.required) return addMessage("Please enter your child's Last Name");
    register(fields);
  };

  const isNewRow = (() => {
    let currTotalWidth = 0;

    return (width) => {
      currTotalWidth += width;
      if (currTotalWidth > 12 || currTotalWidth === width) {
        currTotalWidth = width;
        return true;
      }
      return false;
    }
  })();

  const getRows = () => {
    const rows = [];
    const currCols = [];
    fieldsConfig
      .filter(v => !v.hidden)
      .forEach((f, i, array) => {
        const newRow = isNewRow(f.width);
        const options = f.type === "list" ? ["Choose User Type", ...f.options] : null;
        const col = <div className={`col-md-${f.width} col-sm-12`}>
          <div className="form-group">
            {
              !options
                ? <input
                  onChange={onChange}
                  type={f.type}
                  name={f.name}
                  className="form-control"
                  required={f.required}
                  placeholder={f.label}
                  value={fields[f.name]}
                />
                : <select
                  onChange={onChange}
                  className={classnames("form-control", styles.select)}
                  name={f.name}
                  required={f.required}
                >
                  {options.map((o, i) => (
                    <option key={o} hidden={i === 0} selected={fields[f.name] === o} value={o}>{capitalize(o)}</option>
                  )
                  )}
                </select>
            }
          </div>
        </div>
        if (newRow && currCols.length) {
          rows.push([...currCols]);
          currCols.length = 0;
        }
        currCols.push(col);
        if (i >= array.length - 1)
          rows.push([...currCols]);
      })
    return rows;
  }

  return (
    <>
      <div className="page-content">
        <div className="content-block">
          <div className="section-full bg-white">
            <div className="container">
              <div className="row contact-box content-inner-5">
                <div className="section-head text-center col-md-12">
                  <h2 className={classnames("text-secondary")}>Sign Up</h2>
                </div>
                <div className="dzFormMsg"></div>
                <form className="dzForm col-md-12" autoComplete="off">
                  {
                    getRows().map((row, i) => (
                      <div key={i} className="row">
                        {row.map(col => (
                          <>{col}</>
                        ))}
                      </div>
                    ))
                  }
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="row justify-content-center">
                        <div className="col-md-12 col-sm-12 col-12 text-center">
                          <button
                            className="btn radius-xl btn-lg"
                            onClick={handleSubmit}
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.message} ${authenticated ? styles.success : styles.error
          }`}
      ></div>
    </>
  );
};
export default SignUp;
