import React, { useRef, useState } from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

const DEFAULT_MAX_FILE_SIZE = 500000000;

const convertBytesToKB = (bytes) => Math.round(bytes / 1000);

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE,
  multiple: multi,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState([]);

  const handleProxyBtnClick = () => {
    fileInputField.current.click();
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (!newFiles.length) return;
    const filteredFiles = filterFiles(newFiles);
    setFiles(filteredFiles);
    callUpdateFilesCb(filteredFiles);
  };

  const filterFiles = (newFiles) =>
    [...newFiles].filter((v) => v.size <= maxFileSizeInBytes);

  const callUpdateFilesCb = (files) => updateFilesCb(files);

  const removeFile = (fileName) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    const newFiles = files.filter((v) => v.name !== fileName);
    setFiles(newFiles);
    callUpdateFilesCb(newFiles);
  };

  return (
    <>
      <div className={styles.fileUploadContainer}>
        <label className={styles.inputLabel}>{label}</label>

        <p className={styles.dragDropText}>
          Drag and drop your image
          {multi ? "s" : ""} anywhere or
        </p>

        <button
          className={styles.uploadFileBtn}
          type="button"
          onClick={handleProxyBtnClick}
        >
          <i className="fas fa-file-upload" />
          <span> Upload {multi ? "files" : "a file"}</span>
        </button>

        <input
          className={styles.formField}
          type="file"
          ref={fileInputField}
          multiple={multi}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </div>

      {files?.length && (
        <article>
          <span style={{ color: "grey" }}>To Upload</span>
          <section
            className={classnames(styles.previewList, styles.articleSection)}
          >
            {files?.map((file, index) => {
              const isImageFile = file.type.split("/")[0] === "image";
              return (
                <section key={index} className={styles.previewContainer}>
                  <div>
                    {isImageFile && (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`file preview ${index}`}
                      />
                    )}

                    <div className={styles.fileMetaData}>
                      <span>{file.name}</span>
                      <aside>
                        <span>{convertBytesToKB(file.size)} kb</span>
                        <i
                          className={classnames(
                            "fa fa-trash",
                            styles.removeFileIcon
                          )}
                          onClick={removeFile(file.name)}
                        />
                      </aside>
                    </div>
                  </div>
                </section>
              );
            })}
          </section>
        </article>
      )}
    </>
  );
};

export default FileUpload;
