import React, { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import Slider from "react-slick";
import classnames from "classnames";

// import generic from "images/testimonials/pic2.jpg";
import generic from "images/testimonials/generic.png";
import ButtonPair from "markup/Element/ButtonPair";
import Confirmation from "markup/Element/Confirmation";
import { useTestimonials } from "store/testimonials";
import { useGlobal } from "store/global";

import styles from "./styles.module.scss";

const settings = {
  arrows: false,
  slidesToShow: 1,
  infinite: true,
  autoPlay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const TestimonialSlider = () => {
  const [editStatus, setEditStatus] = useState(null);
  const [action, setAction] = useState("");
  const [currEdit, setCurrEdit] = useState({});
  const [showDeletes, setShowDeletes] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [approveItem, setApproveItem] = useState(false);
  const [currentItem, setCurrentItem] = useState();

  const sliderRef = useRef(null);

  const {
    state: { authUser, modalMounted },
    actions: { setModalContent },
  } = useGlobal();
  const { id: userId } = authUser ?? {};
  const admin = authUser?.accessLevel < 3;
  const canEdit = admin || (userId && currentItem.userId === userId);

  const {
    actions: { get, update },
    state: { testimonials },
  } = useTestimonials();
  const visibleTestimonials = testimonials.filter(
    (v) =>
      (!v.rejected || showDeletes) &&
      (v.approved || authUser?.accessLevel < 3 || v.userId === authUser?.id)
  );

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    sliderRef?.current?.slickGoTo(visibleTestimonials.length - 1);
  }, [visibleTestimonials.length]);

  useEffect(() => {
    if (!modalMounted) {
      setApproveItem(false);
      setDeleteItem(false);
    }
  }, [modalMounted]);

  const handleDelete = (item) => () => {
    if (!canEdit) return;
    setModalContent(true);
    setCurrentItem(item);
    setDeleteItem(true);
  };

  const handleApprove = (item) => () => {
    if (!canEdit) return;
    const _action = item.approved ? "reject" : "approve";

    setAction(_action);
    setModalContent(true);
    setCurrentItem(item);
    setApproveItem(true);
  };

  const next = () => {
    sliderRef?.current?.slickNext();
  };

  const previous = () => {
    sliderRef?.current?.slickPrev();
  };

  const onEditEnable = (field, item) => () => {
    if (!canEdit) return;
    setEditStatus(field);
    setCurrEdit({ [field]: item[field] });
  };

  const handleOnChange = (field) => (evt) => {
    setCurrEdit({ [field]: evt.target.value });
  };

  const handleOnCancel = (field, item) => () => {
    if (!canEdit) return;
    setEditStatus(null);
    setCurrEdit(field ? { [field]: item[field] } : {});
  };

  const handleOnSave = (field, item) => () => {
    if (!canEdit) return;
    update({ [field]: currEdit[field], id: item.id });
    setEditStatus(null);
    setCurrEdit({});
  };

  const toggleShowDeletes = () => setShowDeletes((state) => !state);

  return (
    <div>
      {admin && (
        <label className={styles.checkbox}>
          Show deletes
          <input
            type="checkbox"
            checked={showDeletes}
            onChange={toggleShowDeletes}
          />
          <span className={styles.checkmark}></span>
        </label>
      )}
      {visibleTestimonials.length ? (
        <div className="sprite-nav">
          <Slider
            className="client-carousel owl-carousel owl-theme  "
            ref={(c) => (sliderRef.current = c)}
            {...settings}
          >
            {visibleTestimonials?.map((item, index) => {
              // const owner = userId && item.userId === userId;
              const showText = !item?.text || editStatus === "text";
              return (
                <div className={classnames("item", styles.root)} key={index}>
                  <div
                    className={classnames(
                      "client-box",
                      styles.clientBox,
                      item.rejected && styles.deleted
                    )}
                  >
                    <div className="testimonial-detail clearfix">
                      <div className="testimonial-pic radius">
                        <img src={generic} width="100" height="100" alt="" />
                      </div>
                      <h5 className="testimonial-name m-t0 m-b5">
                        {item.fname}
                      </h5>
                    </div>
                    <div
                      className={classnames(
                        "testimonial-text",
                        styles.textContainer
                      )}
                    >
                      <div
                        className={
                          item.approved
                            ? styles.removeWatermark
                            : styles.watermark
                        }
                      >
                        Awaiting Approval
                      </div>
                      {showText && (
                        <ButtonPair
                          fieldName="text"
                          onSave={handleOnSave("text", item)}
                          onCancel={handleOnCancel("text", item)}
                        />
                      )}
                      {showText ? (
                        <textarea
                          className={styles.text}
                          rows={10}
                          value={currEdit.text ?? item.text}
                          onChange={handleOnChange("text", item)}
                          placeholder="Enter testimonial"
                        />
                      ) : (
                        <p
                          className={classnames(
                            "m-b15",
                            item.rejected && styles.deleted
                          )}
                          onClick={onEditEnable("text", item)}
                        >
                          {item.text}
                        </p>
                      )}
                    </div>
                  </div>
                  {admin && (
                    <>
                      <i
                        onClick={handleDelete(item)}
                        className={classnames(
                          styles.iconButton,
                          styles.minus,
                          "fa fa-minus-circle icon-bx-xs"
                        )}
                      ></i>
                      <i
                        onClick={handleApprove(item)}
                        className={classnames(
                          styles.iconButton,
                          styles.check,
                          item.approved && styles.approved,
                          "fa fa-check-circle icon-bx-xs"
                        )}
                      ></i>
                    </>
                  )}
                </div>
              );
            })}
          </Slider>
          <div
            className="owl-nav"
            style={{ textAlign: "center", paddingBottom: "4rem" }}
          >
            <div className=" owl-prev " onClick={previous} />
            <div className="owl-next " onClick={next} />
          </div>
        </div>
      ) : null}

      {modalMounted &&
        approveItem &&
        createPortal(
          <Confirmation
            message={`Are you sure that you want to ${action} this Testimonial?`}
            onSubmit={(allow) => {
              if (allow)
                update({
                  id: currentItem.id,
                  approved: !currentItem.approved,
                  rejected: false,
                });
              setModalContent(false);
            }}
            affirmText={action}
            rejectText="Cancel"
          />,
          document.getElementById("modalContent")
        )}

      {modalMounted &&
        deleteItem &&
        createPortal(
          <Confirmation
            message="Are you sure that you want to delete this Testimonial?"
            onSubmit={(allow) => {
              if (allow)
                update({ id: currentItem.id, rejected: true, approved: false });
              setModalContent(false);
            }}
            headingText="Delete Confirmation"
            affirmText="Delete"
          />,
          document.getElementById("modalContent")
        )}
    </div>
  );
};
export default TestimonialSlider;
