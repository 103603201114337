import { menuConfig } from "store/menuConfig";
import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
  memo,
  // useMemo,
} from "react";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import { messageStatusEnum as msgEnum } from "utils";
import axios from "axios";

export const initState = {
  showNotification: false,
  notificationExpired: false,
  notificationPosition: "FIXED",
  time: null,
  modalContent: null,
  showModal: null,
  modalMounted: false,
  loading: { global: true, status: false },
  error: "",
  authenticated: false,
  authUser: null,
  accessLevel: null,
  isAdmin: false,
  users: [],
  message: { status: "", message: "" }, // status -> SUCCESS,WARNING,ERROR,INFO
  menuConfig: menuConfig(),
  scrollToTop: false,
  modalCloseCallback: null,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "SHOW_NOTIFICATION": {
      return { ...state, showNotification: true };
    }
    case "NOTIFICATION_EXPIRED": {
      return { ...state, notificationExpired: true };
    }
    case "NOTIFICATION_POSITION": {
      return { ...state, notificationPosition: payload.data };
    }
    case "HIDE_NOTIFICATION": {
      return { ...state, showNotification: false };
    }
    case "LOADING_GLOBAL": {
      return { ...state, loading: { global: true, status: true } };
    }
    case "LOADING_LOCAL": {
      return { ...state, loading: { global: false, status: true } };
    }
    case "LOADED": {
      return { ...state, loading: initState.loading };
    }
    case "LOGGED_IN": {
      return {
        ...state,
        authenticated: true,
        authUser: payload.data,
        accessLevel: payload.data?.accessLevel,
        isAdmin: payload.data?.accessLevel < 3,
      };
    }
    case "LOGGED_OUT": {
      return {
        ...state,
        authenticated: false,
        authUser: null,
        accessLevel: null,
        isAdmin: false,
      };
    }
    case "MESSAGE": {
      return { ...state, message: payload.data };
    }
    case "CLEAR_MESSAGE": {
      return { ...state, message: {} };
    }
    case "FETCH_SUCCESS": {
      return { ...state, error: "" };
    }
    case "FETCH_FAIL": {
      return { ...state, error: payload.data };
    }
    case "ADDUSERS": {
      return { ...state, users: [...state.users, ...payload?.data] };
    }
    case "SHOW_MODAL": {
      return { ...state, modalContent: payload.data, showModal: true };
    }
    case "HIDE_MODAL": {
      return { ...state, modalContent: null, showModal: false };
    }
    case "MODAL_MOUNTED": {
      return { ...state, modalMounted: payload.data };
    }
    case "MODAL_CLOSE_CALLBACK": {
      return { ...state, modalCloseCallback: payload.data };
    }
    case "CONFIGURE_MENU": {
      return { ...state, menuConfig: payload.data };
    }
    case "UPDATE_TIME": {
      return { ...state, time: payload.data };
    }
    case "SCROLL_TO_TOP": {
      return { ...state, scrollToTop: payload.data };
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const createActions = (state, dispatch) => ({
  setShowNotification: (show) => {
    dispatch({ type: show ? "SHOW_NOTIFICATION" : "HIDE_NOTIFICATION" });
  },
  setNotificationExpired: () => {
    dispatch({ type: "NOTIFICATION_EXPIRED" });
  },
  setNotificationPosition: (pos) => {
    dispatch({ type: "NOTIFICATION_POSITION", payload: { data: pos } });
  },
  addMessage: async (message) => {
    dispatch({
      type: "MESSAGE",
      payload: { data: { status: msgEnum.INFO, message } },
    });
    setTimeout(() => {
      dispatch({ type: "CLEAR_MESSAGE" });
    }, 3000);
  },
  clearMessage: () => {
    dispatch({ type: "CLEAR_MESSAGE" });
  },
  onAuthSuccess: (authUser) => {
    dispatch({ type: "LOGGED_IN", payload: { data: authUser } });
    dispatch({ type: "LOADED" });
    dispatch({
      type: "MESSAGE",
      payload: {
        data: {
          status: msgEnum.SUCCESS,
          message: "Successfully logged in!!",
        },
      },
    });
    setTimeout(() => {
      dispatch({ type: "CLEAR_MESSAGE" });
    }, 3000);
    dispatch({
      type: "CONFIGURE_MENU",
      payload: { data: menuConfig(true, authUser.accessLevel) },
    });
  },
  onAuthFail: (errorMsg) => {
    dispatch({ type: "LOGGED_OUT" });
    dispatch({ type: "LOADED" });
    dispatch({
      type: "MESSAGE",
      payload: {
        data: {
          status: msgEnum.ERROR,
          message: `Authorization failed: ${errorMsg}`,
        },
      },
    });
    dispatch({
      type: "CONFIGURE_MENU",
      payload: { data: menuConfig(false) },
    });
  },
  onLogout: () => {
    dispatch({ type: "LOGGED_OUT" });
    dispatch({ type: "LOADED" });
    dispatch({
      type: "MESSAGE",
      payload: {
        data: {
          status: msgEnum.SUCCESS,
          message: "User successfully logged out",
        },
      },
    });
    dispatch({
      type: "CONFIGURE_MENU",
      payload: { data: menuConfig(false) },
    });
    setLocalStorage("authUser", null);
  },
  onFetchStart: ({ isLoadingLocal } = { isLoadingLocal: false }) => {
    dispatch({
      type: isLoadingLocal ? "LOADING_LOCAL" : "LOADING_GLOBAL",
    });
    dispatch({ type: "CLEAR_MESSAGE" });
  },
  onFetchSuccess: (message, showNotification = true) => {
    dispatch({ type: "FETCH_SUCCESS" });
    dispatch({ type: "LOADED" });
    if (showNotification) {
      dispatch({
        type: "MESSAGE",
        payload: { data: { status: msgEnum.SUCCESS, message } },
      });
      setTimeout(() => {
        dispatch({ type: "CLEAR_MESSAGE" });
      }, 3000);
    }
  },
  onFetchFail: ({
    serverError = "Oopsies. There was a problem",
    displayError,
  }) => {
    dispatch({
      type: "FETCH_FAIL",
      payload: { data: serverError ?? displayError },
    });
    dispatch({ type: "LOADED" });
    dispatch({
      type: "MESSAGE",
      payload: {
        data: { status: msgEnum.ERROR, message: displayError ?? serverError },
      },
    });
  },
  setActiveMenu: (pathname) => {
    const isInMenu = (item, path) =>
      !!item.submenu?.find((v) => v.link === path);

    const newMenu = state.menuConfig.map((v) => {
      // TODO: location is not defined
      v.active = v.link === location.pathname || isInMenu(v, pathname);
      return v;
    });

    dispatch({
      type: "CONFIGURE_MENU",
      payload: { data: newMenu },
    });
  },
  getAuth: () => {
    const authUser = getLocalStorage("authUser");
    if (authUser) {
      dispatch({ type: "LOGGED_IN", payload: { data: authUser } });
      dispatch({ type: "ADDUSERS", payload: { data: [authUser] } });
      dispatch({
        type: "CONFIGURE_MENU",
        payload: { data: menuConfig(true, authUser.accessLevel) },
      });
    }
  },
  sendMail: async (data) => {
    try {
      dispatch({ type: "LOADING_GLOBAL" });
      dispatch({ type: "CLEAR_MESSAGE" });
      await axios.post("api/sendMessage", data);
      dispatch({
        type: "MESSAGE",
        payload: {
          data: {
            status: msgEnum.SUCCESS,
            message: "Email Sent Successfully!",
          },
        },
      });
      dispatch({ type: "LOADED" });
      setTimeout(() => {
        dispatch({ type: "CLEAR_MESSAGE" });
      }, 3000);
    } catch (err) {
      dispatch({ type: "LOADED" });
      dispatch({
        type: "MESSAGE",
        payload: {
          data: { status: msgEnum.ERROR, message: err.message },
        },
      });
      setTimeout(() => {
        dispatch({ type: "CLEAR_MESSAGE" });
      }, 3000);
    }
  },
  loadingLocal: () => {
    dispatch({ type: "LOADING_LOCAL" });
  },
  setLoading: (loading) => {
    dispatch({ type: loading ? "LOADING_GLOBAL" : "LOADED" });
  },
  setModalMounted: (mounted = false) => {
    dispatch({ type: "MODAL_MOUNTED", payload: { data: mounted } });
  },
  setModalContent: (content, closeCallback = null) => {
    if (content) {
      dispatch({
        type: "MODAL_CLOSE_CALLBACK",
        payload: { data: closeCallback },
      });
      dispatch({ type: "SHOW_MODAL", payload: { data: content } });
    } else {
      dispatch({ type: "HIDE_MODAL" });
      state.modalCloseCallback?.();
    }
  },
  updateTime: (time) => {
    dispatch({ type: "UPDATE_TIME", payload: { data: time } });
  },
  setScrollToTop: (scrollToTop) => {
    dispatch({ type: "SCROLL_TO_TOP", payload: { data: scrollToTop } });
  },
});

export const GlobalContext = createContext();
GlobalContext.displayName = "GlobalContext";

export const useGlobal = () => {
  const data = useContext(GlobalContext);
  if (data === undefined) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return data;
};
const GlobalProvider = ({
  children,
  value: { state: oState, actions: oActions } = {}, // for testing
}) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = useCallback(
    { ...createActions(state, dispatch), ...oActions },
    [state, oActions]
  );

  useEffect(() => {
    dispatch({
      type: "CONFIGURE_MENU",
      payload: { data: menuConfig(state.authenticated, state.accessLevel) },
    });
  }, [state.authenticated, state.accessLevel]);

  return (
    <GlobalContext.Provider value={{ state: oState ?? state, actions }}>
      {children}
    </GlobalContext.Provider>
  );
};

const MemoizedGlobalProvider = memo(GlobalProvider);

export { MemoizedGlobalProvider as GlobalProvider };
