import React from "react";

import InputBlog from "./TeachersDetail/InputBlog";
import AccordionBlog from "../Element/AccordionBlog/AccordionBlog";
//Images
import bnr1 from "images/line2.png";

const data = [
  {
    title: "What does 'Montessori-based' mean?",
    text: `Maria Montessori created a child-led, natural learning style that revolutionized the way teachers teach. Our system uses her core ideas with natural learning, life-skills, and respect for nature.  However, we also pull from other resources.
`,
  },
  {
    title: "What is 'NAPS'?",
    text: `NAPS stands for Non-Accredited Private School. When registering a homeschool with the state of Kansas, it becomes an NAPS. Our goal is to stay clear of government interference, and therefore will remain a Non-Accredited Private School.
`,
  },
  {
    title: `How is Freedom Learning Academy like homeschool?`,
    text: `Students who are homeschooled receive specialized one-on-one attention and have more time to explore topics, move at their own pace, and get outdoors. Our schedule and philosophy mimic the pros of homeschooling, from teacher to student ratios - to flexible work spaces.
`,
  },
  {
    title: `Why are you using state standards?`,
    text: `The state standards are used by higher education and college assessments to measure student mastery. By using the state standards, but freely being able to move up and down as needed per individual student, students will have measurable data other institutions could use to accept enrollment applications.
`,
  },
  {
    title: `What is 'Common Core'?`,
    text: `Common Core was a new set of standards set forth from the government to delegate federalized standards for every state. However, from “No Child Left Behind” to “Common Core”, what may have been a good idea, or at least was advertised as one, turned out to be very different. As legislations go, both sides of the aisle include many, less discussed, agendas. Kansas chose not to adopt the Common Core standards and is free of the annexed agendas, Common Core standards and curriculums.<br />Being a Non-Accredited Private School also gives us the added freedom to choose our own resources and curriculums. We use a variety of sources that are effective, affordable, and vetted for any inappropriate content. The resources investigated during each phase of our school's standard implementation were each assessed by only two criteria - credibility and academic focus. Our students will learn how to use an arsenal of tools to meet their many challenges. An array of tools will be demonstrated and each student will choose the tool that works best for him/her.
`,
  },
  {
    title: `What are your health and safety expectations?`,
    text: `We expect the same common sense health and safety expectations we've had for generations. If you're sick, stay home. If you're not, let's have some fun learning at school.<br />We'll teach and practice basic hygiene and expect parents to take into consideration the other students and teachers when deciding if their own child is healthy enough to come to school.
`,
  },
  {
    title: `Do you teach Social Emotional Learning?`,
    text: `Growing up looks different for everyone, but we all deal with the same emotions. It's an important job for educators to help students reason with their emotions and choose healthy behaviors as a result. We discuss character traits and treating others the way we want to be treated. We also encourage conflict-resolution and learning through experience, so we will help guide children to solve their own problems in healthy ways using Love and Logic techniques.
`,
  },
  {
    title: `Do you teach any CRT or Gender theory?`,
    text: `NO! That is up to the parent. If a student asks a teacher about any of this, we will simply say, "You will need to talk to your parents about that." Even the teacher's opinion is influential for children, and they should not share it on these subjects.
`,
  },
  {
    title: `Are there any other school locations?`,
    text: `Hopefully there will be locations all over soon. For now, this is it. However, if you email flmacademy@protonmail.com, you will get an update on any new locations in progress.
`,
  },
  {
    title: `What is your holiday schedule?`,
    text: `We will follow a similar schedule as the SMSD yearly calendar for major holidays, but each school will make sure to send a yearly calendar at least a month before the next school year that may vary slightly.
`,
  },
  {
    title: `Does the school provide food?`,
    text: `In short, no. We believe in proper nutrition and maintaining an active lifestyle. It is the parent's responsibility to provide breakfast before school. Students should bring lunch and a healthy snack as well. Students will be able to warm up and even cook their lunch as long as they have the ingredients, because we will have a fully functioning kitchen available.<br />Eventually, we hope to have our own garden where students can grow, harvest, and cook their own food. We also have the goal of having a chef that will cook healthy meals, but it will be at a higher cost than the base tuition. This would also provide an opportunity for culinary lessons.
`,
  },
  {
    title: `How are grades and grade cards done?`,
    text: `Grades will be kept in each academic area. Grade cards will be handed out quarterly. These records will remain private and only for the parents of the student. If at any time a parent wants to enroll their student in a different institution, this paperwork will be required to prove grade level mastery.
`,
  },
];

const Faq = () => (
  <div className="page-content">
    <div className="content-block">
      <div
        className="section-full bg-white content-inner-1 about-area"
        style={{
          backgroundImage: "url(" + bnr1 + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="section-head text-center">
            <h2 className="head-title text-secondary">General Questions</h2>
            <p>You got questions, we got answers.</p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="m-b10">
                <AccordionBlog data={data} start={0} count={6} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 teacher-content">
              <div className="m-b10">
                <AccordionBlog data={data} start={6} count={6} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section-full bg-white content-inner-2 about-area"
        style={{
          backgroundImage: "url(" + bnr1 + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="contact-box">
            <div className="section-head text-center col-md-12">
              <h2 className="text-secondary">Additional Questions?</h2>
              <p className="m-b0">
                Fill out the information below, and we will get back to you as
                quickly as possible.
              </p>
            </div>
            <div className="dzFormMsg"></div>
            <form
              method="post"
              className="dzForm col-md-12"
              action="script/contact.php"
            >
              <input type="hidden" value="Contact" name="dzToDo" />
              <div className="row">
                <InputBlog />
                <div className="col-md-12 col-sm-12">
                  <div className="form-group">
                    <div
                      className="g-recaptcha"
                      data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                      data-callback="verifyRecaptchaCallback"
                      data-expired-callback="expiredRecaptchaCallback"
                    ></div>
                    <input
                      className="form-control d-none"
                      style={{ display: "none" }}
                      data-recaptcha="true"
                      required
                      data-error="Please complete the Captcha"
                    />
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 text-center">
                  <button
                    name="submit"
                    type="submit"
                    value="Submit"
                    className="btn radius-xl btn-lg"
                  >
                    Send Message<span></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Faq;
