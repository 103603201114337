import React from "react";
import classnames from "classnames";

const colors = ["bg-blue", "bg-green", "bg-orange"];

const Skills = ({ passions, skills }) => (
  <div className="container">
    <div className="row">
      <div className="col-lg-6 section-head">
        <h2 className="text-secondary">Passions</h2>
        <p className="m-b0">{passions ?? "No passions yet"}</p>
      </div>
      {skills && (
        <div className="col-lg-6">
          <div className="progress-section">
            <h4>Proficiency</h4>
            {skills.map(({ name, percent }, i) => (
              <div key={i} className="progress-bx">
                <h6 className="title">{name}</h6>
                <div className="count-box">{percent}%</div>
                <div className="progress">
                  <div
                    className={classnames(
                      "progress-bar",
                      colors[i] ?? "bg-blue"
                    )}
                    style={{ width: `${percent}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default Skills;
