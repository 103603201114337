import React, { useState, useCallback, useMemo } from "react";

import bnr1 from "images/line2.png";
import TeacherSlider from "markup/Element/TeacherSlider";
import AddButton from "markup/Element/AddButton";

import { useGlobal } from "store/global";

const Teachers = () => {
  const [addTeacher, setAddTeacher] = useState(false);
  const {
    state: { loading, isAdmin },
  } = useGlobal();

  const buttonText = loading.status ? "Loading Teachers . . ." : "Add Teacher";

  const handleAdd = useCallback(() => setAddTeacher(true), []);

  const style = useMemo(() => ({
    backgroundImage: "url(" + bnr1 + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  }));

  return (
    <div className="page-content">
      <div className="content-block">
        <div className="section-full bg-white content-inner" style={style}>
          <div className="container">
            <div className="section-head text-center">
              <h2 className="head-title text-secondary">About the Teachers</h2>
              <p>
                We have an excellent teacher to child ratio at our school. This
                ensures that each child receives the attention required.
              </p>
            </div>
            {isAdmin && <AddButton text={buttonText} onClick={handleAdd} />}

            <TeacherSlider add={addTeacher} setAdd={setAddTeacher} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teachers;
