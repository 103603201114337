import React from "react";
import { Link } from "react-router-dom";

import { schoolPhone } from "Constants";
import { EducationBanner } from "./Index1";
import TeacherSlider from "../Element/TeacherSlider";
import BlogSlider from "../Element/BlogSlider";
//Images
import bnr1 from "images/line2.png";
import bnr2 from "images/line2.png";

const bnr3 =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fbackground%2Fbg6.jpg?alt=media&token=c21b29b9-fa32-4d19-b641-2f4fefc9f326";
const Children1 =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fbackground%2Fchildren1.png?alt=media&token=0f2c5dc1-9efc-4b0d-b491-339ee7d1173c";
const Children2 =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fbackground%2Fchildren2.png?alt=media&token=7ea6639d-dcd1-451b-b29d-1942e3187a2f";
const CenterImg =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fabout%2Fabout-2.jpg?alt=media&token=083244ac-8a5e-4ec4-95cb-2e6e0f764db6";

const TextBlog = ({ title }) => (
  <div className="icon-content">
    <h5 className="dlab-tilte">{title}</h5>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod..
    </p>
  </div>
);

const AcademyBlog = () => (
  <>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="section-head text-center">
            <h2 className="head-title text-secondary">
              Welcome To Freedom Learning Academy
            </h2>
            <p>
              The concept of school and pre-school education consists of 3
              programs of development and training in our academy.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="icon-bx-wraper right m-b30 about-iconbx">
            <div className="icon-bx-sm radius bg-pink">
              {" "}
              <Link to={"#"} className="icon-cell">
                <i className="flaticon-bricks"></i>
              </Link>{" "}
            </div>
            <TextBlog title="Active Learning" />
          </div>
          <div className="icon-bx-wraper right m-b30 about-iconbx">
            <div className="icon-bx-sm radius bg-blue">
              {" "}
              <Link to={"#"} className="icon-cell">
                <i className="flaticon-boy"></i>
              </Link>{" "}
            </div>
            <TextBlog title="Expert Teachers" />
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 m-b30 d-md-none d-lg-block d-sm-none">
          <img src={CenterImg} alt="" />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="icon-bx-wraper left m-b30 about-iconbx">
            <div className="icon-bx-sm bg-green radius">
              {" "}
              <Link to={"#"} className="icon-cell">
                <i className="flaticon-bus"></i>
              </Link>{" "}
            </div>
            <TextBlog title="Strategi Location" />
          </div>
          <div className="icon-bx-wraper left m-b30 about-iconbx">
            <div className="icon-bx-sm bg-orange radius">
              <Link to={"#"} className="icon-cell">
                <i className="flaticon-ice-cream"></i>
              </Link>{" "}
            </div>
            <TextBlog title="Full Day Programs" />
          </div>
        </div>
      </div>
    </div>
  </>
);

const AboutUs2 = () => (
  <div className="page-content">
    {/* inner page banner */}

    {/* inner page banner End*/}
    <div className="content-block">
      {/* Section-1 */}
      <div
        className="section-full bg-white content-inner-1 about-kids"
        style={{
          backgroundImage: "url(" + bnr1 + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <AcademyBlog />
        <div className="childern-box">
          <div className="childern-1">
            <img src={Children1} alt="" />
          </div>
          <div className="childern-2">
            <img src={Children2} alt="" />
          </div>
        </div>
      </div>
      {/* Section-1 End*/}
      {/* Section-2 Start*/}
      <EducationBanner />
      {/* Section-2 End*/}
      {/* Section-3 Start*/}
      <div
        className="section-full bg-white content-inner-1"
        style={{
          backgroundImage: "url(" + bnr2 + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="section-head text-center">
            <h2 className="head-title text-secondary">About the Teachers</h2>
            <p>
              We have an excellent teacher to child ratio at our school in order
              to ensure that each child receives the attention he or she needs
            </p>
          </div>
          <TeacherSlider />
        </div>
      </div>
      {/* Section-3 End*/}
      {/* Section-4 Start*/}
      <div
        className="section-full bg-white content-inner-2 about-content bg-img-fix"
        style={{ backgroundImage: "url(" + bnr3 + ")" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-12 wow fadeIn about-text"
              data-wow-delay="0.2s"
              data-wow-duration="2s"
              style={{
                visibility: "visible",
                animationDuration: "2s",
                animationDelay: "0.2s",
                animationName: "fadeIn",
              }}
            >
              <div className="section-head text-center">
                <h4 className="text-white">Join Our New Session</h4>
                <h2>
                  Call To Enroll Your Child <br />
                  <span className="text-yellow">{schoolPhone}</span>
                </h2>
                <Link to={"./about"} className="btn btn-md radius-xl">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section-4 End*/}
      {/* Section-5 Start*/}
      <div className="section-full bg-white content-inner">
        <div className="container">
          <div className="section-head text-center">
            <h2 className="head-title text-secondary">From the Blog</h2>
            <p>
              We have an excellent teacher to child ratio at our school in order
              to ensure that each child receives the attention he or she needs
            </p>
          </div>
          <BlogSlider />
        </div>
      </div>
      {/* Section-5 End*/}
    </div>
  </div>
);
export { AcademyBlog };
export default AboutUs2;
