import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { useTestimonials } from "store/testimonials";
import { useGlobal } from "store/global";
import { handleScrollAnimations } from "utils";
import { useScroll } from "hooks";

import BannerSlider from "markup/Element/BannerSlider";
import TestimonialSlider from "markup/Element/TestimonialSlider";
import TeacherSlider from "markup/Element/TeacherSlider";
import GallerySlider from "markup/Element/GallerySlider";
import { AddIcon } from "markup/Element/Icons/Icons";

import mathIcon from "images/icons/mathIcon.png";
import icon1 from "images/icons/creative.png";
import icon2 from "images/icons/respect.png";
import icon3 from "images/icons/active.png";
import icon4 from "images/icons/knowledge.png";
import bgimg1 from "images/line.png";
import bgimg3 from "images/line2.png";

import { preloadImage } from "utils/images";
import { classInfoDetail } from "Constants";

import styles from "./styles.module.scss";

const enroll = true;

const preloadSrcList = [
  mathIcon,
  icon1,
  icon2,
  icon3,
  icon4,
  bgimg1,
  bgimg3,
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fcustom-background%2Fbg2.jpg?alt=media&token=e20b3fc3-e765-488b-8cfb-257573983fdb",
];

const Index1 = () => {
  const mountedRef = useRef(false);
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  const [addTeacher, setAddTeacher] = useState(false);
  const onScrollAnimRef = useRef(null);
  const { scrollY } = useScroll();

  const {
    actions: { create: createTestimonial },
  } = useTestimonials();

  const {
    state: { authenticated, authUser, isAdmin },
    actions: { setLoading, setShowNotification, setNotificationExpired },
  } = useGlobal();

  useEffect(() => setNotificationExpired, []);

  useEffect(() => {
    setLoading(!assetsLoaded);
    setShowNotification(assetsLoaded);
  }, [assetsLoaded]);

  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  useEffect(() => {
    if (mountedRef.current) {
      const effect = async () => {
        const imagesPromiseList = preloadSrcList.map((v) => preloadImage(v));
        await Promise.all(imagesPromiseList);
        if (!mountedRef.current) return;
        setAssetsLoaded(true);
      };
      effect();
    }
  }, []);

  useEffect(() => {
    onScrollAnimRef.current = handleScrollAnimations();
    return () => (onScrollAnimRef.current = null);
  }, [assetsLoaded]);

  useEffect(() => {
    onScrollAnimRef.current?.();
  }, [scrollY]);

  const iconBlog = useMemo(
    () => [
      { image: icon1, title1: "To Think Creatively", title2: "and Create" },
      {
        image: icon2,
        title1: "To build self-respect and respect of others",
      },
      { image: icon3, title1: "To be Independent", title2: "and Active" },
      { image: icon4, title1: "To Apply", title2: "Knowledge in Life" },
    ],
    [assetsLoaded]
  );

  const iconBlog2 = useMemo(
    () => [
      {
        icon: <i className="flaticon-bricks text-green" />,
        title: "Primary K-2",
        desc: "Building on the foundation of phonics and phonemic awareness, numeration and basic operations, and moving through state standards at the student's pace.",
      },
      {
        icon: <img src={mathIcon} alt="math" />,
        title: "Secondary 3-5",
        desc: "Building on the foundation of grammatical writing and comprehension, algebraic thinking and geometry, and moving through the state standards at the student's pace.",
      },
      {
        icon: <i className="flaticon-puzzle text-orange" />,
        title: "Middle 6-8",
        desc: "Building on the foundation of fiction and nonfiction reading and writing, understanding equations and proofs, and moving through the state standards at the student's pace.",
      },
    ],
    [assetsLoaded]
  );

  const handleAddTestimonial = () => createTestimonial(authUser);

  const handleAddTeacher = () => setAddTeacher(true);

  const style1 = useMemo(
    () => ({
      backgroundImage: "url(" + bgimg1 + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }),
    [assetsLoaded]
  );

  const style3 = useMemo(
    () => ({
      backgroundImage: "url(" + bgimg3 + ")",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }),
    [assetsLoaded]
  );

  return (
    <div className={classnames("page-content bg-white", styles.root)}>
      <BannerSlider />
      {enroll && (
        <Link to="/contact-us">
          <img src="/enroll.png" className={styles.enrollBtn} />
        </Link>
      )}
      <div
        className={classnames(
          "content-block",
          assetsLoaded ? styles.fadeIn : styles.hide
        )}
      >
        {/*  About Us */}
        <div className="section-full bg-white content-inner-1 text-center">
          <div className="container">
            <div className="section-head">
              <h2 className="head-title text-secondary">
                Welcome to Freedom Learning Academy
              </h2>
            </div>
            <div className="row js-scroll slideUp">
              {iconBlog.map((data, index) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                  <div className="icon-bx-wraper sr-iconbox m-b20">
                    <div className={styles.icon}>
                      <Link to={"#"} className="icon-cell">
                        <img src={data.image} alt="" />
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h6 className="dlab-tilte">
                        {data.title1}
                        <br />
                        {data.title2}
                      </h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/*  About Us End*/}

        <div
          className="section-full bg-white content-inner-2 about-box"
          style={style1}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 col-12 js-scroll slideRight">
                <div className="section-head">
                  <h2 className="head-title text-secondary">
                    Building a solid
                    <br />
                    foundation of knowledge
                    <br />
                    and independence
                  </h2>
                  <p>
                    From kindergarten to highschool, students need skills in
                    academics, work ethic and social expectations that build
                    upon a strong foundation. At Freedom Learning Academy we
                    believe this begins in the home, and it is our
                    responsibility to maximize your visions for your children.
                  </p>
                  <Link to={"./faq"} className="btn btn-md kids-btn radius-xl">
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 col-12 js-scroll slideLeft">
                {iconBlog2.map((item, index) => (
                  <div className="icon-bx-wraper left" key={index}>
                    <div className="icon-lg m-b20">
                      {" "}
                      <span className="icon-cell">{item.icon}</span>{" "}
                    </div>
                    <div className="icon-content">
                      <h2 className="dlab-tilte">{item.title}</h2>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <EducationBanner />
        <div className="section-full bg-white content-inner-1">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="head-title text-secondary">
                Gallery of our classes
              </h2>
              <p>{classInfoDetail}</p>
            </div>
            <div className="js-scroll slideRight">
              <GallerySlider />
            </div>
          </div>
        </div>
        <div className="section-full bg-white content-inner-1" style={style3}>
          <div className="container">
            <div className="section-head text-center">
              <h2 className="head-title text-secondary">
                About the Teachers
                {isAdmin && (
                  <AddIcon className={styles.plus} onClick={handleAddTeacher} />
                )}
              </h2>
              <p>
                Every teacher at Freedom Learning Academy comes with experience
                and a passion for educating the next generation of world
                changers. Click the teachers&apos; names for more information.
              </p>
            </div>
            <div className="js-scroll slideLeft">
              <TeacherSlider add={addTeacher} setAdd={setAddTeacher} />
            </div>
          </div>
        </div>
        <div className="section-full bg-white content-inner-1">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="head-title text-secondary">
                Testimonials about FLA
                {authenticated && (
                  <AddIcon
                    className={styles.plus}
                    onClick={handleAddTestimonial}
                  />
                )}
              </h2>

              <p>
                Our Montessori based, homeschool philosophy provides optimal
                learning experiences for each child. Parents, students, and
                teachers are all part of the learning community.
              </p>
            </div>
            <div className="js-scroll scaleUp">
              <TestimonialSlider />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EducationBanner = () => (
  <div
    className={classnames(
      "section-full bg-white content-inner-2 about-content bg-img-fix",
      styles.edBanner
    )}
  >
    <div className="about-overlay-box"></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="section-head">
            <h2 className="head-title text-yellow">
              Purpose. Hope. Community.
            </h2>
            <p className="text-white">
              The purpose of education is to provide meaningful and memorable
              learning opportunities that allow natural curiosities in academia
              to be explored and understood. The hope for the future is to
              continually create strong, independent, resourceful, and
              reasonable contributors to society. The community around the
              student learning experience starts at home, but includes the
              teachers and peers involved with the student, all with the goal of
              supporting and nourishing the academic and emotional needs of the
              child.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
      </div>
    </div>
  </div>
);

export { EducationBanner };
export default Index1;
