import React from "react";
import { Link } from "react-router-dom";

import CloseButton from "markup/Element/CloseButton";

const CommentForm = ({
  onSubmit,
  fields,
  onChange,
  onCancel = () => {},
  showTitle = true,
  showCancel = false,
}) => (
  <div className="comment-respond" id="respond">
    {showTitle && (
      <h4 className="comment-reply-title" id="reply-title">
        Leave a Reply
        <small>
          <Link
            style={{ display: "none" }}
            to={"#"}
            id="cancel-comment-reply-link"
            rel="nofollow"
          >
            Cancel reply
          </Link>
        </small>
      </h4>
    )}
    <form className="comment-form" id="commentform" onSubmit={onSubmit}>
      {showCancel && (
        <CloseButton windowType="MODAL" size="SMALL" onClick={onCancel} />
      )}{" "}
      {fields.authUser?.displayName && (
        <p className="comment-form-author">
          <label htmlFor="author">Name</label>
          <input
            type="text"
            value={fields.authUser?.displayName ?? ""}
            onChange={onChange("author")}
            name="author"
            placeholder="Author"
            disabled
            id="author"
          />
        </p>
      )}
      {fields.authUser?.email && (
        <p className="comment-form-email">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            value={fields.authUser?.email ?? ""}
            onChange={onChange("email")}
            placeholder="Email"
            name="email"
            disabled
            id="email"
          />
        </p>
      )}
      <p className="comment-form-comment">
        <label htmlFor="comment">
          Comment <span className="required">*</span>
        </label>
        <textarea
          onChange={onChange("comment")}
          rows="8"
          name="comment"
          placeholder="Comment"
          id="comment"
          value={fields.comment ?? ""}
        />
      </p>
      <p className="form-submit">
        <input
          type="submit"
          value="Post Comment"
          className="btn btn-lg radius-xl"
          id="submit"
          name="submit"
        />
      </p>
    </form>
  </div>
);

export default CommentForm;
