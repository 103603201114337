import { useState, useEffect, useRef } from "react";

export const useWindowDimensions = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const timerRef = useRef(null);

  useEffect(
    () =>
      window.addEventListener("resize", () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          setWidth(window.innerWidth);
          setHeight(window.innerHeight);
        }, 250);
      }),
    []
  );

  return {
    width,
    height,
  };
};
