import React, { useState } from "react";
import classnames from "classnames";

import FileUpload from "../../Element/FileUpload";
import styles from "./styles.module.scss";

const initFields = {
  userId: "",
  author: "",
  title: "",
  text: "",
  imageUrl: "",
};

const AddBlogForm = ({ submit, imageOnly = false, authUser }) => {
  const authInit = { userId: authUser?.uid, author: authUser?.displayName };
  const [fields, setFields] = useState(
    imageOnly ? { image: null } : { ...initFields, ...authInit }
  );

  const onChange = (evt) => {
    setFields({ ...fields, [evt.target.name]: evt.target.value });
  };

  const updateUploadedFiles = (files) =>
    setFields({
      ...fields,
      image: Array.isArray(files) ? files[0] : files,
    });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submit(fields);
  };

  const submitButtonText = imageOnly ? "Update" : "Create";

  const title = imageOnly ? "Update Blog Image" : "Add Blog Form"

  return authUser?.uid ? (
    <div className="contact-box">
      <div className={styles.formContainer}>
        <div className={styles.fixedHeader}>
          <h2 className="text-secondary">{title}</h2>
        </div>
        <form className={classnames(styles.form, "dzForm container")}>
          <div className="row">
            {!imageOnly && (
              <>
                <div className="col-sm-12">
                  <div className="form-group">
                    <input
                      onChange={onChange}
                      type="text"
                      name="author"
                      disabled
                      className="form-control input"
                      placeholder="Author"
                      value={fields.author}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <input
                      onChange={onChange}
                      type="text"
                      name="title"
                      className="form-control input"
                      placeholder="Title"
                      value={fields.title}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <textarea
                      onChange={onChange}
                      rows={4}
                      name="text"
                      required
                      className="form-control text"
                      placeholder="Text"
                      value={fields.text}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="col-sm-12">
              <div className="form-group">
                <FileUpload
                  accept=".jpg,.png,.jpeg"
                  label="Blog's Image"
                  updateFilesCb={updateUploadedFiles}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 text-center">
              <button
                type="submit"
                value="Submit"
                className="btn radius-xl btn-lg"
                onClick={handleSubmit}
              >
                {submitButtonText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};
export default AddBlogForm;
