import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
// import { useAsyncReducer } from "services/api/Common";
// import galleryState from "./mockData";
import { useGlobal } from "store/global";
import {
  setLocalStorage,
  getLocalStorage,
  mergeToLocalStorageArray,
} from "utils/storage";
import { createFormData } from "utils";

import { GalleryConstants } from "./galleryConstants";
const { PAGE_SIZE, BATCH_SIZE } = GalleryConstants;

const initState = { gallery: [], page: 1 };

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "GET": {
      return { ...state, gallery: payload.data };
    }
    case "ADD_GET": {
      return { ...state, gallery: [...state.gallery, ...payload.data] };
    }
    case "CREATE": {
      return { ...state, gallery: [...state.gallery, ...payload.data] };
    }
    case "DELETE": {
      return {
        ...state,
        gallery: state.gallery.filter((item) => item.id !== payload.data.id),
      };
    }
    case "UPDATE": {
      return {
        ...state,
        gallery: state.gallery.map((v) => {
          if (v.id === payload.data.id) return payload.data;
          return v;
        }),
      };
    }
    case "PAGE": {
      return { ...state, page: Math.max(payload.data, 1) };
    }
    default:
      throw new Error(`Unhandled gallery action type: ${type}`);
  }
};

const createActions = (state, dispatch, globalActions) => ({
  get: async (params) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const query = JSON.stringify(params);
    const localData = getLocalStorage("gallery");

    if (localData && !params?.next) {
      dispatch({ type: "GET", payload: { data: localData } });
      globalActions.onFetchSuccess("Successfully loaded Gallery Images", false);
    }

    try {
      const res = await axios.get("/api/gallery", { params: { query } });
      const { gallery } = res.data ?? {};
      if (!gallery?.length) {
        globalActions.onFetchSuccess();
        globalActions.addMessage("Sorry, no more pics.");
        return;
      }

      if (params?.next) mergeToLocalStorageArray("gallery", gallery);
      else setLocalStorage("gallery", gallery);

      globalActions.onFetchSuccess(
        "Successfully fetched Gallery Images",
        false
      );
      if (params?.next) {
        dispatch({ type: "ADD_GET", payload: { data: gallery } });
        if (gallery.length)
          dispatch({ type: "PAGE", payload: { data: state.page + 1 } });
      } else dispatch({ type: "GET", payload: { data: gallery } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("get GalleryImages Error", serverError);
    }
  },
  create: async (data) => {
    globalActions.onFetchStart();
    const formData = createFormData(data);

    try {
      const res = await axios.post("/api/gallery", formData);
      const { gallery } = res.data ?? {};
      if (!gallery) return;
      globalActions.onFetchSuccess("Successfully created Gallery Image(s)");
      globalActions.setModalContent(null);
      dispatch({ type: "CREATE", payload: { data: gallery } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onAuthFail(serverError);
      globalActions.setModalContent(null);
      console.log("Create GalleryImage Error", error?.response?.statusText);
    }
  },
  update: async (payload) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    try {
      const res = await axios.put("/api/gallery", payload);
      const data = res.data;
      globalActions.onFetchSuccess("Successfully updated Gallery Image");
      dispatch({ type: "UPDATE", payload: { data } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("update GalleryImage Error", serverError);
    }
  },
  delete: async ({ id, path }) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    try {
      await axios.delete("/api/gallery", { data: { id, path } });
      globalActions.onFetchSuccess("Successfully deleted Gallery Image");
      dispatch({ type: "DELETE", payload: { data: { id } } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("delete GalleryImage Error", serverError);
    }
  },
});

export const createPageActions = (state, dispatch, actions) => {
  return {
    gotoPage: (page) => {
      dispatch({ type: "PAGE", payload: { data: page } });
    },
    nextPage: () => {
      const lastVisibleIndex = state.page * PAGE_SIZE - 1;
      const lastIndex = state.gallery.length - 1;
      const nextPage = state.page + 1;
      if (lastIndex > lastVisibleIndex) {
        dispatch({ type: "PAGE", payload: { data: nextPage } });
      } else {
        actions.get({ next: true, limit: PAGE_SIZE * BATCH_SIZE });
      }
    },
    prevPage: () => {
      dispatch({
        type: "PAGE",
        payload: { data: Math.max(1, state.page - 1) },
      });
    },
  };
};

const GalleryContext = createContext();
export const useGallery = () => {
  const data = useContext(GalleryContext);
  if (data === undefined) {
    throw new Error("useGallery must be used within a GalleryProvider");
  }
  return data;
};
export const GalleryProvider = ({ children }) => {
  const { actions: globalActions } = useGlobal();
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = useCallback(createActions(state, dispatch, globalActions), [
    state,
    dispatch,
    globalActions,
  ]);
  const pageActions = useCallback(createPageActions(state, dispatch, actions), [
    state,
    dispatch,
    actions,
  ]);

  return useMemo(
    () => (
      <GalleryContext.Provider value={{ state, actions, pageActions }}>
        {children}
      </GalleryContext.Provider>
    ),
    [state, actions]
  );
};
