import React from "react";

import { useGlobal } from "store/global";
import styles from "./styles.module.scss";

const Loader = ({ scrollable, force }) => {
  const {
    state: {
      loading: { status },
    },
  } = useGlobal();

  return force || status ? (
    <div className={`${styles.screen} ${scrollable && styles.fixed}`}>
      <ul className={styles.spinner2}>
        {[...Array(5)].map((_, i) => (
          <li key={i} />
        ))}
      </ul>
    </div>
  ) : null;
};

export default Loader;
