import React, { useState } from "react";
import classnames from "classnames";

import AddUrl from "markup/Element/AddUrl";

import { useUsers } from "store/users";
import { useGlobal } from "store/global";

import styles from "./styles.module.scss";

const initFields = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  password: "",
  profileImage: null,
  accessLevel: 2,
};

const options = [
  { value: 2, label: "ADMIN" },
  { value: 3, label: "GENERAL" },
];

const Admin = () => {
  const { actions: usersActions } = useUsers();
  const {
    state: {
      authenticated,
      authUser,
      // authUser: { accessLevel },
    },
  } = useGlobal();
  const accessLevel = authUser?.accessLevel ?? 0;
  const [fields, setFields] = useState(initFields);

  const onChange = (evt) => {
    const val =
      evt.target.name === "accessLevel"
        ? parseInt(evt.target.value)
        : evt.target.value;
    setFields({ ...fields, [evt.target.name]: val });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    usersActions.create(fields);
  };

  const getOptions = () =>
    accessLevel < 2 ? [...options, { value: 1, label: "GOD" }] : options;

  return (
    <>
      <section>
        <div className="page-content">
          <div className="content-block">
            <div className="section-full bg-white">
              <div className="container">
                <div className="row contact-box content-inner-5">
                  <div className="section-head text-center col-md-12">
                    <h2 className="text-secondary">Admin</h2>
                    <p className="m-b0">Create User</p>
                  </div>
                  <div className="dzFormMsg"></div>
                  <form className="dzForm col-md-12">
                    <div className="row">
                      <div className="col-sm-6 mx-auto">
                        <div className="form-group">
                          <select
                            onChange={onChange}
                            name="accessLevel"
                            required
                            className={classnames(
                              "form-control",
                              styles.select
                            )}
                            value={parseInt(fields.accessLevel, 10)}
                          >
                            {getOptions().map((v, i) => (
                              <option key={i} value={v.value}>
                                {v.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <input
                            onChange={onChange}
                            type="text"
                            name="fname"
                            required
                            className="form-control"
                            placeholder="First Name"
                            value={fields.fname}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <input
                            onChange={onChange}
                            type="text"
                            name="lname"
                            required
                            className="form-control"
                            placeholder="Last Name"
                            value={fields.lname}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <input
                            onChange={onChange}
                            type="email"
                            name="email"
                            className="form-control"
                            required
                            placeholder="Email"
                            value={fields.email}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            onChange={onChange}
                            type="mobile"
                            name="phone"
                            required
                            className="form-control"
                            placeholder="Phone"
                            value={fields.phone}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            onChange={onChange}
                            type="password"
                            name="password"
                            className="form-control"
                            required
                            placeholder="Password"
                            value={fields.password}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 text-center">
                        <button
                          type="submit"
                          value="Submit"
                          className="btn radius-xl btn-lg"
                          onClick={handleSubmit}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles.message} ${
            authenticated ? styles.success : styles.error
          }`}
        />
      </section>
      <section>
        <AddUrl />
      </section>
    </>
  );
};
export default Admin;
