import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { useGlobal } from "store/global";
import { createActions } from "./urlActions";

const initState = { allData: [], currData: {}, currId: null, page: 1 };

const UrlContext = createContext();
const reducer = (state, { type, payload }) => {
  switch (type) {
    case "GET": {
      return { ...state, allData: payload.data };
    }
    case "ADD": {
      const nState = { ...state, allData: [...state.allData, payload.data] };
      return nState;
    }
    case "CREATE": {
      return { ...state, currData: payload.data };
    }
    case "DELETE": {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload.data.id),
      };
    }
    case "UPDATE": {
      return {
        ...state,
        data: state.data.map((v) => {
          if (v.id === payload.data.id) return payload.data;
          return v;
        }),
      };
    }
    default:
      throw new Error(`Unhandled url action type: ${type}`);
  }
};

export const useUrls = () => {
  const data = useContext(UrlContext);
  if (data === undefined) {
    throw new Error("useUrls must be used within a UrlProvider");
  }
  return data;
};

export const UrlProvider = ({ children }) => {
  const { actions: globalActions } = useGlobal();
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = useCallback(createActions(dispatch, globalActions), [
    state,
    dispatch,
    globalActions,
  ]);

  return (
    <UrlContext.Provider value={{ state, actions }}>
      {children}
    </UrlContext.Provider>
  );
};
