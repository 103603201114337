import React from "react";

export default [
    {
        title: "Students",
        text: (
            <ul>
                <li>
                    learning fundamental and advanced academics in math, ELA, Science, and
                    Social Studies/History
                </li>
                <li>
                    high expectations and accountability for work ethic, personal
                    responsibility
                </li>
                <li>
                    using natural curiosity to enhance choice in learning path and pace
                </li>
                <li>life experiences and life skills</li>
                <li>social respect and citizenship</li>
            </ul>
        ),
    },
    {
        title: "Parents",
        text: (
            <ul>
                <li>first, best, and most influential teachers for kids</li>
                <li>knowledge and voice in academic enrichment for students</li>
                <li>communication with school and teachers</li>
                <li>involvement in curricular and extra curricular activities</li>
                <li>connecting with one another</li>
                <li>
                    maintaining parental rights to religious, political, and personal
                    traditions and beliefs
                </li>
            </ul>
        ),
    },
    {
        title: "Teachers",
        text: (
            <ul>
                <li>empowered and trusted as professionals</li>
                <li>facilitators of learning and schedule maintenance</li>
                <li>entrusted with guiding school decision making</li>
                <li>expectations based on relationships and effectiveness</li>
                <li>
                    leaving parental rights to the parents when it comes to religious or
                    political beliefs.
                </li>
            </ul>
        ),
    },
    {
        title: "Roots Of Education",
        text: `Education creates a firm foundation for creating the path for successful contributors to society, building strong academic performers who can make informed decisions based on reason and thinking for themselves based on knowledge and personal beliefs, empowering communities to grow and flourish, strengthening relationships and work ethic, providing quality learning opportunities through real-life experiences.
  `,
    },
];