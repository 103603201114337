import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

import { useGallery } from "store/picGallery";

//Light Gallery on icon click
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={() => openLightbox(props.imageToOpen)}
      className="check-km"
    >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};

const settings = {
  arrows: false,
  slidesToShow: 3,
  infinite: true,
  autoPlay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const GallerySlider = () => {
  const sliderRef = useRef(null);
  const {
    actions,
    state: { gallery },
  } = useGallery();

  useEffect(() => {
    actions.get();
  }, []);

  const next = () => sliderRef.current.slickNext();
  const previous = () => sliderRef.current.slickPrev();

  return (
    <>
      <SimpleReactLightbox>
        <SRLWrapper>
          <div className="sprite-nav">
            <Slider
              className="gallery-carousel owl-carousel owl-theme dots-none "
              ref={(c) => (sliderRef.current = c)}
              {...settings}
            >
              {gallery?.map((item, index) => (
                <div className="item p-3" key={index}>
                  <div className="dlab-box frame-box">
                    <div className="dlab-thum dlab-img-overlay1">
                      <img src={item.imageUrl} alt="" />
                      <div className="overlay-bx">
                        <div className="overlay-icon">
                          <Iconimage />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="owl-nav" style={{ textAlign: "center" }}>
              <div className=" owl-prev " onClick={previous} />
              <div className="owl-next " onClick={next} />
            </div>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </>
  );
};
export default GallerySlider;
