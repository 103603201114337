export const preloadImage = src => (
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve(img);
        }
        img.onerror = img.onabort = () => {
            reject(src);
        }
        img.src = src;
    })
);