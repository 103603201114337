import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
//Store
import { useTestimonials } from "store/testimonials";
import { useBlogs } from "store/blogs";
import { useGlobal } from "store/global";

import Section from "./Section";
import AccordionBlog from "markup/Element/AccordionBlog/AccordionBlog";
import TestimonialSlider from "markup/Element/TestimonialSlider";
import BlogSlider from "markup/Element/BlogSlider";
import VideoPopup from "markup/Element/VideoPopup";
//Images
import bnr1 from "images/line2.png";
import bnr4 from "images/line.png";
import about from "images/icons/school-house.png";

import mission from "./missionData";

import styles from "./styles.module.scss";

const videoUrl = "https://www.youtube.com/watch?v=v_cmRajrXk0";
const bnr2 =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fcustom-background%2Fbg5.jpg?alt=media&token=fb075a65-95d1-4310-aa0e-822496853390";
const bnr3 =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fcustom-background%2Fbg6.jpg?alt=media&token=abf840df-c9cb-4340-9025-48f8a7bfe40e";

const AboutUs = () => {
  const [videoClasses, setVideoClasses] = useState([
    "video-play",
    styles.videoPlay,
  ]);

  const {
    actions: { create: createTestimonial },
  } = useTestimonials();

  const splitBoxRef = useRef(document.querySelectorAll(".split-box"));
  const fSB = [].slice.call(splitBoxRef.current);
  fSB.forEach((el) => el.classList.add("split-active"));

  const {
    actions: { get: getBlogs, setCurrId: setCurrBlogId },
    state: { blogs },
  } = useBlogs();

  const {
    state: { authenticated, authUser },
  } = useGlobal();

  useEffect(() => {
    getBlogs();
  }, []);

  const handleAddTestimonial = () => createTestimonial(authUser);

  const videoStart = () => {
    setVideoClasses(["video-play", styles.videoPlayActive]);
  };

  const videoStop = () => {
    setVideoClasses(["video-play", styles.videoPlay]);
  };

  return (
    <div className="page-content">
      <div className="content-block">
        <Section bgUrl={bnr1} bgStatic={false}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 m-b15">
              <div className="section-head">
                <h2 className="head-title text-secondary">
                  Who we are and what we believe
                </h2>
                <p>
                  Freedom Learning Academy began as a home school in response to
                  the public schools making decisions that did not have the
                  students&apos; and parents&apos; interests at heart. These
                  decisions include: teaching political or religious propaganda,
                  disrespecting parental rights, wasteful spending, and lowering
                  academic expectations.
                </p>
              </div>
              <AccordionBlog data={mission} />
              <div className="section-head">
                <p>
                  Freedom Learning Academy is a Non-Accredited Private School
                  with a Montessori based curriculum and pedagogy maintaining a
                  home-school model instructed by professional teachers to
                  ensure the maximum effectiveness for each individual student.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
              <div className="split-box">
                <div className="about-media">
                  <img src={about} alt="" />
                  <div className={classnames(...videoClasses)}>
                    <VideoPopup
                      url={videoUrl}
                      onOpen={videoStart}
                      onClose={videoStop}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section
          largeText={
            <>
              <div>Let Your Kids Have an Amazing</div>
              <div>Time at the park!</div>
            </>
          }
          bgUrl={bnr2}
        />
        {/*  Portfolio  */}
        <Section
          smallText={"Join Our New Session"}
          largeText={
            <>
              <span>Call To Enroll Your Child</span> <br />
              <span className="text-yellow">913 272 3719</span>
            </>
          }
          action={{ text: "Contact Us", link: "./contact-us" }}
          bgUrl={bnr3}
        />
        <Section bgUrl={bnr4} noBorder={true} bgStatic={false}>
          <div className="section-head text-center">
            <h2 className="head-title text-secondary">
              Testimonials about FLA
              {authenticated && (
                <i
                  onClick={handleAddTestimonial}
                  className={classnames(
                    styles.plus,
                    "fa fa-plus-circle icon-bx-xs"
                  )}
                ></i>
              )}
            </h2>
          </div>
          <TestimonialSlider />
        </Section>
        <Section noBorder={true}>
          <div className="section-head text-center">
            <h2 className="head-title text-secondary">From the Blog</h2>
            <p>Little insights to the world of young minds.</p>
          </div>
          <BlogSlider blogs={blogs} setCurrBlogId={setCurrBlogId} />
        </Section>
      </div>
    </div>
  );
};
export default AboutUs;
