import React, { useEffect, useRef } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// import ThemeButton from "./Element/ThemeButton/ThemeButton";
import ScrollToTop from "./Element/ScrollToTop";
import Loader from "markup/Element/Loader";
import Modal from "./Element/Modal";
import Message from "./Element/Message";
import Notification from "./Element/Notification";

import Index1 from "./Pages/Index1";
import AboutUs from "./Pages/AboutUs";
import AboutUs2 from "./Pages/AboutUs2";
import Classes from "./Pages/Classes/Classes";
import Teachers from "./Pages/Teachers";
import TeachersDetail from "./Pages/TeachersDetail";
import ComingSoon from "./Pages/ComingSoon";
import Faq from "./Pages/Faq";
import Event from "./Pages/Event";
import ErrorPage from "./Pages/ErrorPage";
import BlogClassicGrid from "./Pages/Blogs/BlogClassicGrid";
import BlogDetails from "./Pages/BlogDetails/BlogDetails";
import Gallery from "./Pages/Gallery";
import GalleryMasonry from "./Pages/GalleryMasonry";
import GalleryFilter from "./Pages/GalleryFilter";
import Contact from "./Pages/Contact";
import Admin from "./Pages/Admin";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";

// import { GalleryProvider } from "store/picGallery";
import { TeamProvider } from "store/team";
import { CommentProvider } from "store/comments";
import { TestimonialsProvider } from "store/testimonials";
import { AuthProvider } from "store/auth";
import { UsersProvider } from "store/users";
import { useGlobal } from "store/global";
import { useBlogs } from "store/blogs";
import { useSocialEvents } from "store/socialEvents";

const Markup = () => {
  const origPathRef = useRef();

  const { pathname } = useLocation();
  origPathRef.current = !origPathRef.current ? pathname : origPathRef.current;

  const {
    state: { authenticated, loading, notificationPosition },
    actions: { getAuth },
  } = useGlobal();

  const {
    actions: { get: getBlogs },
  } = useBlogs();

  const {
    actions: { get: getSocialEvents },
  } = useSocialEvents();

  useEffect(() => {
    getAuth();
  }, []);

  useEffect(() => {
    getBlogs();
  }, []);

  useEffect(() => {
    getSocialEvents();
  }, []);

  const adminRoutes = ["/admin"];
  const authRoutes = ["/login", "/forgot-password", "/sign-up"];
  const isAdminRoute =
    adminRoutes.indexOf(pathname) > -1 || origPathRef.current === "/admin";
  const isAuthRoute =
    authRoutes.indexOf(pathname) > -1 && origPathRef.current !== "/admin";

  if (!authenticated && isAdminRoute && authRoutes.indexOf(pathname) === -1) {
    return <Redirect to={"/login"} />;
  } else if (authenticated && isAuthRoute) {
    return <Redirect to={"/"} />;
  } else if (
    authenticated &&
    isAdminRoute &&
    authRoutes.indexOf(pathname) > -1
  ) {
    return <Redirect to={"/admin"} />;
  }

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <TeamProvider>
            <TestimonialsProvider>
              <Index1 />
            </TestimonialsProvider>
          </TeamProvider>
        </Route>
        <Route path="/about">
          <TestimonialsProvider>
            <AboutUs />
          </TestimonialsProvider>
        </Route>
        <Route path="/about-2">
          <TeamProvider>
            <AboutUs2 />
          </TeamProvider>
        </Route>
        <Route path="/classes" component={Classes} />
        <Route path="/admin">
          <AuthProvider>
            <UsersProvider>
              <Admin />
            </UsersProvider>
          </AuthProvider>
        </Route>
        <Route path="/login">
          <AuthProvider>
            <Login />
          </AuthProvider>
        </Route>
        <Route path="/sign-up">
          <AuthProvider>
            <SignUp />
          </AuthProvider>
        </Route>
        <Route path="/coming-soon" component={ComingSoon} />
        <Route path="/faq" component={Faq} />
        <Route path="/event" component={Event} />
        <Route path="/teachers">
          <TeamProvider>
            <Teachers />
          </TeamProvider>
        </Route>
        <Route path="/teachers-details">
          <TeamProvider>
            <TeachersDetail />
          </TeamProvider>
        </Route>
        <Route path="/blog-classic-grid">
          <CommentProvider>
            <BlogClassicGrid />
          </CommentProvider>
        </Route>
        <Route path="/blog-details">
          <CommentProvider>
            <BlogDetails />
          </CommentProvider>
        </Route>
        <Route path="/gallery">
          <Gallery />
        </Route>
        <Route path="/gallery-masonry">
          <GalleryMasonry />
        </Route>
        <Route path="/gallery-filter">
          <GalleryFilter />
        </Route>
        <Route path="/contact-us" component={Contact} />
        <Route path="/error-404" component={ErrorPage} />
      </Switch>
      <ScrollToTop />
      <Modal />
      <Message />
      {loading.global && <Loader scrollable />}
      {notificationPosition === "FIXED" && <Notification />}
    </>
  );
};

export default Markup;
