import React, { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import classnames from "classnames";

import CommentForm from "./CommentForm";

import { getFormattedDate } from "utils/dateUtils";
import { useComments } from "store/comments";
import { sortByDate } from "utils";
import { useGlobal } from "store/global";
import CloseButton from "markup/Element/CloseButton";

import styles from "./styles.module.scss";

const Comment = ({
  data,
  onSubmit,
  onDelete,
  onApprove,
  showForm,
  setShowForm,
  currParentId,
  setCurrParentId,
}) => {
  const isMounted = useRef(false);

  const {
    state: { authUser, isAdmin },
  } = useGlobal();

  const {
    actions: { get, update },
  } = useComments();

  const [showReplies, setShowReplies] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newComment, setNewComment] = useState(data.comment);
  const [fields, setFields] = useState({});

  const props = {
    onSubmit,
    showForm,
    setShowForm,
    // onDelete,
    onApprove,
    currParentId,
    setCurrParentId,
  };

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  });

  useEffect(() => {
    if (currParentId === data.id) setShowReplies(true);
    setCurrParentId?.(null);
  }, [setCurrParentId, currParentId, data.id, setShowReplies]);

  useEffect(() => {
    if (isMounted.current) {
      get({ parentDocPath: data.docPath });
    }
  }, []);

  const handleSubmit = (e) => {
    onSubmit(data.docPath)(e, fields, data.id);
  };

  const handleApprove = (e) => {
    if (data.approved) return;
    onApprove(data.docPath)(e, data.id);
  };

  const handleDelete = (e) => {
    onDelete(data.docPath)(e, data.id);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const onCancelEditing = () => {
    setEditing(false);
  };

  const onChange = (field) => (e) => {
    const val = e.currentTarget.value;
    setFields((state) => ({ ...state, [field]: val }));
  };

  const toggleForm = (e) => {
    e.preventDefault();
    setShowForm?.((state) => (state ? null : data.id));
  };

  const toggleReplies = () => {
    setShowReplies?.((state) => !state);
  };

  const visibleComments = sortByDate(
    data?.comments?.filter(
      (v) => !v.rejected && (v.approved || authUser?.accessLevel < 3)
    )
  );

  const commentCount = visibleComments?.length;

  const renderEditForm = () => (
    <div className={styles.textEditWrapper}>
      <div>
        <CloseButton
          windowType="MODAL"
          size="SMALL"
          onClick={onCancelEditing}
        />
      </div>
      <textarea
        onChange={editComment}
        rows={3}
        className={styles.textEdit}
        value={newComment}
      />
      <button
        onClick={submitEditedComment}
        className={classnames("btn btn-sm radius-md")}
      >
        Send
      </button>
    </div>
  );

  const editComment = (e) => {
    setNewComment(e.currentTarget.value);
  };

  const submitEditedComment = () => {
    update({
      id: data.id,
      docPath: data.docPath,
      approved: false,
      comment: newComment,
    });
    setEditing(false);
  };

  return (
    <div className="comment-body">
      <div className="comment-author vcard">
        {" "}
        <img className="avatar photo" src={data.imageUrl} alt="" />
        <cite className="fn">{data.author}</cite>{" "}
        <span className="says">says:</span>
      </div>
      <div className="comment-meta">
        {getFormattedDate(data?.updatedAt?.seconds * 1000)}
      </div>
      {!editing &&
        isAdmin &&
        showForm !== data.id &&
        (!visibleComments || visibleComments.length === 0) && (
          <div className={styles.overlay}>
            <div className={styles.vetting}>
              <button
                className={classnames("btn btn-sm radius-md", styles.info)}
                onClick={handleEdit}
              >
                Edit
              </button>
              <button
                className={classnames(
                  "btn btn-sm radius-md",
                  styles.safe,
                  `${data.approved ? styles.disabled : ""}`
                )}
                disabled={data.approved}
                onClick={handleApprove}
              >
                {data?.approved ? "Approved" : "Approve"}
              </button>
              <button
                className={classnames("btn btn-sm radius-md", styles.danger)}
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        )}
      {editing ? renderEditForm() : <p>{data.comment || ""}</p>}
      {!editing && (
        <div
          className={classnames(
            "reply",
            styles.reply,
            showForm !== data.id ? styles.noForm : ""
          )}
        >
          <div onClick={toggleForm} className="comment-reply-link">
            Reply
          </div>
          {authUser?.uid === data.userId && (
            <span onClick={handleEdit} className={styles.commentEdit}>
              edit
            </span>
          )}
          {commentCount > 0 && (
            <span className={styles.accordionLink} onClick={toggleReplies}>
              {showReplies ? "Hide" : "Show"} Replies(
              {commentCount})
            </span>
          )}
          {showForm === data.id && (
            <CommentForm
              showTitle={false}
              onSubmit={handleSubmit}
              onChange={onChange}
              fields={fields}
              showCancel={true}
              onCancel={toggleForm}
            />
          )}
        </div>
      )}
      {showReplies &&
        visibleComments?.map((v, i) => (
          <ol key={i} className="children">
            <li className="comment odd parent">
              <Comment data={v} {...props} onDelete={onDelete} />
            </li>
          </ol>
        ))}
    </div>
  );
};

export default Comment;
