export const sortByDate = (items) =>
  items?.sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds);

export const createFormData = (data) => {
  const formData = new FormData();
  return Object.keys(data).reduce((acc, k) => {
    if (Array.isArray(data[k])) {
      for (const file of data[k]) {
        formData.append("images", file); // appending every file to formdata
      }
    }
    acc.append(k, data[k]);
    return acc;
  }, formData);
};

export const createJsonFormData = (data) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  return formData;
};

export const findNestedItemById = (array, id, nestedArrayName) => {
  for (const item of array) {
    if (item.id === id) return item;
    if (item[nestedArrayName]?.length) {
      const inner = findNestedItemById(item[nestedArrayName], id);
      if (inner) return inner;
    }
  }
  return null;
};

export const messageStatusEnum = Object.freeze({
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
  INFO: "INFO",
});

export const throttle2 = (fn, interval) => {
  let lastTime = 0;
  return () => {
    const now = new Date();
    if (now - lastTime < interval) return;
    fn();
    lastTime = now;
  };
};

export const throttle = (fn, interval) => {
  let timer;
  return () => {
    if (timer) return;
    timer = setTimeout(() => {
      timer = null;
      fn();
    }, interval);
  };
};

export const debounce = (fn, time) => {
  let debounceTimer;
  return () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(fn, time);
  };
};

const displayScrollElement = (el) => {
  el.classList.add("js-scrolled");
};

const hideScrollElement = (el) => {
  el.classList.remove("js-scrolled");
};

const elementInView = (el, dividend = 1) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop <=
    (window.innerHeight || document.documentElement.clientHeight) / dividend
  );
};

const elementOutofView = (el) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop > (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const handleScrollAnimations = () => {
  const scrollElements = document.querySelectorAll(".js-scroll");
  return () =>
    scrollElements.forEach((el) => {
      if (elementInView(el, 1.25)) displayScrollElement(el);
      else if (elementOutofView(el)) hideScrollElement(el);
    });
};

export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
