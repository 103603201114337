import React, { useCallback, useRef, useState, useEffect } from "react";

const Timer = ({ seconds, expired }) => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [timerDated, setTimerDated] = useState(false);
  const intervalRef = useRef();

  const startTimer = useCallback(() => {
    if (!seconds) return;
    const countdownDate = seconds * 1000;
    intervalRef.current = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        //stop our timer
        clearInterval(intervalRef.current);
      } else {
        //update timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        setTimerDated(true);
      }
    }, 1000);
  }, [seconds]);

  useEffect(() => {
    if (!expired) {
      startTimer();
      return () => clearInterval(intervalRef.current);
    }
  }, [expired, startTimer]);

  return timerDated ? (
    <div className="countdown-box">
      <div className="countdown">
        <div className="date">
          <span className="time days text-primary">{timerDays}</span>
          <div>
            <strong>Days</strong>
          </div>
        </div>
        <div className="date">
          <span className="time hours text-primary">{timerHours}</span>
          <div>
            <strong>Hours</strong>
          </div>
        </div>
        <div className="date">
          <span className="time mins text-primary">{timerMinutes}</span>
          <div>
            <strong>Minutes</strong>
          </div>
        </div>
        <div className="date">
          <span className="time secs text-primary">{timerSeconds}</span>
          <div>
            <strong>Seconds</strong>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Timer;
