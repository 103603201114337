import React, { useState } from "react";
import classnames from "classnames";
// import Form from "react-bootstrap/Form";

import FileUpload from "../../Element/FileUpload";
import { timestampToString, dateToTimestamp } from "utils/dateUtils";

import styles from "./styles.module.scss";

const AddFlyerForm = ({ submit, isEdit, fields, setFields }) => {
  const [hasFocus, setHasFocus] = useState(null);
  const onTimeChange = (evt) => {
    evt.preventDefault();
    if (!evt.target.value) return;
    const eventDate = dateToTimestamp(evt.target.value);
    setFields({ ...fields, eventDate });
  };
  const onStartChange = (evt) => {
    evt.preventDefault();
    if (!evt.target.value) return;
    const launchDate = dateToTimestamp(evt.target.value);
    setFields({ ...fields, launchDate });
  };

  const updateUploadedImage = (files) => {
    setFields({
      ...fields,
      image: Array.isArray(files) ? files[0] : files,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (!validate()) return;
    submit({
      ...fields,
      eventDate: timestampToString(fields.eventDate),
      launchDate: timestampToString(fields.launchDate),
    });
  };

  const nullCheck = (val) =>
    val === null || typeof val === "undefined" || val === "";

  const validate = () =>
    Object.keys(fields).reduce((acc, k) => acc || !nullCheck(fields[k]), false);

  const submitButtonText = isEdit ? "Update" : "Create";

  const onDateFocus =
    (name) =>
    ({ target }) => {
      target.type = "datetime-local";
      setHasFocus(name);
    };
  const onDateBlur = ({ target }) => {
    target.type = "text";
    setHasFocus(null);
  };

  return (
    <div className={classnames(styles.root, "contact-box")}>
      <div className={styles.fixedHeader}>
        <h2 className="text-secondary">{`${submitButtonText} Flyer Form`}</h2>
      </div>
      <form className={classnames(styles.form, "dzForm container")}>
        <div className={classnames("form-group", styles.formGroup)}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <label>
                {(fields.eventDate || hasFocus === "eventDate") && (
                  <div className={styles.label}>Event Date</div>
                )}
                <input
                  onChange={onTimeChange}
                  onFocus={onDateFocus("eventDate")}
                  onBlur={onDateBlur}
                  type="text"
                  name="eventDate"
                  required
                  className="form-control input"
                  placeholder="Event Date"
                  value={timestampToString(fields.eventDate) ?? ""}
                />
              </label>
            </div>
            <div className="col-sm-12 col-md-6">
              <label>
                {(fields.launchDate || hasFocus === "launchDate") && (
                  <div className={styles.label}>Ad Launch Date</div>
                )}
                <input
                  onChange={onStartChange}
                  onFocus={onDateFocus("launchDate")}
                  onBlur={onDateBlur}
                  type="text"
                  name="launchDate"
                  required
                  className="form-control input"
                  placeholder="Ad Launch Date"
                  value={timestampToString(fields.launchDate) ?? ""}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <FileUpload
                accept=".jpg,.png,.jpeg,.heic"
                label="Flyer Image"
                updateFilesCb={updateUploadedImage}
              />
            </div>
            <div className="col-md-12 col-sm-12 text-center">
              <button
                type="submit"
                value="Submit"
                className={classnames("btn radius-xl btn-lg", styles.btn)}
                onClick={handleSubmit}
                disabled={!validate()}
              >
                {submitButtonText}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddFlyerForm;
