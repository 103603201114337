import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

export const AddIcon = ({ onClick, className }) => (
  <i
    onClick={onClick}
    className={classnames(
      styles.plus,
      "fa fa-plus-circle icon-bx-xs",
      className
    )}
  ></i>
);
