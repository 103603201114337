import { useEffect, useState, useRef, useCallback } from "react";
import { throttle } from "utils";

export const useScroll = () => {
  const [fullyScrolled, setFullyScrolled] = useState(false);
  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const elRef = useRef(document.querySelector("html"));

  const processScroll = useCallback(() => {
    const el = elRef.current;
    setFullyScrolled(el.scrollTop > el.scrollHeight - el.clientHeight * 1.5);
    setScrollX(el.scrollLeft);
    setScrollY(el.scrollTop);
  }, [setFullyScrolled, setScrollX, setScrollY, elRef.current]);

  useEffect(() => {
    processScroll();
    return window.addEventListener("scroll", throttle(processScroll, 250));
  }, []);

  return { scrollX, scrollY, atBottom: fullyScrolled };
};
