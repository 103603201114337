import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { useComments } from "store/comments";
import { useBlogs } from "store/blogs";
import { useGlobal } from "store/global";
import { sortByDate } from "utils";
import SideBar from "markup/Element/SideBar";
import AddBlogForm from "markup/Pages/Blogs/AddBlogForm";

import ClassDetailContent from "./ClassDetailContent";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";

const BlogDetails = () => {
  const [commentFields, setCommentFields] = useState({});
  const [currBlog, setCurrBlog] = useState(null);

  const { state: locState } = useLocation();
  const { blogId } = locState ?? {};

  const {
    actions: { setModalContent },
    state: { isAdmin, authUser },
  } = useGlobal();

  const {
    actions: { get: getComments, create: createComment },
    state: commentsState,
  } = useComments();

  const {
    actions: { get: getBlogs, create, update, getCurrBlog, setCurrId },
    state: { currId, blogs },
  } = useBlogs();

  useEffect(() => {
    getBlogs();
  }, []);

  useEffect(() => {
    if (blogs?.length && currId) {
      const blog = getCurrBlog(currId);
      setCurrBlog(blog);
    }
  }, [getCurrBlog, currId, blogs]);

  useEffect(() => {
    if (currId !== blogId) {
      setCurrId(blogId);
    }
  }, [setCurrId, blogId]);

  const { rootComments } = commentsState ?? {};

  useEffect(() => {
    if (currBlog?.id) {
      getComments({ blogId: currBlog?.id });
    }
  }, [currBlog?.id]);

  const onCommentSubmit = (e) => {
    e.preventDefault();
    createComment({
      ...commentFields,
      userId: authUser.uid,
      author: authUser?.displayName,
      parentDocPath: `blogs/${currBlog.id}`,
    });
    setCommentFields({});
  };

  const onTextUpdate = (commentFields) => {
    update({
      ...commentFields,
      userId: authUser.uid,
      author: authUser?.displayName,
      id: currBlog.id,
    });
  };

  const onChange = (field) => (e) => {
    setCommentFields((currFields) => ({
      ...currFields,
      [field]: e.currentTarget.value,
    }));
  };

  const handleSubmit = useCallback(
    ({ imageOnly, item }) =>
      () => {
        setModalContent(
          <AddBlogForm
            submit={
              item?.id
                ? (fields) => update({ ...item, ...fields })
                : (fields) => create({ ...fields, userId: authUser.uid })
            }
            authUser={authUser}
            imageOnly={imageOnly}
          />
        );
      },
    [authUser, setModalContent]
  );

  const updateImage = () => {
    if (!isAdmin) return null;
    return handleSubmit({
      imageOnly: true,
      item: getCurrBlog(),
    });
  };

  const visibleRootComments = sortByDate(
    rootComments.filter(
      (v) => !v.rejected && (v.approved || authUser?.accessLevel < 3)
    )
  );

  return !currBlog && !blogId ? (
    <Redirect to="/blog-classic-grid" push />
  ) : currBlog ? (
    <div className="page-content">
      <div className="content-block">
        <div className="section-full bg-white content-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <ClassDetailContent
                  onUpdate={onTextUpdate}
                  onImageUpdate={updateImage()}
                  data={currBlog}
                  commentCount={visibleRootComments?.length}
                />
                <CommentForm
                  onSubmit={onCommentSubmit}
                  onChange={onChange}
                  fields={{ ...commentFields, authUser }}
                />
                <CommentList comments={visibleRootComments} />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <SideBar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default BlogDetails;
