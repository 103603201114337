import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import styles from "./styles.module.scss";

const AccordionBlog = ({ data = [], start = 0, count }) => {
  const [activeDefault, setActiveDefault] = useState();

  const handleClick = i => () => setActiveDefault(activeDefault === i ? -1 : i);

  return (
    <Accordion className="accordion FAQ-box" defaultActiveKey="-1">
      {data.map((d, i) => {
        if (i >= start && i < start + (count ?? data.length)) {
          const paragraphs = typeof d.text === 'string' ? d.text.split("<br />") : null;

          return (
            <Card key={i}>
              <div className="card-header ">
                <Accordion.Toggle
                  variant="link"
                  className={` ${activeDefault === i ? "" : "collapsed"}`}
                  onClick={handleClick(i)}
                  eventKey={`${i}`}
                >
                  {d.title}
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey={`${i}`} className="">

                <Card.Body>
                  {
                    paragraphs ? paragraphs.map((v, i) => (
                      <div key={i} className={i === paragraphs.length - 1 ? '' : styles.paragraph}>{v}</div>
                    ))
                      : d.text
                  }
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        }
      })}
    </Accordion>
  );
};

export default AccordionBlog;
