import React, { useState } from "react";
import classnames from "classnames";

import FileUpload from "../../Element/FileUpload";
import styles from "./styles.module.scss";

const initFields = {
  name: "",
  subject: "",
  email: "",
  phone: "",
  facebook: "",
  googlePlus: "",
  linkedin: "",
  instagram: "",
  twitter: "",
  desc: "",
  image: null,
};

const AddTeacherForm = ({ submit, imageOnly }) => {
  const [fields, setFields] = useState(
    imageOnly ? { image: null } : initFields
  );

  const onChange = (evt) => {
    setFields({ ...fields, [evt.target.name]: evt.target.value });
  };

  const updateUploadedFiles = (files) =>
    setFields({
      ...fields,
      image: Array.isArray(files) ? files[0] : files,
    });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submit(fields);
  };

  const submitButtonText = imageOnly ? "Update" : "Create";

  return (
    <div className="contact-box">
      <div className={styles.fixedHeader}>
        <h2 className="text-secondary">AddTeacherForm</h2>
      </div>
      <form className={classnames(styles.form, "dzForm container")}>
        <div className="row">
          {!imageOnly && (
            <>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="text"
                    name="name"
                    required
                    className="form-control input"
                    placeholder="Name"
                    value={fields.name}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="text"
                    name="subject"
                    required
                    className="form-control input"
                    placeholder="Subject(s)"
                    value={fields.subject}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="email"
                    name="email"
                    className="form-control input"
                    required
                    placeholder="Email Address"
                    value={fields.email}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="mobile"
                    name="phone"
                    required
                    className="form-control input"
                    placeholder="Phone"
                    value={fields.phone}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="text"
                    name="facebook"
                    required
                    className="form-control input"
                    placeholder="facebook"
                    value={fields.facebook}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="text"
                    name="googlePlus"
                    required
                    className="form-control input"
                    placeholder="googlePlus"
                    value={fields.googlePlus}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="text"
                    name="linkedin"
                    required
                    className="form-control input"
                    placeholder="linkedin"
                    value={fields.linkedin}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="text"
                    name="instagram"
                    required
                    className="form-control input"
                    placeholder="instagram"
                    value={fields.instagram}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    onChange={onChange}
                    type="text"
                    name="twitter"
                    required
                    className="form-control input"
                    placeholder="twitter"
                    value={fields.twitter}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <textarea
                    onChange={onChange}
                    rows={4}
                    name="desc"
                    required
                    className="form-control desc"
                    placeholder="Description"
                    value={fields.desc}
                  />
                </div>
              </div>
            </>
          )}
          <div className="col-sm-12">
            <div className="form-group">
              <FileUpload
                accept=".jpg,.png,.jpeg"
                label="Teacher's Image"
                updateFilesCb={updateUploadedFiles}
              />
            </div>
          </div>
          <div className="col-md-12 col-sm-12 text-center">
            <button
              type="submit"
              value="Submit"
              className="btn radius-xl btn-lg"
              onClick={handleSubmit}
            >
              {submitButtonText}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddTeacherForm;
