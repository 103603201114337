import React, { useEffect, useRef } from "react";
// import { PortalHost } from "@gorhom/portal";

import { useGlobal } from "store/global";
import CloseButton from "../CloseButton";
import styles from "./styles.module.scss";

const Modal = () => {
  const ref = useRef();

  const {
    state: { modalContent },
    actions: { setModalContent, setModalMounted },
  } = useGlobal();

  useEffect(() => {
    setModalMounted(!!modalContent);
  }, [modalContent]);

  const handleClose = () => {
    setModalContent(null);
  };

  return modalContent ? (
    <div className={`${styles.root}`}>
      <div className={styles.modalScreen} onClick={handleClose} />
      <div className={styles.modalContainer}>
        <div ref={ref} className={styles.modal} id="modalContent"></div>
        <CloseButton onClick={handleClose} windowType="MODAL" />
      </div>
    </div>
  ) : null;
};

export default Modal;
