import React, { createContext, useContext, useMemo, useCallback } from "react";
import axios from "axios";
import { useGlobal } from "store/global";
import { setLocalStorage } from "utils/storage";

const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

const createFormData = (data) => {
  const formData = new FormData();
  return Object.keys(data).reduce((acc, k) => {
    formData.append(k, data[k]);
    return formData;
  }, formData);
};

const createActions = ({ globalActions }) => ({
  register: async (data) => {
    globalActions.onFetchStart();
    const formData = createFormData({ payload: JSON.stringify(data) });
    [...formData.entries()].map(([k, v]) => console.log("formData", k, ":", v));

    try {
      const res = await axios.post("/api/auth/register", formData);
      const { authUser: aUser = {}, user = {} } = res.data;
      const authUser = { ...aUser, ...user };
      globalActions.onAuthSuccess(authUser);
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onAuthFail(serverError);
      console.log("RegisterUser Error", error?.response?.statusText);
    }
  },
  login: async (data) => {
    globalActions.onFetchStart();
    try {
      const res = await axios.post("/api/auth/login", data);
      const { authUser: aUser = {}, user = {} } = res.data;
      const authUser = { ...aUser, ...user };
      setLocalStorage("authUser", authUser);
      globalActions.onAuthSuccess(authUser);
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onAuthFail(serverError);
      console.log("LoginUser Error", error?.response?.statusText);
    }
  },
  logout: () => {
    globalActions.onFetchStart();
    globalActions.onLogout();
  },
  forgotPassword: async (data) => {
    globalActions.onFetchStart();
    try {
      const res = await axios.post("/api/auth/forgotPassword", data);
      if (res?.data?.success);
      globalActions.onFetchSuccess(
        "Password reset instructions have been emailed to you."
      );
    } catch (e) {
      const {
        statusText,
        data: { serverError: sE, displayError },
      } = e?.response ?? {};
      const serverError = sE ?? statusText ?? e.message;
      globalActions.onFetchFail({ serverError, displayError });
      console.log("ForgotPassword Error", statusText);
    }
  },
});

export const useAuth = () => {
  const data = useContext(AuthContext);
  if (data === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return data;
};

export const AuthProvider = ({ children }) => {
  const {
    state: { authUser },
    actions: globalActions,
  } = useGlobal();
  const actions = useCallback(createActions({ globalActions }), []);

  return useMemo(
    () => (
      <AuthContext.Provider
        value={{
          authUser,
          actions,
        }}
      >
        {children}
      </AuthContext.Provider>
    ),
    [authUser, actions]
  );
};
