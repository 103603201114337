import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

const Hamburger = ({ onClick, menuOpen, transparent, showBackground }) => (
  <div
    className={classnames(
      styles.root,
      (transparent || !showBackground) && styles.transparent
    )}
  >
    <button
      className="navbar-toggler navicon justify-content-end"
      onClick={onClick}
      type="button"
      data-toggle="collapse"
      aria-controls="navbarNavDropdown"
      aria-expanded={menuOpen}
      aria-label="Toggle navigation"
    >
      {[...Array(3)].map((_, i) => (
        <span key={i}></span>
      ))}
    </button>
  </div>
);

export default Hamburger;
