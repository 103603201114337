import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  // useMemo,
} from "react";
import axios from "axios";
import { useGlobal } from "store/global";
import { setLocalStorage, getLocalStorage } from "utils/storage";
import { createJsonFormData } from "utils";

const initState = {
  testimonials: [],
};

const TestimonialsContext = createContext();
TestimonialsContext.displayName = "TestimonialsContext";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "GET": {
      return { ...state, testimonials: payload.data };
    }
    case "CREATE": {
      return { ...state, testimonials: [...state.testimonials, payload.data] };
    }
    case "DELETE": {
      return {
        ...state,
        testimonials: state.testimonials.filter(
          (item) => item.id !== payload.data.id
        ),
      };
    }
    case "UPDATE": {
      return {
        ...state,
        testimonials: state.testimonials.map((v) => {
          if (v.id === payload.data.id) return payload.data;
          return v;
        }),
      };
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const createActions = (dispatch, globalActions) => ({
  get: async (payload) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const localData = getLocalStorage("testimonials");
    if (localData) {
      dispatch({ type: "GET", payload: { data: localData } });
      globalActions.onFetchSuccess("Locally loaded Testimonials", false);
    }

    try {
      const res = await axios.get("/api/testimonials", payload);
      const { testimonials } = res.data ?? {};
      if (!testimonials) return;
      setLocalStorage("testimonials", testimonials);
      globalActions.onFetchSuccess("Successfully fetched Testimonials", false);
      dispatch({ type: "GET", payload: { data: testimonials } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("fetch Testimonials Error", serverError);
    }
  },
  create: async ({ id: userId, fname }) => {
    globalActions.onFetchStart();

    try {
      const res = await axios.post("/api/testimonials", { userId, fname });
      const { testimonial } = res.data ?? {};
      if (!testimonial) throw new Error("Testimonial response is undefined");
      globalActions.onFetchSuccess("Successfully created Testimonial");
      globalActions.setModalContent(null);
      dispatch({ type: "CREATE", payload: { data: testimonial } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("create Testimonial Error", error?.response?.statusText);
    }
  },
  update: async (payload) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const action = payload.rejected ? "deleted" : "updated";
    const formData = createJsonFormData(payload);
    try {
      const res = await axios.put("/api/testimonials", formData);
      const { testimonial } = res.data ?? {};
      if (!testimonial) throw new Error("Testimonial response is undefined");
      globalActions.onFetchSuccess(`Successfully ${action} Testimonial`);
      globalActions.setModalContent(null);
      dispatch({ type: "UPDATE", payload: { data: testimonial } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("Update Testimonial Error", serverError);
    }
  },
  delete: async ({ id, path }) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    try {
      await axios.delete("/api/testimonials", { data: { id, path } });
      globalActions.onFetchSuccess("Successfully deleted Testimonial");
      dispatch({ type: "DELETE", payload: { data: { id } } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("delete Testimonial Error", serverError);
    }
  },
});

export const useTestimonials = () => {
  const data = useContext(TestimonialsContext);
  if (data === undefined) {
    throw new Error(
      "useTestimonials must be used within a TestimonialsProvider"
    );
  }
  return data;
};

export const TestimonialsProvider = ({ children }) => {
  const { actions: globalActions } = useGlobal();
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = useCallback(createActions(dispatch, globalActions));

  return (
    <TestimonialsContext.Provider value={{ state, actions }}>
      {children}
    </TestimonialsContext.Provider>
  );
};
