export const setLocalStorage = (key, val) => {
  localStorage.setItem(key, JSON.stringify(val));
};

export const mergeToLocalStorageArray = (key, val) => {
  if (typeof val !== "object") return;
  const storage = getLocalStorage(key);
  localStorage.setItem(key, JSON.stringify([...val, ...storage]));
};

export const mergeToLocalStorageObject = (key, val) => {
  if (typeof val !== "object") return;
  const storage = getLocalStorage(key);
  localStorage.setItem(key, JSON.stringify({ ...val, ...storage }));
};

export const getLocalStorage = (key) => {
  return key ? JSON.parse(localStorage.getItem(key)) : null;
};

export const clearLocalStorage = (key) => {
  localStorage.removeItem(key);
};
