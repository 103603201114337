import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

export const TagLi = ({ name, handlesettag, tagActive }) => (
  <li
    className={` tag ${tagActive ? "btn active" : "btn"}`}
    onClick={() => handlesettag(name)}
  >
    <input type="radio" />
    <Link to={"#"}>
      {" "}
      <span className={styles.tagLi}>
        {name} {""}
      </span>{" "}
    </Link>
  </li>
);
