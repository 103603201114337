import React from "react";
import { formatDate } from "@fullcalendar/react";

import { Link } from "react-router-dom";

//Images
const large1 = "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fblog%2Frecent-blog%2Fpic1.jpg?alt=media&token=69f36006-5d13-4470-ba1a-f428ba44d196";
const large2 = "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fblog%2Frecent-blog%2Fpic2.jpg?alt=media&token=aa69785c-8283-4202-84c9-6d4d89dc44c4";
const large3 = "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fgeneral%2Fblog%2Frecent-blog%2Fpic3.jpg?alt=media&token=4dc3f053-95cd-48ea-bbd6-35cb6bb00b8d";

const eventBlog = [
  {
    images: large1,
    date: new Date("07/05/2021"),
    title: "Business Conferences 2017",
    address: "OP, KC",
  },
  {
    images: large2,
    date: new Date("07/27/2021"),
    title: "5 Outrageous Ideas For You",
    address: "OP, KC",
  },
  {
    images: large3,
    date: new Date("08/25/2021"),
    title: "Seven Latest Developments",
    address: "OP, KC",
  },
  {
    images: large1,
    date: new Date("07/03/2021"),
    title: "Five Mind Numbing Facts About",
    address: "OP, KC",
  },
  {
    images: large2,
    date: new Date("09/19/2021"),
    title: "Five Ways To Introduce",
    address: "OP, KC",
  },
  {
    images: large3,
    date: new Date("07/17/2021"),
    title: "Ten Things To Know About",
    address: "OP, KC",
  },
];

const Event = () => (
  <div className="page-content">
    <div className="content-block">
      <div className="section-full bg-white content-inner">
        <div className="container">
          <div className="row">
            {eventBlog.map((item, index) => (
              <div className="col-lg-6 col-md-6 col-sm-6" key={index}>
                <div className="event-box">
                  <div className="event-media">
                    <img src={item.images} alt="" />
                  </div>
                  <div className="event-info">
                    <div className="dlab-post-title">
                      <h3 className="post-title">
                        <Link to={"/event-details"}>{item.title}</Link>
                      </h3>
                    </div>
                    <div className="event-meta">
                      <ul>
                        <li className="post-date">
                          <strong>{item.date.getDate()}</strong>
                          <span>
                            {formatDate(item.date, { month: "short" })}
                          </span>
                        </li>
                        <li className="post-author">
                          <i className="fa fa-map-marker"></i> {item.address}
                        </li>
                      </ul>
                    </div>
                    <div className="dlab-post-text">
                      <p>
                        Lorem ipsum dolor sit amet elit. Cum veritatis sequi
                        nulla nihil, dolor voluptatum nemo adipisci eligendi!
                        Sed nisi perferendis, totam harum dicta.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="pagination-bx rounded pagination-lg clearfix primary">
                <ul className="pagination justify-content-center">
                  <li className="previous">
                    <Link to={"#"}>
                      <i className="ti-arrow-left"></i> Prev
                    </Link>
                  </li>
                  <li className="active">
                    <Link to={"#"}>1</Link>
                  </li>
                  <li>
                    <Link to={"#"}>2</Link>
                  </li>
                  <li>
                    <Link to={"#"}>3</Link>
                  </li>
                  <li className="next">
                    <Link to={"#"}>
                      Next <i className="ti-arrow-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Event;
