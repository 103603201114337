import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useGlobal } from "store/global";
import { schoolLocations, schoolPhone, schoolEmail } from "Constants";

const initState = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const Contact = () => {
  const [fields, setFields] = useState(initState);
  const {
    actions: { sendMail },
  } = useGlobal();

  const handleChange = (field) => (e) =>
    setFields({ ...fields, [field]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMail(fields);
  };
  return (
    <div className="page-content">
      <div className="content-block">
        <div className="section-full bg-white">
          <div className="container">
            <div className="row align-items-center content-inner">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="icon-bx-wraper m-b30 left">
                  <div className="icon-md m-b20 m-t5">
                    <Link to={"#"} className="icon-cell text-blue">
                      <i className="ti-headphone-alt"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h4 className="dlab-tilte m-b5">Phone</h4>
                    <p>Phone {schoolPhone}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="icon-bx-wraper m-b30 left">
                  <div className="icon-md m-b20 m-t5">
                    <Link to={"#"} className="icon-cell text-green">
                      <i className="ti-location-pin"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h4 className="dlab-tilte m-b5">Address</h4>
                    {schoolLocations.map((location, i) => (
                      <p key={i}>{location}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="icon-bx-wraper m-b30 left">
                  <div className="icon-md m-b20 m-t5">
                    <Link to={"#"} className="icon-cell text-orange">
                      <i className="ti-email"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h4 className="dlab-tilte m-b5">Email</h4>
                    <p>{schoolEmail}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row content-inner-4">
            <div className="banner-map col-md-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.0035555838786!2d-94.73979004908298!3d38.94674715153853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c094cf79a41eb3%3A0x141663e2154388c0!2s13010%20W%20100th%20Terrace%2C%20Lenexa%2C%20KS%2066215!5e0!3m2!1sen!2sus!4v1636230849328!5m2!1sen!2sus"
                style={{ width: "100%", height: "600px" }}
                className="align-self-stretch rounded-sm border-0"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div> */}
            <div className="row contact-box content-inner-5">
              <div className="section-head text-center col-md-12">
                <h2 className="text-secondary">Contact Us</h2>
                <p className="m-b0">
                  Enquire here about any information you need on Freedom
                  Learning Academy.
                </p>
              </div>
              <form className="dzForm container">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        name="name"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Enter your name"
                        value={fields.name}
                        onChange={handleChange("name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        required
                        placeholder="Your Email Address"
                        value={fields.email}
                        onChange={handleChange("email")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        name="phone"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Phone"
                        value={fields.phone}
                        onChange={handleChange("phone")}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        rows="4"
                        className="form-control"
                        required
                        placeholder="Your message here"
                        value={fields.message}
                        onChange={handleChange("message")}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 text-center">
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className="btn radius-xl btn-lg"
                      onClick={handleSubmit}
                    >
                      Send Message<span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
