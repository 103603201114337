import { useEffect, useState, useCallback } from "react";
import { throttle } from "utils";

export const useResize = () => {
  const [device, setDevice] = useState("PHONE");

  const getDevice = useCallback((w) => {
    if (w < 550) return "PHONE";
    else if (w < 769) return "TABLET";
    else if (w < 1201) return "TABLETPRO";
    else return "DESKTOP";
  }, []);

  const processResize = useCallback(() => {
    setDevice(getDevice(window.innerWidth));
  }, [getDevice, setDevice]);

  useEffect(() => {
    processResize();
    return window.addEventListener("resize", throttle(processResize, 250));
  }, []);

  return device;
};
