import React, {
  createContext,
  useMemo,
  useContext,
  useReducer,
  memo,
} from "react";
import axios from "axios";

import { setLocalStorage, getLocalStorage } from "utils/storage";
import { createFormData } from "utils";
import { useGlobal } from "store/global";

const initState = {
  socialEvents: [],
  currEvent: null,
  eventExpired: false,
  adLaunched: false,
};

const SocialEventContext = createContext(initState);
SocialEventContext.displayName = "SocialEventContext";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_EVENTS": {
      return { ...state, socialEvents: payload.data };
    }
    case "SET_CURR_EVENT": {
      return { ...state, currEvent: payload.data };
    }
    case "SET_CURR_EVENT_DATE": {
      return {
        ...state,
        currEvent: { ...state.currEvent, eventDate: payload.data },
      };
    }
    case "SET_EVENT_EXPIRED": {
      return { ...state, eventExpired: payload.data };
    }
    case "SET_AD_LAUNCHED": {
      return { ...state, adLaunched: payload.data };
    }
    case "ADD_EVENT": {
      return { ...state, socialEvents: [...state.socialEvents, payload.data] };
    }
    case "DELETE_EVENT": {
      return {
        ...state,
        socialEvents: state.socialEvents.filter(
          (item) => item.id !== payload.data.id
        ),
      };
    }
    case "UPDATE": {
      return {
        ...state,
        socialEvents: state.socialEvents.map((v) => {
          if (v.id === payload.data.id) return payload.data;
          return v;
        }),
      };
    }
    case "POST_PROCESS": {
      const afterDate = payload?.data?.afterDate;
      const currEvent = state.socialEvents.sort((a, b) => {
        return a.eventDate.seconds > b.eventDate.seconds ? 1 : -1;
      })[0];
      const adLaunched = currEvent
        ? currEvent.launchDate.seconds <= new Date().getTime() / 1000
        : false;

      const thresholdDate = afterDate ? new Date(afterDate) : new Date();
      const eventExpired =
        currEvent?.eventDate.seconds * 1000 <= thresholdDate.getTime();

      return {
        ...state,
        currEvent: adLaunched ? currEvent : null,
        eventExpired,
        adLaunched,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const createActions = (dispatch, globalActions) => ({
  get: async ({ afterDate } = {}) => {
    globalActions?.onFetchStart({ isLoadingLocal: true });
    const localData = getLocalStorage("socialEvents");
    if (localData) {
      dispatch({ type: "SET_EVENTS", payload: { data: localData } });
      globalActions?.onFetchSuccess("Locally loaded SocialEvents", false);
    }

    try {
      const params = {
        params: { ...(afterDate && { afterDate }) },
      };
      const res = await axios.get("/api/socialEvents", params);
      const { socialEvents } = res.data ?? {};
      if (!socialEvents) return;
      setLocalStorage("socialEvents", socialEvents);
      globalActions?.onFetchSuccess("Successfully fetched SocialEvents", false);
      dispatch({ type: "SET_EVENTS", payload: { data: socialEvents } });
      dispatch({ type: "POST_PROCESS", payload: { data: afterDate } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions?.onFetchFail({ serverError });
      console.log("fetch SocialEvents Error", serverError);
    }
  },
  create: async (data) => {
    globalActions.onFetchStart();
    const formData = createFormData(data);

    try {
      const res = await axios.post("/api/socialEvents", formData);
      const { socialEvent } = res.data ?? {};
      if (!socialEvent) throw new Error("SocialEvent response is undefined");
      globalActions.onFetchSuccess("Successfully created SocialEvent");
      globalActions.setModalContent(null);
      dispatch({ type: "ADD_EVENT", payload: { data: socialEvent } });
      dispatch({ type: "SET_CURR_EVENT", payload: { data: socialEvent } });
      const eventExpired =
        socialEvent?.eventDate.seconds * 1000 <= new Date().getTime();
      dispatch({
        type: "SET_EVENT_EXPIRED",
        payload: { data: eventExpired },
      });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onAuthFail(serverError);
      globalActions.setModalContent(null);
      console.log("registerUser Error", error?.response?.statusText);
    }
  },
  updateCurrEvent: (payload) => {
    dispatch({ type: "SET_CURR_EVENT", payload: { data: payload } });
  },
  update: async (payload) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const formData = createFormData(payload);
    try {
      const res = await axios.put("/api/socialEvents", formData);
      const { socialEvent } = res.data ?? {};
      if (!socialEvent) throw new Error("SocialEvent response is undefined");
      dispatch({ type: "SET_CURR_EVENT", payload: { data: socialEvent } });
      const eventExpired =
        socialEvent?.eventDate.seconds * 1000 <= new Date().getTime();
      dispatch({
        type: "SET_EVENT_EXPIRED",
        payload: { data: eventExpired },
      });
      globalActions.onFetchSuccess("Successfully updated SocialEvent");
      globalActions.setModalContent(null);
      dispatch({ type: "UPDATE", payload: { data: socialEvent } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("loginSocialEvent Error", serverError);
    }
  },
  delete: async ({ id, imagePath }) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    try {
      await axios.delete("/api/socialEvents", { data: { id, imagePath } });
      globalActions.onFetchSuccess("Successfully deleted SocialEvent");
      dispatch({ type: "DELETE_EVENT", payload: { data: { id } } });
      dispatch({ type: "POST_PROCESS" });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("deleteSocialEvent Error", serverError);
    }
  },
});

export const useSocialEvents = () => {
  const data = useContext(SocialEventContext);
  if (data === undefined) {
    throw new Error(
      "useSocialEvents must be used within a SocialEventProvider"
    );
  }
  return data;
};

const SocialEventProvider = ({ children }) => {
  const { actions: globalActions } = useGlobal();
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = useMemo(
    () => createActions(dispatch, globalActions),
    [globalActions, dispatch]
  );
  const value = useMemo(() => ({ state, actions }), [state, actions]);

  return (
    <SocialEventContext.Provider value={value}>
      {children}
    </SocialEventContext.Provider>
  );
};

const MemoizedSocialEventProvider = memo(SocialEventProvider);

export { MemoizedSocialEventProvider as SocialEventProvider };
