import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const GalleryWidget = ({ gallery }) => (
  <div className="widget widget_gallery gallery-grid-4">
    <h5 className="widget-title">Gallery</h5>
    <ul>
      <SimpleReactLightbox>
        <SRLWrapper>
          {gallery.map(item => (
            <li key={item.id}>
              <span className="check-km">
                <img src={item.imageUrl} alt="" />
              </span>
            </li>
          ))}
        </SRLWrapper>
      </SimpleReactLightbox>
    </ul>
  </div>
);

export default GalleryWidget;
