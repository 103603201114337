import React from "react";
import BlogMasonry from "markup/Pages/Blogs/BlogMasonry";
import GalleryFilter from "markup/Pages/GalleryFilter";

const SideBar = () => (
  <div className="sticky-top">
    <BlogMasonry isWidget={true} />
    <GalleryFilter isWidget={true} />
  </div>
);
export default SideBar;
