import React, { createContext, useContext, useReducer, useMemo } from "react";
import axios from "axios";
import { useGlobal } from "store/global";
// import {
//   setLocalStorage,
//   getLocalStorage,
//   clearLocalStorage,
// } from "utils/storage";
import { createFormData, findNestedItemById } from "utils";

const initState = JSON.stringify({ rootComments: [] });

const getDocId = (path, fromLastIndex = 0) => {
  const ids = path?.split("/comments/");
  return ids?.[ids.length - fromLastIndex - 1];
};

const getComment = ({ comments, path, ancestorLevel = 0 }) => {
  if (!path || !comments) return null;
  const docId = getDocId(path, ancestorLevel);
  if (!docId) return null;
  return findNestedItemById(comments, docId, "comments");
};

const isRootComment = (path) => path?.split("comments")?.length - 1 === 1;

const CommentContext = createContext();
const reducer = (state, { type, payload }) => {
  const data = payload.data;
  if (!data) return;

  switch (type) {
    case "GET": {
      if (!isRootComment(data[0]?.docPath)) {
        const parsedState = JSON.parse(state);
        const parent = getComment({
          comments: parsedState.rootComments,
          path: data[0]?.docPath,
          ancestorLevel: 1,
        });
        if (!parent) return state;
        parent.comments = data;
        return JSON.stringify(parsedState);
      }
      return JSON.stringify({ rootComments: data });
    }
    case "CREATE": {
      const parsedState = JSON.parse(state);
      if (isRootComment(data.docPath)) {
        return JSON.stringify({
          ...parsedState,
          rootComments: [...parsedState.rootComments, data],
        });
      }
      const parent = getComment({
        comments: parsedState.rootComments,
        path: data.docPath,
        ancestorLevel: 1,
      });
      if (!parent) return state;
      parent.comments = [...(parent.comments ?? []), data];
      return JSON.stringify({
        ...parsedState,
        rootComments: [...parsedState.rootComments],
      });
    }
    case "DELETE": {
      const parsedState = JSON.parse(state);
      return JSON.stringify({
        ...parsedState,
        rootComments: parsedState.rootComments.filter(
          (item) => item.id !== data.id
        ),
      });
    }
    case "UPDATE": {
      const parsedState = JSON.parse(state);
      if (isRootComment(data.docPath)) {
        parsedState.rootComments = parsedState.rootComments?.map((v) =>
          v.id === data.id ? data : v
        );
        return JSON.stringify(parsedState);
      }
      const parent = getComment({
        comments: parsedState.rootComments,
        path: data.docPath,
        ancestorLevel: 1,
      });
      if (!parent) return;
      parent.comments = parent.comments?.map((v) =>
        v.id === data.id ? data : v
      );
      return JSON.stringify(parsedState);
    }
    default:
      throw new Error(`Unhandled comment action type: ${type}`);
  }
};

const createActions = (dispatch, globalActions, authenticated) => ({
  get: async (payload /* , callback */) => {
    // global: store is reducer
    globalActions.onFetchStart({ isLoadingLocal: true });
    // const localData = getLocalStorage("comments");
    // if (localData) {
    //   // if (localData && !callback) {
    //   dispatch({ type: "GET", payload: { data: localData } });
    //   globalActions.onFetchSuccess("Locally loaded Comments", false);
    // }
    const parentDocPath = payload.parentDocPath ?? `blogs/${payload.blogId}`;
    const params = { payload, parentDocPath };

    try {
      const res = await axios.get("/api/comments", { params });
      const { comments } = res.data ?? {};
      if (!comments?.length) return;
      // setLocalStorage("comments", comments);
      globalActions.onFetchSuccess("Successfully fetched Comments", false);
      dispatch({ type: "GET", payload: { data: comments } });
      // if (!callback) dispatch({ type: "GET", payload: { data: comments } });
      // else callback(comments);
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("Comments error", serverError);
    }
  },
  create: async (data) => {
    if (!authenticated) {
      // alert("Please log in to post");
      globalActions.addMessage("Please log in to post");
      return;
    }
    globalActions.onFetchStart();
    const formData = data ? createFormData(data) : null;

    try {
      const res = await axios.post("/api/comments", formData);
      const { comment } = res.data ?? {};
      if (!comment) throw new Error("Comment response is undefined");
      globalActions.onFetchSuccess("Successfully created Comment");
      globalActions.setModalContent(null);
      dispatch({ type: "CREATE", payload: { data: comment } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("Create Comment error", error?.response?.statusText);
    }
  },
  update: async (payload) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const formData = createFormData(payload);
    try {
      const res = await axios.put("/api/comments", formData);
      const { comment } = res.data ?? {};
      if (!comment) throw new Error("Comment response is undefined");
      globalActions.onFetchSuccess(
        payload.customMsg ?? "Successfully updated Comment"
      );
      globalActions.setModalContent(null);
      dispatch({ type: "UPDATE", payload: { data: comment } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("Update Comment Error", serverError);
    }
  },
  delete: async ({ id, path }) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    try {
      await axios.delete("/api/comments", { data: { id, path } });
      globalActions.onFetchSuccess("Successfully deleted Comment");
      dispatch({ type: "DELETE", payload: { data: { id } } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("delete Comment Error", serverError);
    }
  },
});

export const useComments = () => {
  const data = useContext(CommentContext);
  if (data === undefined) {
    throw new Error("useComment must be used within a CommentProvider");
  }
  return data;
};

export const CommentProvider = ({ children }) => {
  const {
    actions: globalActions,
    state: { authenticated },
  } = useGlobal();
  const [state, dispatch] = useReducer(reducer, initState);
  const parsedState = JSON.parse(state);
  const actions = createActions(dispatch, globalActions, authenticated);

  return useMemo(
    () => (
      <CommentContext.Provider value={{ state: parsedState, actions }}>
        {children}
      </CommentContext.Provider>
    ),
    [children, state]
  );
};
