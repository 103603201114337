import React from "react";
import { Button } from "react-bootstrap";

import styles from "./styles.module.scss";

const AddButton = ({ text, onClick }) => (
  <Button className={styles.root} onClick={onClick}>
    {text}
  </Button>
);

export default AddButton;
