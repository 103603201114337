import React, { useEffect, useState, useMemo } from "react";
import { createPortal } from "react-dom";

import FilterTab from "markup/Element/FilterTab";
import AddButton from "markup/Element/AddButton";
import Confirmation from "markup/Element/Confirmation";
import AddGalleryForm from "./AddGalleryForm";
import GalleryWidget from "markup/Widgets/GalleryWidget";
import Pagination from "markup/Element/Pagination";

import { useGlobal } from "store/global";
import { useGallery } from "store/picGallery";

import { GalleryConstants } from "store/picGallery/galleryConstants";
const { PAGE_SIZE, BATCH_SIZE } = GalleryConstants;

import styles from "./styles.module.scss";

const GalleryFilter = ({ isWidget = false, limit = 8 }) => {
  const [currentItem, setCurrentItem] = useState();
  const [addItem, setAddItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  const {
    state: { gallery, page },
    actions: { get, create, delete: deletePic },
    pageActions: { gotoPage, nextPage, prevPage },
  } = useGallery();
  const {
    state: { loading, isAdmin, modalMounted },
    actions: { setModalContent },
  } = useGlobal();

  useEffect(() => {
    get({ limit: isWidget ? limit : PAGE_SIZE * BATCH_SIZE });
  }, [isWidget, limit]);
  const [loadingId, setLoadingId] = useState(null);

  useEffect(() => {
    if (!modalMounted) {
      setAddItem(false);
      setDeleteItem(false);
    }
  }, [modalMounted]);

  const handleDelete = (item) => () => {
    setModalContent(true);
    setCurrentItem(item);
    setDeleteItem(true);
  };

  const handleAdd = () => {
    setModalContent(true);
    setAddItem(true);
  };

  const buttonText = loading.status ? "Loading Images . . ." : "Add Images";

  const paginateProps = useMemo(
    () => ({
      page,
      gotoPage,
      nextPage,
      prevPage,
      totalSize: gallery.length,
      pageSize: PAGE_SIZE,
    }),
    [page, gotoPage, nextPage]
  );

  const pagedGallery = useMemo(() => {
    const start = (page - 1) * PAGE_SIZE;
    return gallery.slice(start, start + PAGE_SIZE);
  }, [page, gallery]);

  return isWidget ? (
    <GalleryWidget gallery={gallery} />
  ) : (
    <div className="page-content">
      {isAdmin && <AddButton text={buttonText} onClick={handleAdd} />}
      {pagedGallery.length ? (
        <>
          <div className="content-block">
            <FilterTab
              gallery={pagedGallery}
              onDelete={handleDelete}
              authenticated={isAdmin}
              loadingId={loading.status && !loading.global ? loadingId : null}
            />
          </div>
        </>
      ) : (
        <h2 className={styles.empty}>Gallery is empty</h2>
      )}
      <Pagination {...paginateProps} />
      {modalMounted &&
        addItem &&
        createPortal(
          <AddGalleryForm addImages={create} />,
          document.getElementById("modalContent")
        )}
      {modalMounted &&
        deleteItem &&
        createPortal(
          <Confirmation
            message="Are you sure that you want to delete this gallery image?"
            onSubmit={(allow) => {
              const { id, path } = currentItem;
              if (allow) deletePic({ id, path });
              setLoadingId(id);
              setModalContent(null);
            }}
          />,
          document.getElementById("modalContent")
        )}
    </div>
  );
};
export default GalleryFilter;
