import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import bnr from "images/pattern/blueglass.jpg";
import { noTitleRoutes } from "markup/Layout/layoutConfig";
import { useAuth } from "store/auth";

import styles from "./styles.module.scss";

const PageTitle = () => {
  const { pathname } = useLocation();
  const { authUser } = useAuth();

  const show = !noTitleRoutes.includes(pathname);
  const nodes = pathname.split("/");

  return useMemo(
    () =>
      show ? (
        <div
          className="dlab-bnr-inr"
          style={{
            backgroundImage: "url(" + bnr + ")",
            backgroundSize: "100%",
            zIndex: 0,
          }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={"./"}>
                      <i className="fa fa-home"></i>
                    </Link>
                  </li>
                  {nodes.map((node) => (
                    <Link key={node} to={location.pathname}>
                      <li>{node}</li>
                    </Link>
                  ))}
                </ul>
                {authUser && (
                  <div className={styles.greeting}>
                    Welcome {authUser.displayName?.split(" ")[0]}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null,
    [authUser?.uid, pathname]
  );
};

export default PageTitle;
