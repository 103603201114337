import React from "react";
import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";

import { useGlobal } from "store/global";

const NextArrow = ({ onClick }) => (
  <div className="owl-nav">
    <div className="owl-next" onClick={onClick} />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="owl-nav">
    <div className=" owl-prev " onClick={onClick} />
  </div>
);

const settings = {
  arrows: true,
  slidesToShow: 3,
  infinite: true,
  autoPlay: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const BlogSlider = ({ blogs = [], setCurrBlogId }) => {
  const history = useHistory();

  const {
    actions: { setScrollToTop },
  } = useGlobal();

  const handleMore = (blogId) => () => {
    setCurrBlogId(blogId);
    setScrollToTop(true);
    history.push({ pathname: "/blog-details", state: { blogId } });
  };

  return (
    <Slider
      className="blog-carousel owl-carousel owl-theme dots-none sprite-nav owl-btn-center-lr "
      {...settings}
    >
      {blogs.map((item, index) => (
        <div
          className="item p-3 wow bounceInUp"
          data-wow-duration="2s"
          data-wow-delay="0.2s"
          key={index}
          onClick={handleMore(item.id)}
        >
          <div className="blog-post blog-grid ">
            <div className="dlab-post-media frame-box">
              <img src={item.imageUrl} alt="" />
            </div>
            <div className="dlab-info">
              <div className="dlab-post-title">
                <h4 className="post-title">
                  <Link to={"./blog-details"}>
                    {`${item.title.substr(0, 20)}...`}
                  </Link>
                </h4>
              </div>
              <div className="dlab-post-text">
                <p>{`${item.text.substr(0, 77)}...`}</p>
              </div>
              <div className="dlab-post-readmore">
                <Link
                  to={"./blog-details"}
                  title="READ MORE"
                  rel="bookmark"
                  className="btn-link"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};
export default BlogSlider;
