import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { useScroll } from "hooks";
import { useGlobal } from "store/global";

import styles from "./styles.module.scss";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const { atBottom } = useScroll();
  const {
    actions: { setScrollToTop },
    state: { scrollToTop: toTop },
  } = useGlobal();

  const elRef = useRef(document.querySelector("html"));

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    if (toTop) scrollToTop();
  }, [toTop]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setScrollToTop(false);
  }, [setScrollToTop, window.scrollTo]);

  const handleScroll = () => {
    window.scrollTo({
      top: elRef.current.scrollTop + elRef.current.clientHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  return atBottom ? (
    <div className={styles.root} onClick={scrollToTop}>
      <i className="fa fa-arrow-up"></i>
    </div>
  ) : (
    <div className={styles.root} onClick={handleScroll}>
      <i className="fa fa-arrow-down"></i>
    </div>
  );
};

export default ScrollToTop;
