/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { createPortal } from "react-dom";

import Notification from "markup/Element/Notification";
import AddFlyerForm from "./AddFlyerForm";
import FlyerList from "./FlyerList";
import Timer from "../../Element/Timer";
import AddButton from "markup/Element/AddButton";

import { useGlobal } from "store/global";
import { useSocialEvents } from "store/socialEvents";

import styles from "./styles.module.scss";

const bnr =
  "https://firebasestorage.googleapis.com/v0/b/freedom-learning-academy.appspot.com/o/images%2Fbackground%2Fbg10.jpg?alt=media&token=b2298467-b1e3-42bb-b173-a13141573192";

const initFields = {
  eventDate: "",
  launchDate: "",
  image: "",
};

const ComingSoon = () => {
  const [fields, setFields] = useState(initFields);
  const [showFlyerAdd, setShowFlyerAdd] = useState(false);
  const [showFlyerEdit, setShowFlyerEdit] = useState(false);
  const [showFlyerList, setShowFlyerList] = useState(false);

  const {
    state: { loading, isAdmin, modalMounted },
    actions: { setModalContent },
  } = useGlobal();

  const {
    state: { currEvent, eventExpired, socialEvents },
    // eslint-disable-next-line no-unused-vars
    actions: { update, updateCurrEvent, create, delete: remove },
  } = useSocialEvents();

  useEffect(() => {
    if (!modalMounted) {
      setShowFlyerAdd(false);
      setShowFlyerEdit(false);
      setFields(initFields);
    }
  }, [modalMounted]);

  const isEdit = useMemo(() => !!currEvent?.id, [currEvent?.id]);

  const handleEdit = useCallback(() => {
    setModalContent(true);
    setShowFlyerEdit(true);
    setShowFlyerAdd(false);
    setShowFlyerList(false);
  }, []);

  const handleAdd = () => {
    setModalContent(true);
    setShowFlyerAdd(true);
    setShowFlyerEdit(false);
    setShowFlyerList(false);
  };

  const handleList = () => {
    setModalContent(true);
    setShowFlyerList(true);
    setShowFlyerEdit(false);
    setShowFlyerAdd(false);
  };

  const buttonText = loading.status ? "Loading . . ." : "Edit";

  return (
    <div className="content-block">
      <div
        className="coming-soon"
        style={{
          backgroundImage: "url(" + bnr + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Timer
          seconds={currEvent?.eventDate?.seconds}
          expired={!!eventExpired}
        />
        <div className="coming-head">
          <h2 className={styles.soon}>
            <span>Coming soon</span>
          </h2>
          <div className={styles.buttonGroup}>
            {isAdmin && isEdit && (
              <AddButton text={buttonText} onClick={handleEdit} />
            )}
            {isAdmin && <AddButton text={"Add"} onClick={handleAdd} />}
            {isAdmin && <AddButton text={"List"} onClick={handleList} />}
          </div>
          <Notification />
          <p className={styles.message}>
            . . . location at 79th and Renner in Shawnee. The site will continue
            to stay updated as more new locations develop.
          </p>
        </div>
      </div>
      {showFlyerAdd &&
        modalMounted &&
        createPortal(
          <AddFlyerForm
            fields={fields}
            setFields={setFields}
            submit={create}
          />,
          document.getElementById("modalContent")
        )}
      {showFlyerEdit &&
        modalMounted &&
        createPortal(
          <AddFlyerForm
            fields={currEvent}
            setFields={updateCurrEvent}
            isEdit={true}
            submit={update}
          />,
          document.getElementById("modalContent")
        )}
      {showFlyerList &&
        modalMounted &&
        createPortal(
          <FlyerList
            events={socialEvents}
            edit={handleEdit}
            updateCurrEvent={updateCurrEvent}
            remove={remove}
          />,
          document.getElementById("modalContent")
        )}
    </div>
  );
};

export default ComingSoon;
