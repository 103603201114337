import React, { useEffect, useState, useRef } from "react";
import Masonry from "react-masonry-component";
import { useHistory } from "react-router-dom";
import { createPortal } from "react-dom";
import classnames from "classnames";

import { useBlogs } from "store/blogs";
import { BlogConstants } from "store/blogs/blogConstants";
import { useGlobal } from "store/global";
import Confirmation from "markup/Element/Confirmation";
import genericImage from "images/icons/generic.jpg";
import AddBlogForm from "markup/Pages/Blogs/AddBlogForm";

import styles from "./styles.module.scss";

const masonryOptions = { transitionDuration: 0 };
const imagesLoadedOptions = { background: ".my-bg-image-el" };

const BlogMasonry = ({ add, setAdd, isWidget = false, limit = 3 }) => {
  const [deleteItem, setDeleteItem] = useState(false);
  const [addItem, setAddItem] = useState(false);
  const [currentItem, setCurrentItem] = useState();

  const isMountedRef = useRef(false);
  const history = useHistory();

  const {
    state: { authenticated, authUser, modalMounted },
    actions: { setModalContent, setScrollToTop },
  } = useGlobal();

  const {
    actions: { update, create, delete: remove, setCurrId },
    state: { blogs, page },
  } = useBlogs();
  const { PAGE_SIZE } = BlogConstants;

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });

  useEffect(() => {
    if (isMountedRef.current) {
      if (add) {
        handleSubmit({})();
        setAdd(false);
      }
    }
  }, [add]);

  useEffect(() => {
    if (!modalMounted) {
      setAddItem(false);
      setDeleteItem(false);
    }
  }, [modalMounted]);

  const handleDelete = (item) => (e) => {
    e.stopPropagation();
    setModalContent(true);
    setCurrentItem(item);
    setDeleteItem(true);
  };

  const handleSubmit = (item) => () => {
    setModalContent(true);
    setCurrentItem(item);
    setAddItem(true);
  };

  // const handleSubmit = useCallback(
  //   ({ imageOnly, item }) =>
  //     () => {
  //     },
  //   [authUser, setModalContent]
  // );

  const handleMore = (id) => () => {
    setCurrId(id);
    setScrollToTop(true);
    history.push({ pathname: "/blog-details", state: { blogId: id } });
  };

  const pagedBlogs = () => {
    const start = (page - 1) * PAGE_SIZE;
    const paged = blogs.slice(start, start + PAGE_SIZE);
    return paged;
  };

  return isWidget ? (
    <div
      className={classnames("widget recent-posts-entry", styles.recentBlogs)}
    >
      <h5 className="widget-title">Recent Blogs</h5>
      <div className="widget-post-bx">
        {blogs.slice(0, limit)?.map((blog, i) => (
          <div
            key={i}
            onClick={handleMore(blog.id)}
            className={classnames("widget-post clearfix", styles.recentBlog)}
          >
            <div className="dlab-post-media">
              <img src={blog.imageUrl} width="200" height="143" alt="" />
            </div>
            <div className="dlab-post-info">
              <div className="dlab-post-header">
                <h6 className="post-title">
                  <a>{blog.title}</a>
                </h6>
              </div>
              <div className="dlab-post-meta">
                <ul>
                  <li className="post-author">
                    By {blog.author?.split(" ")[0]}
                  </li>
                  <li className="post-comment">
                    <i className="fa fa-comments"></i> {blog.replyCount}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <>
      <Masonry
        className={"my-gallery-class"}
        options={masonryOptions}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
        imagesLoadedOptions={imagesLoadedOptions} // default {}
      >
        {pagedBlogs().map((blog, i) => {
          const hasMore = blog.text.length > 80;
          const fname = blog.author?.split(" ")?.[0];
          return (
            <div className="card-container col-lg-4 col-md-6 col-sm-12" key={i}>
              <div className="blog-post blog-grid style-2">
                <div
                  className={classnames(
                    "dlab-post-media",
                    styles.blogImageSmall
                  )}
                >
                  <div onClick={handleMore(blog.id)}>
                    <img src={blog.imageUrl ?? genericImage} alt="" />
                  </div>
                  {authenticated && (
                    <div
                      className={styles.screen}
                      onClick={handleMore(blog.id)}
                    >
                      <button
                        className={classnames("btn btn-sm radius-xl")}
                        onClick={handleDelete(blog)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
                <div className="dlab-info">
                  <div
                    className={classnames(
                      "dlab-post-title",
                      styles.titleContainer
                    )}
                  >
                    <h5 className={classnames(styles.title)}>
                      <div onClick={handleMore(blog.id)}>{blog.title}</div>
                    </h5>
                  </div>
                  <div className="dlab-post-meta">
                    <ul className="align-items-center">
                      <li className="post-date">
                        <i className="fa fa-calendar"></i>
                        <strong>
                          {blog.date?.day} {blog.date?.month}
                        </strong>{" "}
                        <span>{blog.date?.year}</span>
                      </li>
                      <li className="post-author">
                        <i className="fa fa-user"></i>By <a>{fname}</a>
                      </li>
                    </ul>
                  </div>
                  <div className={classnames("dlab-post-text", styles.text)}>
                    <p>
                      {blog.text.replace("!!!", "").substring(0, 80)}
                      {hasMore ? " . . ." : ""}
                    </p>
                  </div>
                  <div
                    className={classnames(
                      "dlab-post-readmore blog-share",
                      styles.more
                    )}
                  >
                    <div onClick={handleMore(blog.id)} className="btn black">
                      READ MORE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Masonry>

      {modalMounted &&
        addItem &&
        createPortal(
          <AddBlogForm
            submit={
              currentItem?.id
                ? (fields) => update({ ...currentItem, ...fields })
                : (fields) => create({ ...fields, userId: authUser.uid })
            }
            authUser={authUser}
            imageOnly={false}
          />,
          document.getElementById("modalContent")
        )}

      {modalMounted &&
        deleteItem &&
        createPortal(
          <Confirmation
            message="Are you sure that you want to delete this Blog?"
            onSubmit={(confirmed) => {
              const { id, imagePath } = currentItem;
              if (confirmed) remove({ id, imagePath });
              setModalContent(null);
            }}
          />,
          document.getElementById("modalContent")
        )}
    </>
  );
};

export default BlogMasonry;
