import React, { useState, useCallback, useEffect, useMemo } from "react";

import Pagination from "markup/Element/Pagination";

import { useGlobal } from "store/global";
import { useBlogs } from "store/blogs";
import AddButton from "markup/Element/AddButton";
import BlogMasonry from "./BlogMasonry";
import { BlogConstants } from "store/blogs/blogConstants";

const { PAGE_SIZE, BATCH_SIZE } = BlogConstants;

const BlogClassicGrid = () => {
  const {
    state: { loading, isAdmin },
  } = useGlobal();

  const {
    actions: { get },
    pageActions: { gotoPage, nextPage, prevPage },
    state: { page, blogs },
  } = useBlogs();

  const [addBlog, setAddBlog] = useState(false);

  useEffect(() => {
    get({ limit: PAGE_SIZE * BATCH_SIZE });
  }, []);

  const buttonText = loading.status ? "Loading Blogs . . ." : "Add Blog";
  const handleAdd = useCallback(() => setAddBlog(true), []);

  const paginateProps = useMemo(
    () => ({
      page,
      gotoPage,
      nextPage,
      prevPage,
      pageSize: PAGE_SIZE,
      totalSize: blogs.length,
    }),
    [page, gotoPage, nextPage]
  );

  return (
    <div className="page-content">
      <div className="content-block">
        <div className="section-full content-inner">
          <div className="container">
            {isAdmin && <AddButton text={buttonText} onClick={handleAdd} />}
            <div className="dlab-blog-grid-3 " id="masonry">
              <BlogMasonry add={addBlog} setAdd={setAddBlog} />
            </div>
            <Pagination {...paginateProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogClassicGrid;
