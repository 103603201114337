import axios from "axios";

import { BlogConstants } from "./blogConstants";
import { createFormData } from "utils";
import { getDate, getMonthName, getFullYear } from "utils/dateUtils";
import {
  setLocalStorage,
  getLocalStorage,
  mergeToLocalStorageArray,
} from "utils/storage";

const { PAGE_SIZE, BATCH_SIZE } = BlogConstants;

const getDateObject = (data) => {
  const milliSecs = (data?.updatedAt?.seconds ?? 0) * 1000;
  if (!milliSecs) return data;

  const day = getDate(milliSecs);
  const month = getMonthName(milliSecs, 3);
  const year = getFullYear(milliSecs);
  return { milliSecs, day, month, year };
};

const withDate = (data) => {
  if (Array.isArray(data)) {
    return data?.map((v) => ({ ...v, date: getDateObject(v) }));
  }
  return { ...data, date: getDateObject(data) };
};

export const createActions = (state, dispatch, globalActions) => ({
  getCurrBlog: () => state.blogs.find((v) => v.id === state.currId),
  setCurrId: (id) => {
    dispatch({ type: "SETCURRENTID", payload: { data: id } });
  },
  getRecent: async (limit) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const query = JSON.stringify({ limit });

    try {
      const res = await axios.get("/api/blogs-recent", { params: { query } });
      const { blogs } = res.data ?? {};
      if (!blogs) return;
      globalActions.onFetchSuccess("Successfully fetched recent Blogs", false);
      return Promise.resolve(withDate(blogs));
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("fetch recent Blogs Error", serverError);
      return Promise.reject(serverError);
    }
  },
  get: async (params) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const query = JSON.stringify(params);
    const localData = getLocalStorage("blogs");

    if (localData && !params?.next) {
      dispatch({ type: "GET", payload: { data: localData } });
      globalActions.onFetchSuccess("Locally loaded Blogs", false);
    }

    try {
      const res = await axios.get("/api/blogs", { params: { query } });
      const { blogs } = res.data ?? {};
      if (!blogs?.length) {
        globalActions.onFetchSuccess();
        globalActions.addMessage("Sorry, no more blogs available.");
        return;
      }
      const blogsWithDate = withDate(blogs);
      if (params?.next) mergeToLocalStorageArray("blogs", blogsWithDate);
      else setLocalStorage("blogs", blogsWithDate);

      globalActions.onFetchSuccess("Successfully fetched Blogs", false);
      if (params?.next) {
        dispatch({ type: "ADD_GET", payload: { data: blogsWithDate } });
        if (blogs.length)
          dispatch({ type: "PAGE", payload: { data: state.page + 1 } });
      } else dispatch({ type: "GET", payload: { data: blogsWithDate } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("fetch Blogs Error", serverError);
    }
  },
  create: async (data) => {
    globalActions.onFetchStart();
    const formData = data ? createFormData(data) : null;

    try {
      const res = await axios.post("/api/blogs", formData);
      const { blog } = res.data ?? {};
      if (!blog) throw new Error("Blog response is undefined");
      globalActions.onFetchSuccess("Successfully created Blog");
      globalActions.setModalContent(null);
      dispatch({ type: "CREATE", payload: { data: withDate(blog) } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("create Blog Error", error?.response?.statusText);
    }
  },
  update: async (payload) => {
    globalActions.onFetchStart();
    const formData = createFormData(payload);
    try {
      const res = await axios.put("/api/blogs", formData);
      const { blog } = res.data ?? {};
      if (!blog) throw new Error("Blog response is undefined");
      globalActions.onFetchSuccess("Successfully updated Blog");
      globalActions.setModalContent(null);
      dispatch({ type: "UPDATE", payload: { data: withDate(blog) } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("Update Blog Error", serverError);
    }
  },
  delete: async ({ id, imagePath }) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    try {
      await axios.delete("/api/blogs", { data: { id, imagePath } });
      globalActions.onFetchSuccess("Successfully deleted Blog");
      dispatch({ type: "DELETE", payload: { data: { id } } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("delete Blog Error", serverError);
    }
  },
});

export const createPageActions = (state, dispatch, actions) => {
  return {
    gotoPage: (page) => {
      dispatch({ type: "PAGE", payload: { data: page } });
    },
    nextPage: () => {
      const lastVisibleIndex = state.page * PAGE_SIZE - 1;
      const lastIndex = state.blogs.length - 1;
      const nextPage = state.page + 1;
      if (lastIndex > lastVisibleIndex) {
        dispatch({ type: "PAGE", payload: { data: nextPage } });
      } else {
        actions.get({ next: true, limit: PAGE_SIZE * BATCH_SIZE });
      }
    },
    prevPage: () => {
      dispatch({
        type: "PAGE",
        payload: { data: Math.max(1, state.page - 1) },
      });
    },
  };
};
