import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { /* Button,  */ Collapse } from "react-bootstrap";
import classnames from "classnames";

import Hamburger from "./Hamburger";

import { useGlobal } from "store/global";
import { useAuth } from "store/auth";
import { useScroll } from "hooks/useScroll";
import { useResize } from "hooks/useResize";
import logo from "images/logo.png";
import { schoolPhone, schoolLocations, schoolHours } from "Constants";

import styles from "./styles.module.scss";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuItemOpen, setMenuItemOpen] = useState(null);

  const { scrollY } = useScroll();
  const device = useResize();
  const {
    state: { menuConfig /* , time  */ },
    actions: globalActions,
  } = useGlobal();
  const { actions: authActions } = useAuth();
  const actions = { ...globalActions, ...authActions };

  const location = useLocation();

  useEffect(() => {
    actions.setActiveMenu(location.pathname);
    setMenuOpen(false);
  }, [location.pathname]);

  const handleAction = (action) => (e) => {
    e.stopPropagation();
    actions[action]?.();
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemHover = (item, i) => () => {
    if (!item?.submenu?.length) {
      setMenuItemOpen(-1);
      return;
    }
    setMenuItemOpen(menuItemOpen === i ? -1 : i);
  };

  const handleMenuItemClick = (item, i) => () => {
    if (!item?.submenu?.length) {
      setMenuItemOpen(-1);
      return;
    }
    setMenuItemOpen(i);
  };

  const rootClasses = ["site-header header mo-left"];
  const scrolled = scrollY > 50;
  if (scrolled) rootClasses.push(styles.scrolled);
  if (scrolled && device === "DESKTOP") rootClasses.push(styles.minimized);
  if (menuOpen && device !== "DESKTOP") rootClasses.push(styles.mobileMenu);

  return (
    <header className={classnames(...rootClasses)}>
      <div className={classnames("top-bar", styles.topBar)}>
        <div className={classnames("container", styles.container)}>
          <div className="row d-flex justify-content-end">
            <div className="dlab-topbar-right">
              <ul className={styles.list}>
                <li>
                  <i className="fa fa-phone m-r5"></i> {schoolPhone}
                </li>
                <li>
                  <i className="fa fa-clock-o m-r5"></i> School Hours : &nbsp;
                  {schoolHours}
                </li>
              </ul>
            </div>
            <div className="dlab-topbar-right">
              <ul className={styles.list}>
                {schoolLocations.map((location, i) => (
                  <li key={i}>
                    <i className="fa fa-map-marker m-r5"></i> {location}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {device !== "DESKTOP" && (
        <Hamburger
          onClick={handleMenuClick}
          menuOpen={menuOpen}
          transparent={!scrolled}
          showBackground={
            device === "PHONE" || device === "TABLET" || device === "TABLETPRO"
          }
        />
      )}
      <div className={classnames("logo-header clickthru", styles.logo)}>
        <Link to={"./"} className="dez-page">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="sticky-header main-bar-wraper navbar-expand-lg">
        <div className="main-bar clearfix ">
          <div
            className={classnames("container clearfix", styles.menuContainer)}
          >
            <Collapse in={menuOpen}>
              <div
                className="header-nav navbar-collapse myNavbar justify-content-end"
                id="navbarNavDropdown"
              >
                <ul className="nav navbar-nav">
                  {menuConfig.map((item, i) => (
                    <li
                      key={i}
                      className={item.active ? "active" : ""}
                      onMouseEnter={handleMenuItemHover(item, i)}
                      onMouseLeave={handleMenuItemHover(item, i)}
                      onClick={handleMenuItemClick(item, i)}
                      data-toggle="collapse"
                      aria-controls={`menuitem-${i}`}
                      aria-expanded={menuItemOpen === i}
                    >
                      <Link to={item.link ?? "#"}>
                        {item.name}{" "}
                        {item.submenu && <i className="fa fa-chevron-down"></i>}
                      </Link>
                      {item.submenu && (
                        <Collapse in={menuItemOpen === i}>
                          <ul className="sub-menu" id={`menuitem-${i}`}>
                            {item.submenu.map((sub, i) => (
                              <li key={i}>
                                {/* <Link
                                  to={sub?.link ?? "/#"}
                                  onClick={handleAction(sub.action)}
                                > */}
                                <a
                                  href={sub?.link ?? "/#"}
                                  onClick={handleAction(sub.action)}
                                >
                                  {sub.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </Collapse>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
