import React from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";
import Masonry from "react-masonry-component";

import { useGallery } from "store/picGallery";
import { classInfo } from "Constants";

// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

//Light Gallery Icon
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      onClick={() => openLightbox(props.imageToOpen)}
      to={"#"}
      className="check-km"
    >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};

const Gallery = () => {
  const { state, dispatch } = useGallery();

  const onDelete = (item) => () => {
    dispatch({ type: "DELETE", payload: { id: item.id } });
  };

  return (
    <div className="page-content">
      <div className="content-block">
        <div className="section-full content-inner">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="head-title text-secondary">
                Gallery of our classes
              </h2>
              <p>{classInfo}</p>
            </div>
            <SimpleReactLightbox>
              <SRLWrapper>
                <div className="clearfix" id="lightgallery">
                  <ul
                    id="masonry"
                    className="dlab-gallery-listing gallery-grid-4 gallery"
                  >
                    <Masonry
                      className={"my-gallery-class"}
                      options={masonryOptions}
                      disableImagesLoaded={false}
                      updateOnEachImageLoad={false}
                      imagesLoadedOptions={imagesLoadedOptions} // default {}
                    >
                      {state.map((item, index) => (
                        <li
                          className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn"
                          key={index}
                        >
                          <div className="dlab-box frame-box m-b30">
                            <div className="dlab-thum dlab-img-overlay1 ">
                              <img src={item.image} alt="" />
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <Iconimage />
                                </div>
                                <button
                                  className="btn  btn-sm radius-lg"
                                  onClick={onDelete(item)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </Masonry>
                  </ul>
                </div>
              </SRLWrapper>
            </SimpleReactLightbox>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Gallery;
