import React, { useEffect, useState, useRef } from "react";
import ModalVideo from "react-modal-video";

const getYouTubeId = (url) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url?.match(regExp);
  return match && match[7].length == 11 ? match[7] : null;
};

const VideoPopup = ({ url, onClose = () => { }, onOpen = () => { } }) => {
  const [isOpen, setOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const mountedRef = useRef(false);

  const handleOnOpen = () => {
    setOpen(true);
    setShowButton(false);
    onOpen();
  };

  const handleOnClose = () => {
    setOpen(false);
    setShowButton(true);
    onClose();
  };

  useEffect(() => {
    mountedRef.current = true;
    return () => mountedRef.current = false;
  }, []);

  return mountedRef.current && url ? (
    <>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={getYouTubeId(url)}
        onClose={handleOnClose}
      />
      {showButton && (
        <button className="popup-youtube video border-0" onClick={handleOnOpen}>
          <i className="fa fa-play"></i>
        </button>
      )}
    </>
  ) : null;
};

export default VideoPopup;
