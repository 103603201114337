import React, { useState, useEffect, useCallback, useRef } from "react";

import CloseButton from "../CloseButton";
import { useGlobal } from "store/global";
import { useResize } from "hooks/";
import styles from "./styles.module.scss";

const Message = () => {
  const isMountedRef = useRef(false);
  const {
    state: { message },
    actions: { clearMessage },
  } = useGlobal();

  const device = useResize();

  const [isShowing, setIsShowing] = useState(true);

  const handleClose = useCallback(() => {
    setIsShowing(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsShowing(true);
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });

  useEffect(() => {
    if (isMountedRef.current) {
      if (!message.message) {
        handleClose();
      } else {
        handleOpen();
      }
    }
  }, [message, handleClose, handleOpen]);

  const handleForceClose = () => {
    clearMessage();
    setIsShowing(false);
  };

  const rootClasses = [styles.root];
  const modalClasses = [styles.modal];
  if (isShowing) rootClasses.push(styles.show);
  rootClasses.push(styles[device.toLowerCase()]);
  modalClasses.push(styles[device.toLowerCase()]);

  return (
    <div className={rootClasses.join(" ")}>
      <div className={modalClasses.join(" ")}>{message.message}</div>
      <CloseButton onClick={handleForceClose} />
    </div>
  );
};

export default Message;
