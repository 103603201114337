import React, {
  createContext,
  useContext,
  useReducer,
  // useMemo,
} from "react";
import axios from "axios";
import { useGlobal } from "store/global";
import { setLocalStorage, getLocalStorage } from "utils/storage";
import { createFormData } from "utils";

const initState = {
  teachers: [],
};

export const TeamContext = createContext();
TeamContext.displayName = "TeamContext";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "GET": {
      return { ...state, teachers: payload.data };
    }
    case "CREATE": {
      return { ...state, teachers: [...state.teachers, payload.data] };
    }
    case "DELETE": {
      return {
        ...state,
        teachers: state.teachers.filter((item) => item.id !== payload.data.id),
      };
    }
    case "UPDATE": {
      return {
        ...state,
        teachers: state.teachers.map((v) => {
          if (v.id === payload.data.id) return payload.data;
          return v;
        }),
      };
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const createActions = (dispatch, globalActions) => ({
  get: async (payload) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const localData = getLocalStorage("teachers");
    if (localData) {
      dispatch({ type: "GET", payload: { data: localData } });
      globalActions.onFetchSuccess("Locally loaded Teachers", false);
    }

    try {
      const res = await axios.get("/api/teachers", payload);
      const { teachers } = res.data ?? {};
      if (!teachers) return;
      setLocalStorage("teachers", teachers);
      globalActions.onFetchSuccess("Successfully fetched Teachers", false);
      dispatch({ type: "GET", payload: { data: teachers } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("fetchTeachers Error", serverError);
    }
  },
  create: async (data) => {
    globalActions.onFetchStart();
    const formData = createFormData(data);

    try {
      const res = await axios.post("/api/teachers", formData);
      const { teacher } = res.data ?? {};
      if (!teacher) throw new Error("Teacher response is undefined");
      globalActions.onFetchSuccess("Successfully created Teacher");
      globalActions.setModalContent(null);
      dispatch({ type: "CREATE", payload: { data: teacher } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onAuthFail(serverError);
      globalActions.setModalContent(null);
      console.log("registerUser Error", error?.response?.statusText);
    }
  },
  update: async (payload) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    const formData = createFormData(payload);
    try {
      const res = await axios.put("/api/teachers", formData);
      const { teacher } = res.data ?? {};
      if (!teacher) throw new Error("Teacher response is undefined");
      globalActions.onFetchSuccess("Successfully updated Teacher");
      globalActions.setModalContent(null);
      dispatch({ type: "UPDATE", payload: { data: teacher } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("loginTeacher Error", serverError);
    }
  },
  delete: async ({ id, path }) => {
    globalActions.onFetchStart({ isLoadingLocal: true });
    try {
      await axios.delete("/api/teachers", { data: { id, path } });
      globalActions.onFetchSuccess("Successfully deleted Teacher");
      dispatch({ type: "DELETE", payload: { data: { id } } });
    } catch (error) {
      const serverError = error?.response?.statusText ?? error.message;
      globalActions.onFetchFail({ serverError });
      console.log("deleteTeacher Error", serverError);
    }
  },
});

export const useTeam = () => {
  const data = useContext(TeamContext);
  if (data === undefined) {
    throw new Error("useTeam must be used within a TeamProvider");
  }
  return data;
};

export const TeamProvider = ({
  children,
  value: { state: oState, actions: oActions = {} } = {},
}) => {
  const { actions: globalActions } = useGlobal();
  const [state, dispatch] = useReducer(reducer, initState);
  const actions = { ...createActions(dispatch, globalActions), ...oActions };

  return (
    <TeamContext.Provider value={{ state: oState ?? state, actions }}>
      {children}
    </TeamContext.Provider>
  );
};
