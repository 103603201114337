import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import logo from "images/logo.png";
import { useGlobal } from "store/global";
import { useAuth } from "store/auth";

import styles from "./styles.module.scss";
import BlogMasonry from "markup/Pages/Blogs/BlogMasonry";
import { schoolPhone, schoolEmail, schoolLocations } from "Constants";

const Footer = () => {
  const {
    state: { menuConfig },
    actions: globalActions,
  } = useGlobal();
  const { actions: authActions } = useAuth();
  const actions = { ...globalActions, ...authActions };

  const handleAction = (action) => () => actions[action]?.();

  return (
    <footer className="site-footer">
      <div className="footer-top">
        <div className="container wow fadeIn" data-wow-delay="0.5s">
          <h5 className="footer-title">Information</h5>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 footer-col-4">
              <div className="widget widget_ftabout">
                <div className="footer-logo">
                  <Link to={"./"}>
                    <img src={logo} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 footer-col-4">
              <p className={styles.about}>
                Freedom Learning Academy is a growing program. Our goal is to
                provide educational options for parents, to empower teachers to
                teach, and to get back to the roots of academically challenging
                students. If you&apos;re interested in getting involved, please
                email Josiah, Head of School, at &nbsp;
                <span>
                  <a href="mailto:flmacademy@protonmail.com">
                    flmacademy@protonmail.com
                  </a>
                </span>
                .
              </p>
            </div>
          </div>
        </div>
        <div className={`container wow fadeIn`} data-wow-delay="0.5s">
          <div className={styles.separator} />
          <div className="row">
            {/* MENU */}
            <div className="col-lg-6 col-md-6 col-sm-12 footer-col-4">
              <h5 className="footer-title">Menu</h5>
              <div className="widget widget_services border-0">
                <ul className={classnames("list-2", styles.footerList)}>
                  {menuConfig.map((item, i) => {
                    const className = item.active ? "active" : "";
                    return (
                      <li key={i} className={className}>
                        <Link
                          className={className}
                          to={item?.link ?? item?.submenu?.[0]?.link ?? "#"}
                          onClick={handleAction(item?.submenu?.[0]?.action)}
                        >
                          {item?.link ? item?.name : item.submenu?.[0].name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* POSTS */}
            <div className="col-lg-6 col-md-6 col-sm-12 footer-col-4">
              <BlogMasonry isWidget={true} />
            </div>
          </div>
          {/*  footer bottom part  */}
          <div className={classnames(styles.separator, styles.info)}>
            <div className="container separator">
              <div className="row">
                <div className="col-md-8 col-sm-8 text-left">
                  Copyright © 2021 Freedom Learning Academy. All right reserved.
                </div>
                <div className="col-md-4 col-sm-4 text-right ">
                  <ul className="list-inline">
                    <li>
                      <Link to={"#"} className="btn-link facebook circle mr-1">
                        <i className="fa fa-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"#"}
                        className="btn-link google-plus circle mr-1"
                      >
                        <i className="fa fa-google-plus" />
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"} className="btn-link linkedin circle mr-1">
                        <i className="fa fa-linkedin" />
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"} className="btn-link instagram circle">
                        <i className="fa fa-instagram" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 text-center">
                  Created and maintained by Adrian Bartholomew
                </div>
                <div className="col-sm-12 text-center">
                  <div>
                    <i className="fa fa-phone m-r5" />
                    (913•680-9050)
                  </div>
                  <div>
                    <i className="fa fa-envelope m-r5" />
                    adrian@bartholomusic.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classnames("container wow fadeIn", styles.root)}
          data-wow-delay="0.5s"
        >
          <div className={classnames(styles.separator, styles.info)}>
            <div>
              <i className="fa fa-phone m-r5" /> {schoolPhone}
              <i className="fa fa-envelope m-r5" /> {schoolEmail}
            </div>
            <div></div>
            {schoolLocations.map((location, i) => (
              <div key={i}>
                <i className="fa fa-map-marker m-r5" /> {location}
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
