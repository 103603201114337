export default [
  {
    name: "type",
    label: "User Type",
    type: "list",
    options: ["parent", "teacher", "child"],
    width: 12,
    required: true,
  },
  {
    name: "fname",
    label: "First Name",
    type: "text",
    initValue: "",
    width: 6,
    required: true,
  },
  {
    name: "lname",
    label: "Last Name",
    type: "text",
    initValue: "",
    width: 6,
    required: true,
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    initValue: "",
    width: 6,
    required: true,
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    initValue: "",
    width: 6,
    required: true,
  },
  {
    name: "accessLevel",
    label: "Access Level",
    type: "number",
    initValue: 3,
    hidden: true,
  },
  {
    name: "childFirstName",
    label: "Child FirstName",
    type: "text",
    initValue: "",
    width: 6,
  },
  {
    name: "childLastName",
    label: "Child LastName",
    type: "text",
    initValue: "",
    width: 6,
  },
];
